import React from 'react';
import { Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { formatNumber } from '@src/utils/format-number';

import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, BarController, Title, Tooltip, Legend, ArcElement } from 'chart.js';

type ChartBarProps = {
    labels?: string[];
    data?: number[];
    labelName?: string;
    dataSets?: any;
    displayLabel?: boolean;
    enableMaintainAspectRatio?: boolean;
};
export const ChartBar = ({ displayLabel = true, labels, labelName = 'กิจกรรม', dataSets, enableMaintainAspectRatio = true }: ChartBarProps) => {
    ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ArcElement, BarController, ChartDataLabels);

    const options = {
        responsive: true,
        maintainAspectRatio: enableMaintainAspectRatio,
        layout: {
            padding: {
                top: 15,
                right: 10,
            },
        },
        plugins: {
            legend: {
                display: displayLabel,
                position: 'bottom',
                labels: {
                    font: {
                        size: 14,
                    },
                },
            },
            datalabels: {
                offset: 0,
                padding: -1,
                display: true,
                align: 'top',
                anchor: 'end',
                color: '#213555',
                labels: {
                    title: {
                        font: {
                            size: 14,
                            weight: 'bold',
                        },
                    },
                },
                formatter: (value: any) => {
                    return value > 0 ? formatNumber(value, 2) : '';
                },
            },
        },
        scales: {
            x: {
                stacked: true,
                ticks: {},
            },
            y: {
                beginAtZero: true,
                stacked: true,
            },
        },
    };

    //@ts-ignore
    return <Bar options={options} plugins={[ChartDataLabels]} data={dataSets} />;
};

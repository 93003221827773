import { z } from 'zod';

export const SubLocationFormSchema = z.object({
    name: z.string().nonempty({ message: 'กรุณากรอกข้อมูลให้ครบถ้วน' }),
    area: z
        .string()
        .nonempty({ message: 'กรุณากรอกข้อมูลให้ครบถ้วน' })
        .regex(/^[1-9]\d*$/, { message: 'กรอกตัวเลขอย่างน้อย 1 ตัว' }),
    electricConsumption: z.string().regex(/^[0-9]*$/, { message: 'กรุณากรอกตัวเลขเท่านั้น' }),
});

export type SubLocationFormInput = z.infer<typeof SubLocationFormSchema>;

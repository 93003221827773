import { Box, Button } from '@mui/material';
import Iconify from '@src/components/layout/admin/components/iconify';
import React, { useEffect, useMemo, useState } from 'react';
import type { SVGProps } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const PaymentSuccess = () => {
    const { i18n } = useTranslation();
    const [timeLeft, setTimeLeft] = useState(5);
    const bgImage = `${process.env.PUBLIC_URL}/assets/images/pexels-kelly.jpg`;
    const navigate = useNavigate();

    const onBackHome = () => {
        navigate('/');
    };

    const params = useMemo(() => {
        return new URLSearchParams(window.location.search);
    }, []);

    useEffect(() => {
        if (params && params.get('s') !== 'complete' && params.get('s') !== 'success') {
            const countdown = setInterval(() => {
                setTimeLeft((prevTime: number) => prevTime - 1);
            }, 1000);

            // Redirect after 5 minutes
            const timer = setTimeout(() => {
                navigate('/');
            }, 5000); // 5 minutes

            // Cleanup intervals and timeouts on unmount
            return () => {
                clearInterval(countdown);
                clearTimeout(timer);
            };
        }
    }, [navigate, params]);

    return (
        <div
            className={`flex h-screen justify-center items-center bg-no-repeat bg-center bg-cover `}
            style={{
                backgroundImage: `url('${bgImage}')`,
            }}
        >
            <div className="mx-auto max-w-lg w-full text-center bg-white  p-8 rounded-lg">
                {(params.get('s') === 'success' || params.get('s') === 'complete') && (
                    <Box>
                        <div className="inline-flex h-16 w-16 items-center justify-center rounded-full bg-green-500">
                            <Iconify icon="simple-line-icons:check" width={30} sx={{ color: '#ffff' }} />
                        </div>
                        <h1 className="mt-4 text-3xl font-bold tracking-tight text-foreground sm:text-4xl">
                            {i18n.language === 'en'
                                ? 'Payment Successful'
                                : i18n.language === 'th'
                                  ? 'ชำระเงินสำเร็จ'
                                  : i18n.language === 'cn'
                                    ? '付款成功'
                                    : ''}
                        </h1>

                        <p className="mt-1 text-muted-foreground">
                            {i18n.language === 'en'
                                ? 'Your transaction was completed successfully.'
                                : i18n.language === 'th'
                                  ? 'การทำธุรกรรมของคุณเสร็จสมบูรณ์แล้ว'
                                  : i18n.language === 'cn'
                                    ? '您的交易已成功完成。'
                                    : ''}
                        </p>

                        <div className="mt-4 w-full  rounded-lg border bg-card p-5 shadow-sm flex flex-col">
                            <div className="grid grid-cols-1 gap-4">
                                <div>
                                    <h2 className="text-lg font-medium">
                                        {i18n.language === 'en'
                                            ? 'Payment Details'
                                            : i18n.language === 'th'
                                              ? 'รายละเอียดการชำระเงิน'
                                              : i18n.language === 'cn'
                                                ? '付款细节'
                                                : ''}
                                    </h2>
                                    <div className="mt-2 space-y-1  text-muted-foreground">
                                        <div className="flex justify-between px-4">
                                            <span>
                                                {i18n.language === 'en'
                                                    ? ' Transaction ID'
                                                    : i18n.language === 'th'
                                                      ? 'รหัสธุรกรรม'
                                                      : i18n.language === 'cn'
                                                        ? '交易编号'
                                                        : ''}
                                            </span>
                                            <span>{params.get('t') ?? ''}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Box>
                )}
                {params.get('s') !== 'success' && params.get('s') !== 'complete' && (
                    <Box>
                        <div className="inline-flex h-16 w-16 items-center justify-center rounded-full bg-lime-400">
                            <SvgSpinnersClock width={30} height={30} />
                        </div>
                        <h2 className="mt-4 text-2xl font-bold tracking-tight text-foreground sm:text-2xl">
                            {i18n.language === 'en'
                                ? 'Returning to the home page'
                                : i18n.language === 'th'
                                  ? 'กำลังพากลับหน้าหลัก'
                                  : i18n.language === 'cn'
                                    ? '帶您返回主頁'
                                    : ''}
                        </h2>

                        <div className="text-2xl py-2">{(timeLeft % 60).toString().padStart(0, '0')}</div>
                    </Box>
                )}

                <Button
                    variant="contained"
                    onClick={onBackHome}
                    className="mt-6 rounded-lg 
                        !text-black
                        !bg-[#C8F026]"
                >
                    <span className={'!font-kanit !text-lg py-1'}>กลับหน้าหลัก</span>
                </Button>
            </div>
        </div>
    );
};

export default PaymentSuccess;

export function SvgSpinnersClock(props: SVGProps<SVGSVGElement>) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" {...props}>
            <path fill="black" d="M12,1A11,11,0,1,0,23,12,11,11,0,0,0,12,1Zm0,20a9,9,0,1,1,9-9A9,9,0,0,1,12,21Z"></path>
            <rect width={2} height={7} x={11} y={6} fill="black" rx={1}>
                <animateTransform attributeName="transform" dur="9s" repeatCount="indefinite" type="rotate" values="0 12 12;360 12 12"></animateTransform>
            </rect>
            <rect width={2} height={9} x={11} y={11} fill="black" rx={1}>
                <animateTransform attributeName="transform" dur="0.75s" repeatCount="indefinite" type="rotate" values="0 12 12;360 12 12"></animateTransform>
            </rect>
        </svg>
    );
}

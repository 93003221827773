import Box from '@mui/material/Box';

import { NAV, HEADER } from './config-layout';
import { useResponsive } from '../../../hooks/use-responsive';
import { ReactNode } from 'react';
import { SxProps } from '@mui/material';

// ----------------------------------------------------------------------

const SPACING = 8;
interface MainProps {
    children?: ReactNode;
    sx?: SxProps;
}

const Main: React.FC<MainProps> = ({ children, sx, ...other }) => {
    const lgUp = useResponsive('up', 'lg', 'sm');

    return (
        <Box
            component="main"
            sx={{
                flexGrow: 1,
                minHeight: 1,
                height: 1,
                display: 'flex',
                backgroundColor: '#F5F6F7',
                flexDirection: 'column',
                py: `${HEADER.H_MOBILE + SPACING}px`,
                ...(lgUp && {
                    px: 2,
                    py: `${HEADER.H_DESKTOP + SPACING}px`,
                    width: `calc(100% - ${NAV.WIDTH}px)`,
                }),
                ...sx,
            }}
            {...other}
        >
            {children}
        </Box>
    );
};
export default Main;

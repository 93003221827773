import { IProfile } from '@src/types';
import CryptoJS from 'crypto-js';
const secretKey = 'zero-carbon-secret-key';
export const StateProfile = (profile: IProfile) => {
    const jsonData = JSON.stringify(profile);
    const encryptedData = CryptoJS.AES.encrypt(jsonData, secretKey).toString();
    localStorage.setItem('zero_carbon_p', encryptedData);
};

export const Profile = () => {
    const encryptedData = localStorage.getItem('zero_carbon_p');
    const bytes = CryptoJS.AES.decrypt(encryptedData as string, secretKey);
    const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    return decryptedData;
};

import React from 'react';
import Logo from '../../constants/img/Logo Neutral_Self-declaration02.png';
import { Link } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';

export default function Summary() {
    const { t } = useTranslation();
    return (
        <div className="box" style={{ marginBlockStart: '80px', borderRadius: '90px' }}>
            <br />
            <br />
            <br />
            <div className="columns is-mobile">
                <div className="column is-4 is-offset-2">
                    <div className="columns is-mobile">
                        <img src={Logo} width="350px" style={{ marginTop: '65px' }} alt="logo" />
                    </div>
                </div>
                <div className="column is-4">
                    <div className="column">
                        <h1 className="content-header font-bold">{t('DOWNLOAD_LOGO_PAGE.HEADER')}</h1>
                    </div>
                    <div className="column">
                        <p>{t('DOWNLOAD_LOGO_PAGE.CONTENT1')}</p>
                        <Trans i18nKey="DOWNLOAD_LOGO_PAGE.CONTENT2"></Trans>
                    </div>
                    <div className="column">
                        <Trans i18nKey="DOWNLOAD_LOGO_PAGE.CONTENT3"></Trans>
                    </div>
                    <a
                        className="button is-large is-fullwidth App"
                        style={{ backgroundColor: '#26B3F0', fontSize: '16px', color: 'white', height: '70px' }}
                        href={Logo}
                        download
                    >
                        <Trans i18nKey="DOWNLOAD_LOGO_PAGE.BUTTONS.DOWNLOAD"></Trans>
                    </a>
                    <br />
                    <Link to="/">
                        <button className="button is-large is-fullwidth App" style={{ backgroundColor: '#C8F026', fontSize: '20px', height: '70px' }}>
                            {t('DOWNLOAD_LOGO_PAGE.BUTTONS.HOME')}
                        </button>
                    </Link>
                </div>
            </div>
            <br />
            <br />
            <br />
        </div>
    );
}

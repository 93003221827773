import React from 'react';
import CancelIcon from '@mui/icons-material/Cancel';

interface ImageUploadProps {
    onRemove: () => void;
    url: string;
}
const ImageUpload = ({ url, onRemove }: ImageUploadProps) => {
    return (
        <div className="relative z-0 ">
            <div className=" rounded-lg  border-gray-400 border-[0.5px] p-2  ">
                {/* eslint-disable-next-line jsx-a11y/img-redundant-alt  */}
                <img src={url} alt="image-Event" width={150} height={150} className="object-cover w-full h-full static" />
            </div>
            <div className="-top-2 absolute z-10 -right-1 cursor-pointer" onClick={onRemove}>
                <CancelIcon className="text-red-500" />
            </div>
        </div>
    );
};

export default ImageUpload;

export const PaymentStatus = (status: string) => {
    switch (status) {
        case 'COMPLETE':
            return 'ชําระเงินแล้ว';
        case 'ON_HOLD':
            return 'รอชำระเงิน';
        case 'CANCELED':
            return 'ยกเลิก';
        case 'INCOMPLETE':
            return 'ไม่สมบูรณ์';
        default:
            return 'รอการชําระเงิน';
    }
};

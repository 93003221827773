import React from 'react';
import CardDetails from './card-details';

interface TransactionProps {
    transactionID: string;
    amount: number | string | undefined;
    channel: string;
    transactionDate: string;
    transactionAmount: number | string | undefined;
    serviceChargeAll: number | string | undefined;
    serviceCharge: number | string | undefined;
    vat: number | string | undefined;
    netAmount: number | string | undefined;
    text: string;
}
export default function Transaction({
    transactionID,
    amount,
    channel,
    transactionDate,
    transactionAmount,
    serviceChargeAll,
    serviceCharge,
    vat,
    netAmount,
    text,
}: TransactionProps) {
    return (
        <CardDetails className="mt-4" title="ธุรกรรม">
            <div className="grid md:grid-cols-2 text-sm mt-2">
                <div>
                    <div className="grid grid-cols-2">
                        <div className="px-4 py-2 font-semibold">รหัสการรับชำระ</div> <div className="px-4 py-2">{transactionID ?? ''}</div>
                    </div>{' '}
                    <div className="grid grid-cols-2">
                        <div className="px-4 py-2 font-semibold">จำนวน</div> <div className="px-4 py-2">{amount ?? ''} บาท</div>
                    </div>{' '}
                    <div className="grid grid-cols-2">
                        <div className="px-4 py-2 font-semibold">ช่องทาง</div> <div className="px-4 py-2">{channel ?? ''}</div>
                    </div>{' '}
                    <div className="grid grid-cols-2">
                        <div className="px-4 py-2 font-semibold">วันเวลาที่ชำระ</div> <div className="px-4 py-2">{transactionDate ?? ''}</div>
                    </div>
                </div>{' '}
                <div>
                    <div className="flex justify-between items-center">
                        <div className="px-4 py-2 font-semibold">ยอดของธุรกรรม</div> <div className="px-4 py-2">{transactionAmount ?? ''} บาท</div>
                    </div>{' '}
                    {/* <div className="flex justify-between items-center">
                        <div className="px-4 py-2 font-semibold">ค่าบริการรวม</div> <div className="px-4 py-2">{serviceChargeAll ?? ''} บาท</div>
                    </div>{' '} */}
                    {/* <div className="flex justify-between items-center">
                        <div className="px-4 py-2 font-semibold ml-6">ค่าบริการ</div> <div className="px-4 py-2">{serviceCharge ?? ''} บาท</div>
                    </div>{' '}
                    <div className="flex justify-between items-center border-b">
                        <div className="px-4 py-2 font-semibold ml-6">ภาษีมูลค่าเพิ่ม</div> <div className="px-4 py-2">{vat ?? ''} บาท</div>
                    </div>{' '} */}
                    <div>
                        <div className="px-4 mt-2 font-semibold">ยอดสุทธิ</div>{' '}
                        <div className="flex justify-between items-center">
                            <div className="px-4 py-2 font-semibold text-gray-400">{text ?? ''}</div>{' '}
                            <div className="px-4 py-2  pb-6">{netAmount ?? ''} บาท</div>
                        </div>
                    </div>
                </div>
            </div>
        </CardDetails>
    );
}

import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import rawEmission from './rawData.json';
import CardEmission from './ElementEmission/CardEmission';
import { findIconByName, getNameByLanguage } from './UtilEmission';
import EmissionItem from './ElementEmission/EmissionItem';
import TravelEmission from './ElementEmission/TypeEmission/TravelEmission';
import SpecifyEmissionsDialog from './ElementEmission/SpecifyEmissionsDialog';
import Iconify from '@src/components/layout/admin/components/iconify';
import FoodEmission from './ElementEmission/TypeEmission/FoodEmission';
import AccommodationEmission from './ElementEmission/TypeEmission/AccommodationEmission';
import WasteEmission from './ElementEmission/TypeEmission/WasteEmission';
import ElectricEmission from './ElementEmission/TypeEmission/ElectricEmission';
import TransportEmission from './ElementEmission/TypeEmission/TransportEmission';
import { useForm } from 'react-hook-form';
import { CardEmissionForm } from './ElementEmission/CardEmissionForm';
import { EmissionRunTimeTypeInput } from './ElementEmission/EmissionRunTimeTypeInput';
import TransportForm from './ElementEmission/TransportForm';
import { useGlobalState } from '@src/context/GlobalStateContext';
import { DefaultEmission, EmissionsSource, SpecifyEmission, Transport } from '@src/types';
import { useNavigate, useParams } from 'react-router';

interface GasEmissionsProps {
    diffDay: number; // จำนวนวัน diff start - end
    eventMember: number; // จำนวนผู้เข้าร่วมงาน
    eventPeriod: number; //ระยะเวลาจัดงาน
    eventArea: number; //ขนาดพื้นที่จัดงาน (ตร.ม.)
    eventMemberOnline: number;
    eventForeignScale?: number;
}

const GasEmissions = ({ diffDay, eventMember, eventPeriod, eventArea, eventMemberOnline, eventForeignScale }: GasEmissionsProps) => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const { event } = useParams();
    const { gasEmissions, setGasEmissions } = useGlobalState();
    const {
        register,
        handleSubmit,
        setValue,
        trigger,
        reset,
        control,
        formState: { errors },
    } = useForm();

    const [emissionSources, setEmissionSources] = useState<EmissionsSource[]>([]);
    const [openSpecifyEmissions, setOpenSpecifyEmissions] = useState<boolean>(false);
    const [specifyEmissionsIndex, setSpecifyEmissionsIndex] = useState<number | undefined>();
    const [activeSpecifyEmissions, setActiveSpecifyEmissions] = useState<number | undefined>();

    useEffect(() => {
        if (gasEmissions) {
            setEmissionSources(gasEmissions as any);
        } else {
            setEmissionSources(rawEmission.emissions_sources);
        }
    }, [gasEmissions]);

    const handleChangeSwitch = (index: number) => {
        const newEmissionSources = [...emissionSources];
        newEmissionSources[index].default = !newEmissionSources[index].default;
        // clear state index
        if (newEmissionSources[index].default && activeSpecifyEmissions === index) {
            setActiveSpecifyEmissions(undefined);
            // clear specifyEmissions default
        }
        setEmissionSources(newEmissionSources);
    };

    const handleSpecifyByIndex = (index: number) => {
        setActiveSpecifyEmissions(index);
        setSpecifyEmissionsIndex(undefined);
    };

    const handleUpdateSpecifyByIndex = (index: number) => {
        setSpecifyEmissionsIndex(index);
        setValueSpecifyEmissions(index);
    };

    const updateEmissions = (index: number, newData: Partial<any>) => {
        const updatedEmissionSources = emissionSources.map((source: EmissionsSource, sourceIndex: number) => {
            if (sourceIndex !== activeSpecifyEmissions) return source;
            const updatedSpecifyEmissions = source.specify_emissions.map((emission: SpecifyEmission, emissionIndex: number) =>
                emissionIndex === index ? { ...emission, ...newData } : emission,
            );
            return { ...source, specify_emissions: updatedSpecifyEmissions };
        });
        setEmissionSources(updatedEmissionSources);
    };

    const handleRemoveSpecify = (index: number) => {
        updateEmissions(index, {
            enable: !emissionSources[activeSpecifyEmissions as number].specify_emissions[index].enable,
        });

        setSpecifyEmissionsIndex(undefined);
    };

    const handleSubmitSpecify = (data: any) => {
        updateEmissions(specifyEmissionsIndex as number, data);
        reset();
        // close log index specify
        setSpecifyEmissionsIndex(undefined);
    };

    function calculateTransportTotals(transportData: Transport[] = [], ef: number): any {
        return transportData.reduce(
            (totals: any, tp) => {
                totals.totalCarbonFootprint += tp.distance * tp.weight * ef || 0;
                return totals;
            },
            { totalCarbonFootprint: 0 },
        );
    }

    const setValueSpecifyEmissions = (index: number) => {
        // Get the active emission source and the specified emission
        const activeSource = emissionSources[activeSpecifyEmissions as number];
        const emission = activeSource.specify_emissions[index];

        // Check the runtime type
        if (emission.runtime_type === 'TravelEmission') {
            setValue('passengers_total', emission.passengers_total);
            setValue('distance_outbound_total', emission.distance_outbound_total);
        }

        if (emission.runtime_type === 'AccommodationEmission') {
            setValue('guests', emission.guests);
            setValue('night', emission.night);
        }

        if (emission.runtime_type === 'FoodEmission') {
            setValue('set', emission.set);
        }
        if (emission.runtime_type === 'ElectricEmission' || emission.runtime_type === 'ElectricOnLineEmission') {
            setValue('kw_hour', emission.kw_hour);
        }

        if (emission.runtime_type === 'WasteEmission' || emission.runtime_type === 'GiftEmission') {
            setValue('weight', emission.weight);
        }
    };

    const emissionSourcesEnable = useMemo(() => {
        if (activeSpecifyEmissions !== undefined) {
            const specifyEmissionsTotal = emissionSources[activeSpecifyEmissions].specify_emissions.length;
            const enableTotal = emissionSources[activeSpecifyEmissions].specify_emissions.filter((item: SpecifyEmission) => item.enable === false);
            return enableTotal.length === specifyEmissionsTotal;
        }
        return false;
    }, [emissionSources, activeSpecifyEmissions]);

    useEffect(() => {
        const handleLanguageChange = () => {
            setTimeout(() => {
                trigger();
            }, 50);
        };

        i18n.on('languageChanged', handleLanguageChange);

        return () => {
            i18n.off('languageChanged', handleLanguageChange);
        };
    }, [i18n, trigger]);

    const handleSubmitAndNext = () => {
        // ปริมาณ CO2e (ตัน) หาเปอร์เซ็น  CO2e/CO2e ทั้งหมอ /1000
        const newEmissionSources = [...emissionSources];
        // let eventMember = '2'; // จำนวนผู้เข้าร่วมงาน
        // let diffDay = 2; // จำนวนวัน diff start - end
        // let eventPeriod = 2; //ระยะเวลาจัดงาน
        // let eventArea = 2; //ขนาดพื้นที่จัดงาน (ตร.ม.)
        // let eventMemberOnline = 2; //จำนวนผู้เข้าร่วมงานออนไลน์

        newEmissionSources.forEach((source: EmissionsSource) => {
            source.total = 0;
            source.percentage = 0;

            if (source.default) {
                source.default_emissions.forEach((tde: DefaultEmission) => {
                    const memberCount = parseFloat(eventMember.toString());
                    tde.persons = memberCount;
                    tde.days = diffDay;
                    if (tde.runtime_type === 'TravelDefaultEmission' && eventForeignScale) {
                        let air_total = eventMember * (eventForeignScale / 100) * 342.9;
                        tde.air_ef = air_total;
                        tde.foreign = eventForeignScale;
                    }

                    // Travel Default Emission
                    if (tde.runtime_type === 'TravelDefaultEmission') {
                        source.total += memberCount * diffDay * tde.ef;
                        // if (eventForeignScale) {
                        //     let ForeignScaleTotal = eventMember * (eventForeignScale / 100) * 342.9;
                        //     source.total += ForeignScaleTotal;
                        // }
                        source.percentage = source.total / 1000;
                    }

                    // Accommodation Default Emission
                    if (tde.runtime_type === 'AccommodationDefaultEmission') {
                        source.total += memberCount * diffDay * tde.ef;
                        source.percentage = source.total / 1000;
                    }

                    // Food Default Emission
                    if (tde.runtime_type === 'FoodDefaultEmission') {
                        source.total += memberCount * diffDay * tde.ef * 3;
                        source.percentage = source.total / 1000;
                    }

                    // Waste Default Emission
                    if (tde.runtime_type === 'WasteDefaultEmission') {
                        // source.total += memberCount * eventPeriod * tde.ef;
                        source.total += memberCount * diffDay * tde.ef;
                        source.percentage = source.total / 1000;
                    }

                    // Electric On-Site Default Emission
                    if (tde.runtime_type === 'ElectricOnSiteDefaultEmission') {
                        source.total += memberCount * eventArea * tde.ef;
                        source.percentage = source.total / 1000;

                        tde.area = eventArea;
                    }

                    // Electric Online Default Emission
                    if (tde.runtime_type === 'ElectricOnLineDefaultEmission') {
                        source.total += eventMemberOnline * eventPeriod * tde.ef;
                        source.percentage = source.total / 1000;
                        tde.hours = eventPeriod;
                    }

                    // Gift Default Emission
                    if (tde.runtime_type === 'GiftDefaultEmission') {
                        source.total += memberCount * tde.ef;
                        source.percentage = source.total / 1000;
                    }

                    // Transport Default Emission
                    if (tde.runtime_type === 'TransportDefaultEmission') {
                        source.total = 0 * tde.ef; // Assuming this is a placeholder or incomplete calculation
                        source.percentage = source.total / 1000;
                    }
                });
            } else {
                source.specify_emissions.forEach((specify: SpecifyEmission) => {
                    if (specify.runtime_type === 'TravelEmission') {
                        if (specify.distance_outbound_total && specify.passengers_total && specify.enable) {
                            source.total += specify.distance_outbound_total * specify.passengers_total * specify.ef;
                            source.percentage = source.total / 1000;
                        }
                    }

                    if (specify.runtime_type === 'FoodEmission') {
                        if (specify.set && specify.enable) {
                            source.total += specify.set * specify.ef;
                            source.percentage = source.total / 1000;
                        }
                    }

                    if (specify.runtime_type === 'AccommodationEmission') {
                        if (specify.guests && specify.night && specify.enable) {
                            source.total += specify.night * specify.guests * specify.ef;
                            source.percentage = source.total / 1000;
                        }
                    }

                    if (specify.runtime_type === 'WasteEmission') {
                        if (specify.weight && specify.enable) {
                            source.total += specify.weight * specify.ef;
                            source.percentage = source.total / 1000;
                        }
                    }

                    if (specify.runtime_type === 'ElectricEmission' || specify.runtime_type === 'ElectricOnLineEmission') {
                        if (specify.kw_hour && specify.enable) {
                            source.total += specify.kw_hour * specify.ef;
                            source.percentage = source.total / 1000;
                        }
                    }

                    if (specify.runtime_type === 'GiftEmission') {
                        if (specify.weight && specify.enable) {
                            source.total += specify.weight * specify.ef;
                            source.percentage = source.total / 1000;
                        }
                    }

                    if (specify.runtime_type === 'TransportEmission') {
                        if (specify.transport && specify.enable) {
                            const { totalCarbonFootprint } = calculateTransportTotals(specify.transport || [], specify.ef);
                            source.total += totalCarbonFootprint;
                            source.percentage = source.total / 1000;
                        }
                    }
                });
            }
        });

        console.log('newEmissionSources', newEmissionSources);
        setGasEmissions(newEmissionSources as any);

        let tmpCreateDataStr = sessionStorage.getItem('tmpCreateData');
        let tmpCreateData = JSON.parse(tmpCreateDataStr!);
        tmpCreateData.status = event;
        tmpCreateData.paging = 4;
        sessionStorage.setItem('tmpCreateData', JSON.stringify(tmpCreateData));
        if (event === 'new' || event === 'edit') {
            navigate('/guest-register/' + event + '/4');
        } else {
            navigate('/register/' + event + '/4');
        }
    };

    return (
        <div className="container">
            <div className="columns is-mobile">
                <div className="column  mt-12">
                    <h1 className="content-header content-left font-medium text-black">{t('DETAIL_PAGE.HEADER')}</h1>
                </div>
            </div>
            <div className="columns ">
                <div className="column is-4">
                    <div className="flex justify-between">
                        <h4 className="content-left font-semibold text-black">{t('DETAIL_PAGE.SOURCE_CO2E')}</h4>
                        <h4 className="content-left font-semibold text-black">{t('DETAIL_PAGE.DEFAULT_FLAG')}</h4>
                    </div>

                    <div className="mt-4">
                        {emissionSources.map((item: EmissionsSource, index: number) => (
                            <EmissionItem
                                key={index}
                                item={item}
                                index={index}
                                handleSpecify={() => handleSpecifyByIndex(index)}
                                handleChangeSwitch={() => handleChangeSwitch(index)}
                                language={i18n.language}
                            />
                        ))}
                    </div>
                </div>

                <div className="column is-4 relative">
                    <div className="gird grid-cols-1 overflow-y-auto pt-[5px] max-h-[60vh] p-4 overflow-hidden ">
                        {activeSpecifyEmissions !== undefined &&
                            emissionSources[activeSpecifyEmissions].specify_emissions.map(
                                (specify: SpecifyEmission, specifyIndex: number) =>
                                    specify.enable && (
                                        <CardEmission
                                            key={specifyIndex}
                                            title={specify[getNameByLanguage(i18n.language)]}
                                            subTitle={emissionSources[activeSpecifyEmissions][getNameByLanguage(i18n.language)]}
                                            onClick={() => handleUpdateSpecifyByIndex(specifyIndex)}
                                            onRemove={() => handleRemoveSpecify(specifyIndex)}
                                            icon={<Iconify icon={findIconByName(specify.name_en)} sx={{ color: '#000' }} width={24} />}
                                        >
                                            {specify.runtime_type === 'TravelEmission' && <TravelEmission {...specify} />}

                                            {specify.runtime_type === 'FoodEmission' && <FoodEmission {...specify} />}

                                            {specify.runtime_type === 'AccommodationEmission' && <AccommodationEmission {...specify} />}

                                            {(specify.runtime_type === 'WasteEmission' || specify.runtime_type === 'GiftEmission') && (
                                                <WasteEmission {...specify} />
                                            )}

                                            {(specify.runtime_type === 'ElectricEmission' || specify.runtime_type === 'ElectricOnLineEmission') && (
                                                <ElectricEmission {...specify} />
                                            )}

                                            {specify.runtime_type === 'TransportEmission' && (
                                                <TransportEmission transport={specify.transport} ef={specify.ef} />
                                            )}
                                        </CardEmission>
                                    ),
                            )}
                    </div>
                    {emissionSourcesEnable && (
                        <div className="flex justify-center py-3">
                            <h1 className="content-header-s">{t('DETAIL_PAGE.HEADER_CUSTOM_EVENT')}</h1>
                        </div>
                    )}
                    {activeSpecifyEmissions !== undefined && emissionSources[activeSpecifyEmissions].specify_emissions.length > 0 && (
                        <div className="flex justify-center py-3">
                            <button className="button is-rounded is-info" type="button" onClick={() => setOpenSpecifyEmissions(true)}>
                                {t('DETAIL_PAGE.BUTTONS.ADD_CUSTOM_EVENT')}
                            </button>
                        </div>
                    )}
                </div>
                <div className="column is-5">
                    <form onSubmit={handleSubmit(handleSubmitSpecify)}>
                        {activeSpecifyEmissions !== undefined &&
                            specifyEmissionsIndex !== undefined &&
                            emissionSources[activeSpecifyEmissions].specify_emissions.map(
                                (specifyForm: any, spxIndex: number) =>
                                    spxIndex === specifyEmissionsIndex && (
                                        <div key={spxIndex}>
                                            <CardEmissionForm
                                                subTitle={emissionSources[activeSpecifyEmissions][getNameByLanguage(i18n.language)]}
                                                typeName={findIconByName(specifyForm.name_en)}
                                                title={specifyForm[getNameByLanguage(i18n.language)]}
                                            >
                                                {specifyForm.runtime_type === 'TransportEmission' && (
                                                    <TransportForm specifyEmissions={specifyForm} register={register} errors={errors} control={control} />
                                                )}
                                                {specifyForm.runtime_type !== 'TransportEmission' && (
                                                    <EmissionRunTimeTypeInput runtime_type={specifyForm.runtime_type} register={register} errors={errors} />
                                                )}

                                                <button className="button is-rounded is-info my-4" type="submit">
                                                    {t('BUTTONS.SAVE')}
                                                </button>
                                            </CardEmissionForm>
                                        </div>
                                    ),
                            )}
                    </form>
                </div>
            </div>

            <div className="flex justify-center">
                <button className="column button is-rounded is-large max-w-[400px] is-success " onClick={handleSubmitAndNext}>
                    {t('BUTTONS.NEXT')}
                </button>
            </div>

            <SpecifyEmissionsDialog
                language={i18n.language}
                onClose={() => setOpenSpecifyEmissions(false)}
                open={openSpecifyEmissions}
                title={activeSpecifyEmissions !== undefined && emissionSources[activeSpecifyEmissions][getNameByLanguage(i18n.language)]}
                subTitle={activeSpecifyEmissions !== undefined && emissionSources[activeSpecifyEmissions][getNameByLanguage(i18n.language)]}
                specifyEmissionsData={
                    activeSpecifyEmissions !== undefined ? (emissionSources[activeSpecifyEmissions as number].specify_emissions as SpecifyEmission[]) : []
                }
                onRemove={handleRemoveSpecify}
            />
        </div>
    );
};

export default GasEmissions;

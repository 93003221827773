import React from 'react';

function SkeletonOverview() {
    return (
        <div className="animate-pulse space-y-4 p-4">
            <div className="grid grid-cols-2 gap-5">
                <div className="bg-gray-200 rounded-md h-20 "></div>
                <div className="bg-gray-200 rounded-md h-20 "></div>
            </div>
            <div className="grid grid-cols-2 gap-5">
                <div className="grid grid-cols-2 gap-5">
                    <div className="bg-gray-200 rounded-md h-20 "></div>
                    <div className="bg-gray-200 rounded-md h-20 "></div>
                </div>
                <div className="grid grid-cols-2 gap-5">
                    <div className="bg-gray-200 rounded-md h-20 "></div>
                    <div className="bg-gray-200 rounded-md h-20 "></div>
                </div>
            </div>
            <div className="grid grid-cols-2 gap-5">
                <div className="bg-gray-200 rounded-md h-72 "></div>
                <div className="bg-gray-200 rounded-md h-72 "></div>
            </div>
        </div>
    );
}

export default SkeletonOverview;

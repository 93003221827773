import { Dialog, DialogTitle, DialogContent, DialogContentText, ToggleButtonGroup, DialogActions, ToggleButton } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { findIconByName, getNameByLanguage } from '../UtilEmission';
import Iconify from '@src/components/layout/admin/components/iconify';
import { SpecifyEmission } from '@src/types';

function SpecifyEmissionsDialog({
    open,
    onClose,
    specifyEmissionsData,
    title,
    subTitle,
    language,
    onRemove,
}: {
    title?: string;
    subTitle: string;
    language: string;
    open: boolean;
    specifyEmissionsData: SpecifyEmission[];
    onRemove: (index: number) => void;
    onClose: () => void;
}) {
    const { t } = useTranslation();
    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="xs" aria-labelledby="scroll-dialog-title" aria-describedby="scroll-dialog-description">
            <DialogTitle id="scroll-dialog-title" style={{ textAlign: 'center' }}>
                <p className="content-center font-bold">{title}</p>
            </DialogTitle>
            <p id="scroll-dialog-sub-title" className="content-center" style={{ textAlign: 'center', fontSize: '15px' }}>
                {t('DETAIL_PAGE.TITLE_ADD_CUSTOM_EVENT_DIALOG')}
            </p>
            <br />
            <DialogContent dividers={false}>
                <DialogContentText tabIndex={-1}>
                    <ToggleButtonGroup aria-label="eventDetailSelected" orientation="vertical" fullWidth size="medium" value={true}>
                        {specifyEmissionsData.length > 0 &&
                            specifyEmissionsData.map((item: any, index: number) => {
                                return (
                                    <ToggleButton
                                        key={index}
                                        className="event-list"
                                        value={item.enable}
                                        aria-label={item.name}
                                        style={{ textAlign: 'left' }}
                                        onClick={() => onRemove(index)}
                                        sx={{ p: 0 }}
                                    >
                                        <div className="column is-2">
                                            <div
                                                className="bg-gray-200 w-9
                      shadow-xl
                      h-9 rounded-full flex items-center justify-center "
                                            >
                                                <Iconify icon={findIconByName(item.name_en)} width={24} sx={{ color: '#000' }} />
                                            </div>
                                        </div>
                                        <div className="column is-10">
                                            <p className="content-left">{item[getNameByLanguage(language)]}</p>
                                            <p className="content-left" style={{ color: '#C6C3C3', fontSize: 14 }}>
                                                {subTitle ?? ''}
                                            </p>
                                        </div>
                                    </ToggleButton>
                                );
                            })}
                    </ToggleButtonGroup>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <button className="button is-fullwidth content-center" onClick={onClose}>
                    {t('BUTTONS.CLOSE')}
                </button>
            </DialogActions>
        </Dialog>
    );
}

export default SpecifyEmissionsDialog;

import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const UnauthorizedPage = () => {
    const navigate = useNavigate();

    const handleGoBack = () => {
        navigate(-1); // Navigate back to the previous page
    };

    return (
        <Box display="flex" justifyContent="center" alignItems="center" height="100vh" bgcolor="background.default" px={2}>
            <Box textAlign="center" bgcolor="white" boxShadow={3} p={4} borderRadius={4}>
                <Typography variant="h1" color="green" gutterBottom>
                    401
                </Typography>
                <Typography variant="h5" gutterBottom>
                    Unauthorized Access
                </Typography>
                <Typography variant="body1" color="textSecondary" paragraph>
                    Sorry, you don’t have permission to view this page.
                </Typography>
                <Button variant="contained" color="primary" onClick={handleGoBack} sx={{ mt: 2 }}>
                    Go Back
                </Button>
            </Box>
        </Box>
    );
};

export default UnauthorizedPage;

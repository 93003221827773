import DashboardPage from '@src/components/layout/admin/sections/dashboard';
import { Helmet } from 'react-helmet-async';

export default function AdminDashboardPage() {
    return (
        <>
            <Helmet>
                <title> Dashboard | Zero Carbon </title>
            </Helmet>

            <DashboardPage />
        </>
    );
}

import { config } from '@src/config';
import { getCookieName } from '@src/utils/cookie-name';
import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react';
interface AuthContextType {
    setIsAuthenticated: (value: boolean) => void;
    logout: () => void;
    isAuthenticated: boolean;
}
interface ProtectedRouteProps {
    children: ReactNode;
}
const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const useAuth = () => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};

export const AuthProvider: React.FC<ProtectedRouteProps> = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);

    useEffect(() => {
        const token = getCookieName(config.keyNameCookie);
        if (token) {
            setIsAuthenticated(true);
        }
    }, []);

    const logout = () => {};

    return <AuthContext.Provider value={{ isAuthenticated, setIsAuthenticated, logout }}>{children}</AuthContext.Provider>;
};

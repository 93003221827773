import TableRow from '@mui/material/TableRow';

// ----------------------------------------------------------------------
type TableRowZeroCarbonProp = {
    index: number;
    rows: any;
    handleClick?: (amg: any) => void;
    selected?: any;
    defaultChecked?: boolean;
    TableRowBody?: React.ReactNode;
};
export default function TableRowZeroCarbon({ rows, selected, handleClick, defaultChecked = false, TableRowBody }: TableRowZeroCarbonProp) {
    const isItemSelected = defaultChecked ? selected.includes(rows.id) : '';
    return (
        <>
            <TableRow
                hover
                tabIndex={-1}
                role="checkbox"
                style={{
                    height: '2.75rem',
                }}
                aria-checked={isItemSelected}
                selected={isItemSelected ? true : false}
                sx={{ cursor: 'pointer' }}
                onClick={handleClick}
            >
                {TableRowBody}
            </TableRow>
        </>
    );
}

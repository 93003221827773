import React from 'react';
const file = `${process.env.PUBLIC_URL}/assets/images/loading.gif`;

const ScreenLoading = () => {
    return (
        <div className="flex items-center justify-center h-screen">
            <div className="flex flex-col items-center space-y-4">
                <img src={file} width={300} alt="file load" />
            </div>
        </div>
    );
};

export default ScreenLoading;

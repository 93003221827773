import React from 'react';
import { backgroundColor } from '@utils/colors';
import { EmissionsSourcesProps } from '@src/types';
import CountUp from 'react-countup';

function SummaryTable({ dataTable = [] }: { dataTable: EmissionsSourcesProps[] }) {
    const columns = [
        { name: 'แหล่งปล่อยก๊าซเรือนกระจก', align: 'left' },
        { name: 'ปริมาณคาร์บอนฟุตพริ้นท์ (ตัน)', align: 'left' },
        { name: 'เปอร์เซนต์', align: 'left' },
    ];

    return (
        <div className="w-full overflow-x-auto border-b-0">
            <table className="w-full space-y-6">
                <thead>
                    <tr className="bg-black bg-opacity-5 text-black">
                        {columns.map((column, index) => (
                            <th
                                key={index}
                                //@ts-ignore
                                align={column.align ?? 'left'}
                                className="text-sm capitalize py-2 whitespace-nowrap px-2"
                            >
                                {column.name}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {dataTable.map((row, index) => (
                        <tr
                            key={index}
                            className="h-9 px-2 text-black"
                            style={{
                                backgroundColor: backgroundColor[index] ?? backgroundColor[2],
                            }}
                        >
                            <td className="whitespace-nowrap p-2 text-sm text-left">{row.name}</td>
                            <td className="whitespace-nowrap p-2 text-sm">
                                <CountUp start={0} end={row.total ?? 0} duration={2.75} decimals={2} />
                            </td>
                            <td className="whitespace-nowrap p-2 text-sm">
                                <CountUp start={0} end={row.percentage ?? 0} duration={2.75} decimals={2} />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export default SummaryTable;

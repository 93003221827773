import React, { useState, MouseEvent, useEffect } from 'react';
import { Radio } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { ErrorNotify } from '../../constants/screen/toast';
import { useTranslation } from 'react-i18next';
import { getSessionStorageData, setSessionStorageData } from '../../constants/screen/util';
import { useCategories } from '@src/services/queries/useCategoriesQuery';
import { useGlobalState } from '@src/context/GlobalStateContext';
import { IMiceForm } from '@src/types';

export default function RegisterMice({ modeEvent }: { modeEvent: string }) {
    const navigate = useNavigate();
    const categoriesQuery = useCategories();
    const { formMice, setFormMice } = useGlobalState();

    const { t } = useTranslation();
    const [selectedValue, setSelectedValue] = useState<string | null>(null);

    type subCategoryType = {
        id: number | string;
        categoryId: number;
        name: string;
        createdAt: string;
        updatedAt: string;
    };
    type MiceType = {
        id: number;
        name: string;
        createdAt: string;
        updatedAt: string;
        isTravel: boolean;
        isDesktop: boolean;
        isMobile: boolean;
        subCategory: subCategoryType[];
    };

    const handleChange = (event: MouseEvent<HTMLElement>, nextView: string | number) => {
        //@ts-ignore
        if (nextView !== undefined) setSelectedValue(nextView);
    };

    const controlProps = (item: string) => ({
        checked: selectedValue === item,
        value: item,
        name: 'color-radio-button-demo',
        inputProps: { 'aria-label': item },
    });

    function handleClickNext(e: MouseEvent<HTMLElement>) {
        if (!selectedValue) {
            ErrorNotify('กรุณาเลือกประเภทโครงการ');
        } else {
            let objData = {
                subCategoryId: selectedValue,
                status: modeEvent,
                paging: 2,
            };
            let objMice = {
                ...formMice,
            };
            objMice.subCategoryId = selectedValue;
            setFormMice({ ...objMice } as IMiceForm);

            let tmpCreateData = getSessionStorageData('tmpCreateData');
            tmpCreateData.status = modeEvent;
            setSessionStorageData('tmpCreateData', Object.assign(tmpCreateData, objData));
            if (modeEvent === 'new' || modeEvent === 'edit') {
                navigate(`/guest-register/${modeEvent}/2`);
            } else {
                navigate(`/register/${modeEvent}/2`);
            }
        }
    }

    useEffect(() => {
        // let tmpCreateData = getSessionStorageData('tmpCreateData');
        if (formMice && formMice?.subCategoryId) {
            setSelectedValue(formMice?.subCategoryId);
        }
    }, [formMice]);
    if (categoriesQuery.isLoading) return <div>Loading...</div>;
    return (
        <div>
            <div className="columns is-mobile">
                <div className="column mt-3">
                    <h1 className="content-header content-left">{t('MICE_PAGE.HEADER')}</h1>
                </div>
            </div>
            <div className="overflow-x-auto pb-4">
                <ToggleButtonGroup color="success" value={selectedValue} exclusive onChange={handleChange}>
                    {categoriesQuery.data?.data?.map((item: MiceType) =>
                        item?.subCategory?.map((sub: subCategoryType, index: number) => (
                            <ToggleButton
                                value={sub.id as string}
                                aria-label="list"
                                style={{
                                    borderColor: '#E2E2E2',
                                    width: '310px',
                                    borderRadius: '30px',
                                    display: 'block',
                                    marginInlineStart: '10px',
                                    textTransform: 'none',
                                }}
                            >
                                <h1 className="content-header" style={{ fontSize: 80 }}>
                                    {index === 0 ? 'M' : index === 1 ? 'I' : index === 2 ? 'C' : 'E'}
                                </h1>
                                <br />
                                {/* <h1 className="content-header-s">{t('MICE_PAGE.M_EN')}</h1> */}
                                <h1 className="content-header-s" style={{ fontSize: '22px' }}>
                                    {sub?.name}
                                </h1>
                                <Radio
                                    {...controlProps(sub.id as string)}
                                    sx={{
                                        '& .MuiSvgIcon-root': {
                                            fontSize: 40,
                                            color: '#49B90D',
                                            mt: 2,
                                        },
                                    }}
                                />
                            </ToggleButton>
                        )),
                    )}
                </ToggleButtonGroup>
            </div>
            <div className="columns">
                <div className="column is-4 is-offset-4" style={{ alignItems: 'right' }}>
                    <br />
                    <br />
                    <button className="button is-rounded is-large is-fullwidth is-success App" onClick={handleClickNext}>
                        {t('BUTTONS.NEXT')}
                    </button>
                </div>
            </div>
        </div>
    );
}

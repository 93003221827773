export const visuallyHidden = {
    border: 0,
    margin: -1,
    padding: 0,
    width: '1px',
    height: '1px',
    overflow: 'hidden',
    position: 'absolute',
    whiteSpace: 'nowrap',
    clip: 'rect(0 0 0 0)',
};

export function emptyRows(page: number, rowsPerPage: number, arrayLength: number) {
    return page ? Math.max(0, (1 + page) * rowsPerPage - arrayLength) : 0;
}

function descendingComparator(a: any, b: any, orderBy: string) {
    if (a[orderBy] === null) {
        return 1;
    }
    if (b[orderBy] === null) {
        return -1;
    }
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}
export function getComparator(order: string, orderBy: string) {
    return order === 'desc' ? (a: any, b: any) => descendingComparator(a, b, orderBy) : (a: any, b: any) => -descendingComparator(a, b, orderBy);
}

export function applyFilter({
    inputData,
    comparator,
    filterName,
    keyField = 'name',
}: {
    inputData: any[];
    comparator: any;
    filterName: string;
    keyField?: string | string[];
}) {
    const stabilizedThis = inputData.map((el: any, index: number) => [el, index]);

    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });

    inputData = stabilizedThis.map(el => el[0]);

    if (filterName) {
        inputData = inputData.filter(user =>
            (typeof keyField === 'string' ? [String(user[keyField])?.toLowerCase()] : keyField.map(key => String(user[key])?.toLowerCase()))
                .join('')
                .includes(filterName.toLowerCase()),
        );
    }

    return inputData;
}

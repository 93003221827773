import { Box, Card, CardContent } from '@mui/material';
import React, { useEffect } from 'react';
import Logo from '../../constants/img/logo2.png';
import ChangePassword from '../Setting/changePassword';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';
import { useResetForgotPassword, useValidateToken } from '@src/services/queries/useUsersQuery';
import Swal from 'sweetalert2';

const ForgetPassword = () => {
    const validateTokenQuery = useValidateToken();
    const resetForgotPasswordQuery = useResetForgotPassword();

    const param = useParams();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const handleCancel = () => {
        navigate('/login');
    };

    const onSubmit = async (data: any) => {
        data.token = param?.token;
        try {
            const response = await resetForgotPasswordQuery.mutateAsync(data);
            if (response && response.status === 201) {
                Swal.fire({
                    icon: 'success',
                    title: 'Your password has been changed successfully',
                    text: 'กำลังพาคุณหน้า login',
                    // confirmButtonText: t('TEXT_CLOSE'),
                    // confirmButtonColor: '#d33',
                    timer: 3500,
                    timerProgressBar: true,
                    customClass: {
                        popup: 'popup-backend',
                    },
                }).then(() => {
                    navigate('/login');
                });
            }
        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'เกิดข้อผิดพลาด!',
                text: '',
                confirmButtonText: t('TEXT_CLOSE'),
                confirmButtonColor: '#d33',
                customClass: {
                    popup: 'popup-backend',
                },
            }).then(() => {});
        }
    };

    const validateToken = React.useCallback(async (token: string) => {
        try {
            if (token) {
                const { data } = await validateTokenQuery.mutateAsync({ token });
                if (data && !data?.status) {
                    Swal.fire({
                        icon: 'error',
                        title: 'Your token has expired',
                        text: '',
                        confirmButtonText: t('TEXT_CLOSE'),
                        confirmButtonColor: '#d33',
                        customClass: {
                            popup: 'popup-backend',
                        },
                    }).then(() => {
                        handleCancel();
                    });
                }
            }
        } catch (error) {}
    }, []);

    useEffect(() => {
        if (param && param?.token) {
            validateToken(param?.token);
        }
    }, [param, validateToken]);
    return (
        <div
            className="flex items-center justify-center bg-cover bg-center h-screen bg-no-auth
        "
        >
            <Card
                className="w-full max-w-lg   !backdrop-blur-lg !bg-gradient-to-b from-white via-[#ADC1CE] "
                sx={{
                    borderRadius: '30px',
                    background: 'linear-gradient(360deg, rgba(196, 194, 194, 0.9), rgb(255, 255, 255, 1))',
                    backgroundColor: ' rgba(255, 255, 255, 0) ',
                    backdropFilter: 'blur(1px)',
                }}
            >
                <div className="space-y-1">
                    <div className="flex justify-center space-x-2 pt-6 gap-2 items-start">
                        <img src={Logo} alt="TCEB Logo" className="h-12" />
                    </div>
                    <div className="text-2xl text-center  font-semibold mt-4">
                        <span className="text-blue-600">{t('RESET_PASSWORD')}</span> {t('RESET_PASSWORD_TITLE')}
                    </div>
                    <div className="text-center text-sm text-gray-500"> {t('RESET_PASSWORD_DETAIL')}</div>
                </div>
                <CardContent>
                    <Box px={4}>
                        <ChangePassword onClose={handleCancel} onHandleSubmit={onSubmit} />
                    </Box>
                </CardContent>
            </Card>
        </div>
    );
};

export default ForgetPassword;

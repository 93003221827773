import { useMutation, useQuery } from '@tanstack/react-query';
import api from '../axios';

export const useImagesEventsById = (EventId: number) => {
    return useQuery({
        queryKey: ['ImagesEventById', EventId],
        queryFn: async () => {
            try {
                const { data } = await api.get(`/events/${EventId}/images`);
                return data;
            } catch (error) {
                console.error(error);
                throw error;
            }
        },
        enabled: !!EventId,
    });
};

export const useDeleteImagesById = () => {
    return useMutation({
        mutationFn: async (id: number) => {
            try {
                const { data, status } = await api.delete(`/images/${id}`);
                return {
                    data,
                    status,
                };
            } catch (error: any) {
                return {
                    data: error?.response?.data,
                    status: error?.response?.status,
                };
            }
        },
    });
};

import { MouseEvent, useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import Popover from '@mui/material/Popover';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import { Stack } from '@mui/material';
import Iconify from '../components/iconify';
import { config } from '@src/config';
import { removeCookie } from '@src/utils/remove-cookie';
import { Profile } from '@src/utils/state-profile';
import { IProfile } from '@src/types';
import { useNavigate } from 'react-router';

// ----------------------------------------------------------------------

const MENU_OPTIONS = [
    {
        label: 'ข้อมูลผู้ใช้งาน',
        icon: 'iconamoon:profile-bold',
        path: '/admin/profile',
    },
    {
        label: 'เปลี่ยนรหัสผ่าน',
        icon: 'material-symbols:password',
        path: '/admin/profile/reset-password',
    },
] as const;

// ----------------------------------------------------------------------

/**
 * aPopover
 *
 * @param {a} account
 * @returns {ReactNode}
 */
export default function AccountPopover() {
    const navigate = useNavigate();
    const [open, setOpen] = useState<null | HTMLElement>(null);
    const [account, setAccount] = useState<IProfile>();

    const handleOpen = (event: MouseEvent<HTMLElement>) => {
        setOpen(event.currentTarget);
    };

    const handleClose = () => {
        setOpen(null);
    };
    const handleRouter = (path: string) => {
        navigate(path);
        handleClose();
    };
    const logout = () => {
        removeCookie(config.keyNameCookie);
        window.location.href = '/login';
    };

    useEffect(() => {
        const profile = Profile();
        if (profile) {
            setAccount(profile);
        }
    }, []);

    return (
        <>
            <Stack onClick={handleOpen} direction="row" spacing={1.25} alignItems="center" sx={{ p: 0.5, color: 'text.primary', cursor: 'pointer' }}>
                <Avatar
                    src={account?.profileImgId}
                    alt={account?.firstName + ' ' + account?.lastName}
                    sx={{
                        border: theme => `solid 2px ${theme.palette.background.default}`,
                    }}
                >
                    {account?.firstName && account?.firstName.charAt(0).toUpperCase()}
                </Avatar>
                <Typography variant="subtitle1" sx={{ textTransform: 'capitalize' }}>
                    {account?.firstName + ' ' + account?.lastName}
                </Typography>
            </Stack>

            <Popover
                open={!!open}
                anchorEl={open}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                PaperProps={{
                    sx: {
                        p: 0,
                        mt: 1,
                        ml: 0.75,
                        width: 200,
                    },
                }}
            >
                <Box sx={{ my: 1.5, px: 2 }}>
                    <Typography variant="subtitle2" noWrap>
                        {account?.firstName + ' ' + account?.lastName}
                    </Typography>
                    <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
                        {account?.email}
                    </Typography>
                </Box>

                <Divider sx={{ borderStyle: 'dashed' }} />

                {MENU_OPTIONS.map(option => (
                    <MenuItem key={option.label} onClick={() => handleRouter(option.path)}>
                        <Iconify icon={option.icon} sx={{ mr: 1 }} /> {option.label}
                    </MenuItem>
                ))}

                <Divider sx={{ borderStyle: 'dashed', m: 0 }} />

                <MenuItem disableRipple disableTouchRipple onClick={logout} sx={{ typography: 'body2', color: 'error.main', py: 1.5 }}>
                    <Iconify icon="majesticons:logout-half-circle-line" sx={{ mr: 1 }} /> ออกจากระบบ
                </MenuItem>
            </Popover>
        </>
    );
}

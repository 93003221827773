import { z } from 'zod';

export const userFormSchema = z.object({
    firstName: z.string().nonempty({ message: 'กรุณากรอกข้อมูลให้ครบถ้วน' }),
    lastName: z.string().nonempty({ message: 'กรุณากรอกข้อมูลให้ครบถ้วน' }),
    role: z.string().nonempty({ message: 'กรุณากรอกข้อมูลให้ครบถ้วน' }),
    email: z.string().nonempty({ message: 'กรุณากรอกข้อมูลให้ครบถ้วน' }).email({ message: 'อีเมลไม่ถูกต้อง' }),
    password: z
        .string()
        .nonempty({ message: 'กรุณากรอกข้อมูลให้ครบถ้วน' })
        .min(8, { message: 'รหัสผ่านต้องมีความยาวอย่างน้อย 8 ' })
        .regex(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/, {
            message: 'รหัสผ่านต้องมีความยาวอย่างน้อย 8 อักขระและมีตัวพิมพ์เล็กอย่างน้อย 1 ตัว, 1 ตัวพิมพ์ใหญ่, 1 หมายเลขและ 1 อักขระพิเศษ @$!%*?&',
        }),
    tel: z.string().nonempty({ message: '   ' }).regex(/^\d+$/, { message: '   ' }),
});

export const userUpdateFormSchema = z.object({
    firstName: z.string().nonempty({ message: 'กรุณากรอกข้อมูลให้ครบถ้วน' }),
    lastName: z.string().nonempty({ message: 'กรุณากรอกข้อมูลให้ครบถ้วน' }),
    role: z.string().nonempty({ message: 'กรุณากรอกข้อมูลให้ครบถ้วน' }),
    email: z.string().nonempty({ message: 'กรุณากรอกข้อมูลให้ครบถ้วน' }).email({ message: 'อีเมลไม่ถูกต้อง' }),
    password: z.string().nonempty({ message: 'กรุณากรอกข้อมูลให้ครบถ้วน' }).min(8, { message: 'รหัสผ่านต้องมีความยาวอย่างน้อย 8 ' }),
    tel: z.string().nonempty({ message: '   ' }).regex(/^\d+$/, { message: '   ' }),
});
export type UserFormInput = z.infer<typeof userFormSchema> & {
    id?: number | undefined;
};

import React from 'react';
import { Helmet } from 'react-helmet-async';
import { HeaderZero } from '../../components/header-zero';
import ProjectTable from './project-table';

export const ProjectsSection = ({ title }: { title: string }) => {
    return (
        <>
            <Helmet>
                <title> {title ?? ''} | Zero Carbon </title>
            </Helmet>
            <HeaderZero title={title ?? ''} />
            <ProjectTable />
        </>
    );
};

import { z } from 'zod';

export const ResetPasswordFormSchema = z
    .object({
        email: z.string(),
        password: z
            .string()
            .nonempty({ message: 'กรุณากรอกข้อมูลให้ครบถ้วน' })
            .min(8, { message: 'รหัสผ่านต้องมีความยาวอย่างน้อย 8 ' })
            .regex(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/, {
                message: 'รหัสผ่านต้องมีความยาวอย่างน้อย 8 อักขระและมีตัวพิมพ์เล็กอย่างน้อย 1 ตัว, 1 ตัวพิมพ์ใหญ่, 1 หมายเลขและ 1 อักขระพิเศษ @$!%*?&',
            }),
        confirmPassword: z.string().nonempty({ message: 'กรุณายืนยันรหัสผ่าน' }),
    })
    .refine(data => data.password === data.confirmPassword, {
        path: ['confirmPassword'], // The error will be on this field
        message: 'รหัสผ่านและการยืนยันรหัสผ่านไม่ตรงกัน',
    });

export type ResetPasswordFormInput = z.infer<typeof ResetPasswordFormSchema> & {
    id?: number | undefined;
};

import RegisterMice from '@src/pages/Register/mice';
import React, { useMemo } from 'react';
import { useParams } from 'react-router';
import '@src/pages/Register/register.css';
import GuestForm from './Guest-Form';
import dayjs from 'dayjs';
import { useGlobalState } from '@src/context/GlobalStateContext';
import GasEmissions from '@src/pages/Register/GasEmissions';
import GasSummary from '@src/pages/Register/GasSummary';

const GuestRegister = () => {
    const { event, id } = useParams();
    const { formMice } = useGlobalState();

    const { eventMember, eventPeriod, eventArea, eventMemberOnline, diffDay } = useMemo(() => {
        const startDate = dayjs(formMice?.startDate);
        const endDate = dayjs(formMice?.endDate);
        const diffDay = endDate.diff(startDate, 'day') + 1;

        return {
            eventMember: formMice?.eventMember,
            eventPeriod: formMice?.eventPeriod,
            eventArea: formMice?.eventArea,
            eventMemberOnline: formMice?.eventMemberOnline,
            diffDay,
        };
    }, [formMice]);
    return (
        <div className={id === '1' || id === '2' || id === '3' ? 'is-none-background' : 'is-background'}>
            <div className="container">
                {(event === 'new' || event === 'edit') && id === '1' && <RegisterMice modeEvent={event} />}
                {(event === 'new' || event === 'edit') && id === '2' && <GuestForm />}
                {(event === 'new' || event === 'edit') && id === '3' && (
                    <GasEmissions
                        eventMember={eventMember ?? 0}
                        eventPeriod={eventPeriod ?? 0}
                        eventArea={eventArea ?? 0}
                        eventMemberOnline={parseInt(eventMemberOnline as string) ?? 0}
                        diffDay={diffDay}
                    />
                )}
                {(event === 'new' || event === 'edit') && id === '4' && (
                    <GasSummary modeEvent={event as string} TotalMembers={(eventMember ?? 0) + parseInt(eventMemberOnline as string)} />
                )}
            </div>
        </div>
    );
};

export default GuestRegister;

import React from 'react';
import cn from '@src/utils/class-names';

type CompensateStatusBadgeProps = {
    compensateStatus: string;
};

const CompensateStatusBadge: React.FC<CompensateStatusBadgeProps> = ({ compensateStatus }) => {
    const statusClass = cn(
        'inline-flex w-fit items-center whitespace-nowrap rounded-full border px-2.5 py-1 text-xs font-semibold transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 border-transparent text-secondary-foreground hover:bg-secondary/80',
        compensateStatus === 'COMPENSATED'
            ? 'bg-green-100 text-green-600 border-green-600'
            : compensateStatus === 'WAIT_FOR_COMPENSATION'
              ? 'bg-amber-100 text-orange-500 border-orange-500'
              : 'bg-yellow-100 text-orange-500',
    );

    const statusText = compensateStatus === 'COMPENSATED' ? 'ชดเชยแล้ว ' : 'รอการชดเชย';
    return <div className={statusClass}>{statusText}</div>;
};

export default CompensateStatusBadge;

type CompensateStatusPreEventProps = {
    compensateStatus: string;
    paymentStatus: string;
};
export const CompensateStatusPreEvent: React.FC<CompensateStatusPreEventProps> = ({ compensateStatus, paymentStatus }) => {
    const statusClass = cn(
        'inline-flex w-fit items-center whitespace-nowrap rounded-full border px-2.5 py-1 text-xs font-semibold transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 border-transparent text-secondary-foreground hover:bg-secondary/80',
        compensateStatus === 'COMPENSATED'
            ? 'bg-green-100 text-green-600'
            : compensateStatus === 'WAIT_FOR_COMPENSATION' && paymentStatus === 'ON_HOLD'
              ? 'bg-gray-100 text-gray-800 border-gray-500'
              : 'bg-red-100 text-red-500 border-red-600',
    );

    const statusText = compensateStatus === 'WAIT_FOR_COMPENSATION' && paymentStatus === 'ON_HOLD' ? 'รอดำเนินการ Post-Event ' : 'เกิดข้อผิดพลาด';
    return <div className={statusClass}>{statusText}</div>;
};

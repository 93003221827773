import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { AuthRoutes, GuestRoutes } from './AppRoutes';
//@ts-ignore
import useTokenExpiration from '@src/hooks/use-token-expiration';

import { getGuest } from '@src/utils/state-guest';
import App from '@src/App';
export const ManageAccessRouter = () => {
    const isTokenExpired = useTokenExpiration();

    const isGuest = getGuest();
    return <BrowserRouter>{isGuest ? <GuestRoutes /> : isTokenExpired ? <AuthRoutes /> : <App />}</BrowserRouter>;
};

import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import SelectZero from '@src/components/layout/admin/components/select-zero';
import { HeaderZero } from '@src/components/layout/admin/components/header-zero';
import PostEventTable from './post-event-table';
import { useSubCategories } from '@src/services/queries/useCategoriesQuery';
import { optionYear } from '@src/utils/option-year';
import { OptionQuarter } from '@src/utils/data-select';

interface PostEventViewProps {
    title?: string;
}

const PostEventViewSection = ({ title }: PostEventViewProps) => {
    const { data } = useSubCategories();

    const newSubCategories = data?.data?.map((item: any) => {
        return { label: item.name, value: item.id };
    });
    const [projectType, setProjectType] = useState('');
    const [years, setYears] = useState('');
    const [quarter, setQuarter] = useState('');

    const filterElement = (
        <>
            <SelectZero label="ประเภทโครงการ" placeholder="ทุกประเภท" setValue={setProjectType} options={newSubCategories} />
            <SelectZero label="ปี" placeholder="ทุกปี" setValue={setYears} options={optionYear()} />
            <SelectZero label="ไตรมาส" placeholder="ทุกไตรมาส" setValue={setQuarter} options={OptionQuarter()} />
        </>
    );

    return (
        <>
            <Helmet>
                <title> {title ?? ''} | Zero Carbon </title>
            </Helmet>
            <HeaderZero title={title ?? ''} filterElement={filterElement} />

            <PostEventTable
                filterSelect={{
                    projectType: projectType,
                    years: years,
                    quarter: quarter,
                }}
            />
        </>
    );
};

export default PostEventViewSection;

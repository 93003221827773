import React from 'react';
import './App.css';
import { useAuthToken } from './services/queries/useAuthQuery';
//@ts-ignore
import ScreenLoading from '@admin/components/Element/screenLoading';
import { StateProfile } from './utils/state-profile';
import { AdminRoutes, AppRoutes } from './routes/AppRoutes';

function App() {
    const { data, isLoading } = useAuthToken();
    if (isLoading) return <ScreenLoading />;
    if (data) {
        // let state = {
        //     firstName: data?.firstName,
        //     lastName: data?.lastName,
        //     email: data?.email,
        //     profileImgId: data?.profileImgId,
        //     image: data?.image,
        //     authType: data?.authType,
        //     mapId: data?.id,
        //     mobile: data?.tel,
        // };
        if (data?.authType === 'GOOGLE') {
            let imageProfile = data?.googleAuth?.photoUrl;
            data.image = imageProfile;
        }
        data.mapId = data?.id;
        StateProfile(data);
    }

    const allowedRoles = ['ADMIN', 'EVENT_MANAGER', 'PROJECT_OWNER'];
    return (
        <>
            {
                //@ts-ignore
                allowedRoles.includes(data?.role) ? <AdminRoutes role={data?.role.toLowerCase()} /> : <AppRoutes />
            }
        </>
    );
}

export default App;

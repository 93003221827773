import { Input } from '@chakra-ui/react';
import React from 'react';

interface CustomNumberInputProps {
    name: string;
    label?: string;
    error?: any;
    register: any;
    requiredMessage: string;
    minValue: number;
    minValueMessage: string;
    maxLength?: number;
    placeholder?: string;
    allowDecimal?: boolean;
}

const CustomNumberInput: React.FC<CustomNumberInputProps> = ({
    name,
    label,
    error,
    register,
    requiredMessage,
    minValue,
    minValueMessage,
    maxLength = 8,
    placeholder = '',
    allowDecimal = false,
}) => {
    return (
        <div className="flex flex-col">
            {label && <label className="content-left">{label}</label>}
            <Input
                maxLength={maxLength}
                onInput={e => {
                    e.currentTarget.value = e.currentTarget.value
                        // .replace(/[^0-9]/g, '')
                        .replace(allowDecimal ? /[^0-9.]/g : /[^0-9]/g, '')
                        .replace(allowDecimal ? /(\..*?)\..*/g : '', '')
                        .replace(/^0+/, '');
                }}
                style={{
                    borderRadius: '10px',
                    borderColor: error ? 'red' : '',
                }}
                className="input border-curve"
                placeholder={placeholder}
                {...register(name, {
                    required: requiredMessage,
                    valueAsNumber: true,
                    min: {
                        value: minValue,
                        message: minValueMessage,
                    },
                })}
            />
            {error && <p className="p-1 text-red-500 text-left">{typeof error.message === 'string' ? error.message : ''}</p>}
        </div>
    );
};

export default CustomNumberInput;

import { EmissionsSource, ILocation, IMiceForm, UserCreate } from '@src/types';
import React, { createContext, useState, useEffect } from 'react';

export interface IProject {
    id: string;
    price?: number;
    quantity?: number;
}
interface GlobalState {
    formMice?: IMiceForm;
    setFormMice: (age: IMiceForm) => void;
    gasEmissions?: EmissionsSource;
    setGasEmissions: (age: EmissionsSource) => void;

    projectSelect?: IProject;
    setProjectSelect: (age: IProject) => void;

    userUpdate?: UserCreate;
    setUserUpdate: (age: UserCreate | undefined) => void;

    subLocation?: ILocation;
    setSubLocation: (age: ILocation | undefined) => void;
}

const GlobalStateContext = createContext<GlobalState | undefined>(undefined);

export const GlobalStateProvider = ({ children }: any) => {
    const [formMice, setFormMice] = useState<IMiceForm>();
    const [gasEmissions, setGasEmissions] = useState<EmissionsSource>();
    const [projectSelect, setProjectSelect] = useState<IProject>();
    const [userUpdate, setUserUpdate] = useState<UserCreate | undefined>(undefined);
    const [subLocation, setSubLocation] = useState<ILocation | undefined>(undefined);

    useEffect(() => {
        const getFormMice = localStorage.getItem('formMice');
        const getDataGasEmissions = localStorage.getItem('gasEmissions');
        if (getDataGasEmissions && getDataGasEmissions !== 'undefined' && getDataGasEmissions !== 'null') {
            try {
                setGasEmissions(JSON.parse(getDataGasEmissions));
            } catch (error) {
                console.error('Error parsing gas emissions data from localStorage', error);
            }
        }
        const getProjectSelect = localStorage.getItem('projectSelect');
        if (getProjectSelect && getProjectSelect !== 'undefined' && getProjectSelect !== 'null') {
            setProjectSelect(JSON.parse(getProjectSelect));
        }

        if (getFormMice && getFormMice !== 'undefined' && getFormMice !== 'null') {
            try {
                setFormMice(JSON.parse(getFormMice));
            } catch (error) {
                console.error('Error parsing formMice data from localStorage', error);
            }
        }
    }, []);

    useEffect(() => {
        const dataToStore = {
            formMice: JSON.stringify(formMice),
            gasEmissions: JSON.stringify(gasEmissions),
            projectSelect: JSON.stringify(projectSelect),
        };

        Object.entries(dataToStore).forEach(([key, value]) => {
            localStorage.setItem(key, value);
        });
    }, [formMice, gasEmissions, projectSelect]);

    return (
        <GlobalStateContext.Provider
            value={{
                formMice,
                setFormMice,
                gasEmissions,
                setGasEmissions,
                projectSelect,
                setProjectSelect,
                userUpdate,
                setUserUpdate,
                subLocation,
                setSubLocation,
            }}
        >
            {children}
        </GlobalStateContext.Provider>
    );
};

export const useGlobalState = () => {
    const context = React.useContext(GlobalStateContext);
    if (!context) {
        throw new Error('useGlobalState must be used within a GlobalStateProvider');
    }
    return context;
};

import DashboardIcon from '@mui/icons-material/Dashboard';
import { ReactNode } from 'react';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import AddLocationAltTwoToneIcon from '@mui/icons-material/AddLocationAltTwoTone';
interface nav {
    icon: ReactNode;
    title: string;
    path: string;
    role: string[];
}
const navConfig: nav[] = [
    {
        title: 'ภาพรวม',
        path: '/admin',
        icon: <DashboardIcon />,
        role: ['ADMIN'],
    },
    {
        title: 'คำขอกิจกรรมประเภท Pre-event',
        path: '/admin/pre-event',
        icon: <AssignmentOutlinedIcon />,
        role: ['ADMIN'],
    },
    {
        title: 'คำขอกิจกรรมประเภท Post-event',
        path: '/admin/post-event',
        icon: <AssignmentOutlinedIcon />,
        role: ['ADMIN', 'PROJECT_OWNER'],
    },
    {
        title: 'โครงการทั้งหมด',
        path: '/admin/project',
        icon: <AssessmentOutlinedIcon />,
        role: ['ADMIN', 'PROJECT_OWNER'],
    },

    {
        title: 'จัดการข้อมูลสถานที่',
        path: '/admin/manage-locations',
        icon: <AddLocationAltTwoToneIcon />,
        role: ['ADMIN', 'EVENT_MANAGER'],
    },
    {
        title: 'ผู้ใช้งาน',
        path: '/admin/users',
        icon: <SettingsOutlinedIcon />,
        role: ['ADMIN'],
    },
];

export default navConfig;

import { useMutation } from '@tanstack/react-query';
import api from '../axios';

export const useCreateCertificate = () => {
    return useMutation({
        mutationFn: async ({ eventId, lang }: { eventId: number; lang: string }) => {
            try {
                const { data } = await api.post(`/certificate/${eventId}?lang=${lang}`);
                return data;
            } catch (error) {
                throw error;
            }
        },
    });
};

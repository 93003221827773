import React, { useEffect, useState } from 'react';
import Scrollbar from '@src/components/scrollbar';
import { Stack, Divider, Box, IconButton, Skeleton, Tooltip } from '@mui/material';
import { ChevronDown, ChevronRight, Cog, Grid2x2, MapPinCheck, Pencil, Trash } from 'lucide-react';
import { FormLocationDialog } from '../form-location';
import { useDeleteLocationQuery, useLocationQuery } from '@src/services/queries/useLocationQuery';
import cn from '@src/utils/class-names';
import { ILocation } from '@src/types';
import { useGlobalState } from '@src/context/GlobalStateContext';
import LocationToolbar from '../Location-toolbar';
import { applyFilter, getComparator } from '../../../components/TableZeroCarbon/utils';
import Swal from 'sweetalert2';
import { useQueryClient } from '@tanstack/react-query';

const ListLocations = ({ isPrivate }: { isPrivate: boolean }) => {
    const { subLocation, setSubLocation } = useGlobalState();
    const { data: locationData, isLoading } = useLocationQuery(isPrivate);
    const { mutateAsync: deleteLocation } = useDeleteLocationQuery();

    const [search, setSearch] = React.useState('');
    const [openFormLocation, setOpenFormLocation] = React.useState(false);
    const [locations, setLocations] = React.useState([]);
    const [updateLocation, setUpdateLocation] = React.useState<ILocation | undefined>();
    const queryClient = useQueryClient();

    const handleAddLocation = () => {
        setOpenFormLocation(true);
        setUpdateLocation(undefined);
    };

    const handleViewSubLocation = (data: ILocation) => {
        setSubLocation(data as ILocation);
    };

    useEffect(() => {
        if (locationData && locationData.data) {
            setLocations(locationData.data?.data);
        }
    }, [locationData]);

    if (isLoading) {
        return (
            <>
                {[...Array(5)].map((_, index) => (
                    <Stack key={index} gap={1} direction="row">
                        <Skeleton variant="rectangular" width={'100%'} height={40} style={{ marginBottom: '10px' }} />
                        <Skeleton variant="rectangular" width={40} height={40} sx={{ borderRadius: '50px' }} />
                    </Stack>
                ))}
            </>
        );
    }

    const locationFiltered = applyFilter({
        inputData: locations,
        comparator: getComparator('asc', 'asc'),
        filterName: search,
        keyField: ['name'],
    });

    const handelEditLocation = (location: ILocation) => {
        setOpenFormLocation(true);
        setUpdateLocation(location);
    };

    const handelDeleteLocation = (location: ILocation) => {
        Swal.fire({
            title: 'คุณแน่ใจหรือไม่ ?',
            text: 'คุณต้องการลบสถานที่นี้ใช่หรือไม่',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'ยืนยัน',
            cancelButtonText: 'ยกเลิก',
        }).then(async result => {
            if (result.isConfirmed) {
                const result = await deleteLocation({ locationId: location.id });
                // delete location
                if (result) {
                    if (subLocation && subLocation.id === location.id) {
                        setSubLocation(undefined);
                    }

                    Swal.fire({
                        icon: 'success',
                        title: 'ลบสถานที่สําเร็จ',
                        timer: 1000,
                    });
                }
                queryClient.invalidateQueries({ queryKey: ['locations'] });
            }
        });
    };

    return (
        <Scrollbar sx={{ minHeight: 304 }}>
            <FormLocationDialog
                open={openFormLocation}
                setOpenForm={setOpenFormLocation}
                handleClose={() => setOpenFormLocation(false)}
                defaultValue={updateLocation as ILocation}
            />
            <LocationToolbar search={search} onSearch={e => setSearch(e.target.value)} handleAddLocation={handleAddLocation} />
            <Stack divider={<Divider sx={{ borderStyle: 'dashed' }} />} sx={{ minWidth: 560 }}>
                {locationFiltered.map((location: ILocation, index: number) => (
                    <TreeNode
                        key={index}
                        node={location}
                        onViewSubLocation={() => handleViewSubLocation(location)}
                        onEdit={() => handelEditLocation(location)}
                        onDelete={() => handelDeleteLocation(location)}
                    />
                ))}
            </Stack>
        </Scrollbar>
    );
};

export default ListLocations;

const TreeNode = ({
    node,
    isChild,
    onViewSubLocation,
    onEdit,
    onDelete,
}: {
    onEdit?: (arg: ILocation) => void;
    onViewSubLocation?: (arg: ILocation) => void;
    onDelete?: (arg: ILocation) => void;
    node: any;
    isChild?: boolean;
}) => {
    const [isExpanded, setIsExpanded] = useState(true);
    const hasChildren = node.subLocation && node.subLocation.length > 0;

    return (
        <div className="select-none">
            <div className="flex items-center py-1 hover:bg-gray-100 rounded cursor-pointer">
                <div className="w-4 h-4 flex items-center justify-center" onClick={() => hasChildren && setIsExpanded(!isExpanded)}>
                    {hasChildren && (isExpanded ? <ChevronDown className=" text-gray-500" /> : <ChevronRight className=" text-gray-500" />)}
                </div>
                <Box className={cn(`ml-1  text-gray-700 flex gap-1 justify-between items-center w-full`)}>
                    {isChild && <span className="absolute w-[20px] h-[2px] -ml-[20px] border-dashed border-b-gray-500 border-b" />}
                    <div className="flex gap-1">
                        {isChild && <MapPinCheck size={14} strokeWidth={1.5} absoluteStrokeWidth />}
                        {node.name}
                    </div>

                    {isChild && (
                        <div className="flex gap-1 mr-2">
                            <Grid2x2 size={16} strokeWidth={1.5} absoluteStrokeWidth /> ขนาดพื้นที่ : {node.area} การใช้ไฟฟ้า (KWh) : {node.electricConsumption}
                        </div>
                    )}

                    {!isChild && (
                        <Box sx={{ display: 'flex' }}>
                            <Tooltip title="จัดการสถานที่ย่อย" placement="top">
                                <IconButton sx={{ alignSelf: 'flex-start' }} onClick={() => onViewSubLocation && onViewSubLocation(node)}>
                                    <Cog size={20} strokeWidth={1.5} absoluteStrokeWidth />
                                </IconButton>
                            </Tooltip>

                            <Tooltip title="แก้ไขข้อมูลสถานที่" placement="top" color="green">
                                <IconButton sx={{ alignSelf: 'flex-start' }} onClick={() => onEdit && onEdit(node)}>
                                    <Pencil size={18} strokeWidth={1.5} color="green" />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="ลบ" placement="top" color="green">
                                <IconButton sx={{ alignSelf: 'flex-start' }} onClick={() => onDelete && onDelete(node)}>
                                    <Trash size={18} strokeWidth={1.5} color="red" absoluteStrokeWidth />
                                </IconButton>
                            </Tooltip>
                        </Box>
                    )}
                </Box>
            </div>

            {hasChildren && isExpanded && (
                <div className="ml-4 border-l border-dashed border-gray-500">
                    {node.subLocation.map((child: { name: string; area: number }, index: number) => (
                        <TreeNode key={index} node={child} isChild />
                    ))}
                </div>
            )}
        </div>
    );
};

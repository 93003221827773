import { IMiceForm } from '.';

export class MiceForm implements IMiceForm {
    id?: number;
    name: string;
    startDate: string;
    endDate: string;
    eventPeriod: number;
    projectPlace?: string;
    outDoorPercent?: number;
    location: string;
    renewPercent: number;
    placeOther: string;
    eventArea: number;
    eventSpace: number;
    eventMember: number;
    eventMemberOnline: string;
    eventForeignScale: string;
    eventOwner: string;
    subCategoryId?: string;
    subLocation?: string;

    constructor(data: IMiceForm) {
        this.id = data.id;
        this.name = data.name;
        this.startDate = data.startDate;
        this.endDate = data.endDate;
        this.eventPeriod = data.eventPeriod;
        this.projectPlace = data.projectPlace;
        this.outDoorPercent = data.outDoorPercent ?? 0; // Default value
        this.location = data.location;
        this.renewPercent = data.renewPercent;
        this.placeOther = data.placeOther;
        this.eventArea = data.eventArea;
        this.eventSpace = data.eventSpace;
        this.eventMember = data.eventMember;
        this.eventMemberOnline = data.eventMemberOnline;
        this.eventForeignScale = data.eventForeignScale;
        this.eventOwner = data.eventOwner;
        this.subCategoryId = data.subCategoryId?.toString();
        this.subLocation = data.subLocation;
    }
}

import { useMutation, useQuery } from '@tanstack/react-query';
import api from '../axios';
import { LocationFormInput } from '@src/validators/locationFormSchema';

export const useLocationQuery = (mine: boolean) => {
    return useQuery({
        queryKey: ['locations', mine],
        queryFn: async ({ queryKey }: { queryKey: [string, boolean] }) => {
            const [, mine] = queryKey;
            try {
                return await api.get(`/locations?mine=${mine}`);
            } catch (error: any) {
                return {
                    data: error?.response?.data,
                    status: error?.response?.status,
                };
            }
        },
    });
};

export const useAddLocationQuery = () => {
    return useMutation({
        mutationFn: async (location: LocationFormInput) => {
            try {
                const { data } = await api.post(`/locations`, location);
                return data;
            } catch (error) {
                throw error;
            }
        },
    });
};

export const useUpdateLocationQuery = () => {
    return useMutation({
        mutationFn: async (location: { locationId: number; name: string }) => {
            try {
                const { data } = await api.patch(`/locations/${location.locationId}`, location);
                return data;
            } catch (error) {
                throw error;
            }
        },
    });
};

export const useDeleteLocationQuery = () => {
    return useMutation({
        mutationFn: async (location: { locationId: number }) => {
            try {
                const { data } = await api.delete(`/locations/${location.locationId}`);
                return data;
            } catch (error) {
                throw error;
            }
        },
    });
};

export const useAddSubLocationQuery = () => {
    return useMutation({
        mutationFn: async (location: { locationId: number; name: string; area: number; electricConsumption: number }) => {
            try {
                const { data } = await api.post(`/locations/${location.locationId}/sub-locations`, location);
                return data;
            } catch (error) {
                throw error;
            }
        },
    });
};

export const useUpdateSubLocationQuery = () => {
    return useMutation({
        mutationFn: async (subLocation: { subLocationId: number; name: string; area: number; electricConsumption: number }) => {
            try {
                const { data } = await api.patch(`/locations/sub-locations/${subLocation.subLocationId}`, subLocation);
                return data;
            } catch (error) {
                throw error;
            }
        },
    });
};

export const useSubLocationQuery = () => {
    return useMutation({
        mutationFn: async ({ locationId }: { locationId: number }) => {
            try {
                const { data } = await api.get(`/locations/${locationId}/sub-locations`);
                return data;
            } catch (error) {
                throw error;
            }
        },
    });
};

export const useDeleteSubLocationQuery = () => {
    return useMutation({
        mutationFn: async ({ subLocationId }: { subLocationId: number }) => {
            try {
                const { data } = await api.delete(`/locations/sub-locations/${subLocationId}`);
                return data;
            } catch (error) {
                throw error;
            }
        },
    });
};

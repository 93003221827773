import { useMutation } from '@tanstack/react-query';
import api from '../axios';

/**
 * @function useUploadQuery
 * @description Uploads an image to the server via a POST request with a FormData object.
 * @returns A mutation hook with the mutation function and result data.
 * @example
 * const { mutate, data, error, isLoading } = useUploadQuery();
 * const formData = new FormData();
 * formData.append('image', imageFile);
 * mutate(formData);
 */
export const useUploadQuery = () => {
    return useMutation({
        mutationFn: async (formData: FormData) => {
            try {
                const { data, status } = await api.post(`/images`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data', // Ensure proper headers for FormData
                    },
                });
                return {
                    data,
                    status,
                };
            } catch (error: any) {
                return {
                    data: error?.response?.data,
                    status: error?.response?.status,
                };
            }
        },
    });
};

import React, { useEffect } from 'react';
import { useFieldArray } from 'react-hook-form';
import Iconify from '@src/components/layout/admin/components/iconify';
import { Input } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';

const TransportForm = ({ control, specifyEmissions, register, errors }: { control: any; errors: any; register: any; specifyEmissions: any }) => {
    const { fields, append, remove, replace } = useFieldArray({
        control,
        name: 'transport',
    });
    const { t } = useTranslation();

    useEffect(() => {
        if (specifyEmissions?.transport) {
            replace(specifyEmissions.transport);
        }
    }, [specifyEmissions, replace]);
    return (
        <div className="overflow-y-auto  max-h-[50vh] p-4 overflow-hidden">
            <div className="flex justify-end">
                <Button
                    variant="outlined"
                    size="small"
                    sx={{ fontFamily: 'Kanit' }}
                    className="gap-2 mb-1"
                    onClick={() => append({ weight: null, distance: null })}
                >
                    <Iconify icon="zondicons:add-outline" />
                    {t('DETAIL_PAGE.BUTTONS.ADD_CUSTOM_EVENT')}
                </Button>
            </div>
            {fields.map((item, index) => (
                <div key={item.id} className="flex justify-between gap-2 items-center">
                    <div className="w-20 font-medium flex">
                        {t('DETAIL_PAGE.EXPRESS_DETAIL.ROUND_EXPRESS')}
                        {index + 1}
                    </div>
                    <div className="grid grid-cols-2 w-full ">
                        <div className="content-left">
                            <label className="content-left">
                                {`
                                        ${t('DETAIL_PAGE.EXPRESS_DETAIL.AMOUNT')}
                                        (${t('DETAIL_PAGE.EXPRESS_DETAIL.AMOUNT_UNIT')})
                                        `}
                            </label>
                            <Input
                                maxLength={8}
                                onInput={e => {
                                    e.currentTarget.value = e.currentTarget.value.replace(/[^0-9]/g, '').replace(/^0+/, '');
                                }}
                                style={{
                                    borderRadius: '10px',
                                    borderColor: errors.transport?.[index]?.weight ? 'red' : '',
                                }}
                                className="input border-curve"
                                placeholder={''}
                                {...register(`transport.${index}.weight`, {
                                    required:
                                        t('ERROR_MSG_FORM.ERROR_FORM_PREFIX_MSG') +
                                        t('DETAIL_PAGE.EXPRESS_DETAIL.AMOUNT') +
                                        t('ERROR_MSG_FORM.ERROR_FORM_SUFFIX_MSG'),
                                    valueAsNumber: true,
                                    min: {
                                        value: 1,
                                        message: t('DETAIL_PAGE.EXPRESS_DETAIL.AMOUNT') + t('ERROR_MSG_FORM.ERROR_AMT_GT_MSG') + 1,
                                    },
                                })}
                            />
                            {errors.transport?.[index]?.weight && <div className="text-red-500 text-left">{errors.transport[index].weight.message}</div>}
                        </div>
                        <div className="content-left">
                            <label className="content-left">
                                {`
                                        ${t('DETAIL_PAGE.EXPRESS_DETAIL.DISTANCT')}
                                        (${t('DETAIL_PAGE.EXPRESS_DETAIL.DISTANCT_UNIT')})
                                        `}
                            </label>
                            <Input
                                size="sm"
                                maxLength={8}
                                onInput={e => {
                                    e.currentTarget.value = e.currentTarget.value.replace(/[^0-9]/g, '').replace(/^0+/, '');
                                }}
                                style={{
                                    borderRadius: '10px',
                                    borderColor: errors.transport?.[index]?.distance ? 'red' : '',
                                }}
                                className="input border-curve"
                                placeholder={''}
                                {...register(`transport.${index}.distance`, {
                                    required:
                                        t('ERROR_MSG_FORM.ERROR_FORM_PREFIX_MSG') +
                                        t('DETAIL_PAGE.EXPRESS_DETAIL.DISTANCT') +
                                        t('ERROR_MSG_FORM.ERROR_FORM_SUFFIX_MSG'),
                                    valueAsNumber: true,
                                    min: {
                                        value: 1,
                                        message: t('DETAIL_PAGE.EXPRESS_DETAIL.DISTANCT') + t('ERROR_MSG_FORM.ERROR_AMT_GT_MSG') + 1,
                                    },
                                })}
                            />
                            {errors.transport?.[index]?.distance && <div className="text-red-500 text-left">{errors.transport[index].distance.message}</div>}
                        </div>
                    </div>
                    {index !== 0 ? (
                        <div className="cursor-pointer text-red-500" onClick={() => remove(index)}>
                            <Iconify icon="gg:trash" width={24} />
                        </div>
                    ) : (
                        <div className="w-8"></div>
                    )}
                </div>
            ))}
        </div>
    );
};

export default TransportForm;

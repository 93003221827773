import React, { useEffect, useState, MouseEvent } from 'react';
import { formatterNumber, currentDateInThai, currentDateInEng } from '../../constants/screen/util';
import { useNavigate } from 'react-router-dom';
import { ErrorNotify } from '../../constants/screen/toast';
import { useTranslation } from 'react-i18next';

export default function CertificateExport() {
    const [summaryTotal, setSummaryTotal] = useState<any>({});
    const [projectData, setProjectData] = useState<any>({});
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();

    useEffect(() => {
        let tmpCreateProjectStr = sessionStorage.getItem('tmpCreateData');
        let tmpCreateProject = tmpCreateProjectStr ? JSON.parse(tmpCreateProjectStr!) : {};
        if (tmpCreateProject.summaryCo2eq) {
            setSummaryTotal(tmpCreateProject.summaryCo2eq);
        }
        setProjectData(tmpCreateProject);
    }, []);

    function handleSubmitPostProject(e: MouseEvent<HTMLElement>) {
        //submit post event
        let listProjectDataStr = sessionStorage.getItem('listProjectData');
        let arrProjectData = listProjectDataStr ? JSON.parse(listProjectDataStr) : [];
        let tmpCreateProjectStr = sessionStorage.getItem('tmpCreateData');
        let tmpCreateProject = tmpCreateProjectStr ? JSON.parse(tmpCreateProjectStr!) : {};
        tmpCreateProject.submitPost = new Date().getTime();
        tmpCreateProject.status = 'success'; //Change status to post when what to display "paid" status and delete below criteria
        //================ delete here ==================
        if (tmpCreateProject?.paidStatus === 'pending') {
            tmpCreateProject.status = 'post';
        }
        //===============================================
        let indexOfProjectTarget = arrProjectData.findIndex((item: any) => item.id === tmpCreateProject?.id);
        if (indexOfProjectTarget === -1) {
            ErrorNotify('Something went wrong !!!, please try again later.');
        } else {
            arrProjectData[indexOfProjectTarget] = tmpCreateProject;
            sessionStorage.setItem('listProjectData', JSON.stringify(arrProjectData));
            sessionStorage.setItem('tmpCreateData', '');
        }
        navigate('/');
    }

    return (
        <div className="box" style={{ marginBlockStart: '80px', borderRadius: '90px' }}>
            <div className="columns is-mobile">
                <div className="column">
                    <h1 className="content-header font-bold">{t('CERTIFICATE_PAGE.HEADER')}</h1>
                    <h1 className="content-sub-header">{t('CERTIFICATE_PAGE.TITLE')}</h1>
                    <br />
                    <br />
                    <div className="circle">
                        <h1 className="content-center" style={{ color: 'black', marginBlockStart: '35px', marginBottom: '15px' }}>
                            {t('CERTIFICATE_PAGE.OFFSET_TITLE')}
                        </h1>
                        <h1 className="content-header font-bold" style={{ fontSize: '45px', color: 'black', marginBlockStart: '-20px' }}>
                            {formatterNumber.format(summaryTotal?.total)}
                        </h1>
                        <h1 className="content-center" style={{ color: 'black' }}>
                            {t('CERTIFICATE_PAGE.OFFSET_TITLE_UNIT')}
                        </h1>
                    </div>
                    <br />
                    <br />
                    <p>{i18n.language === 'th' ? currentDateInThai : currentDateInEng}</p>
                    <h1 className="content-header-s font-bold">{projectData?.projectName}</h1>
                    <br />
                    <h1>{projectData?.projectOwner}</h1>
                </div>
            </div>
            <div className="columns">
                <div className="column is-4 is-offset-4" style={{ alignItems: 'right' }}>
                    <br />
                    {/* <a className="button is-large is-fullwidth App" href={Certificate} style={{ backgroundColor: "#C8F026", fontSize: "18px", height: "70px" }} onClick={handleSubmitPostProject} download>{t("CERTIFICATE_PAGE.BUTTON")}</a> */}
                    <button
                        className="button is-large is-fullwidth App"
                        style={{ backgroundColor: '#C8F026', fontSize: '18px', height: '70px' }}
                        onClick={handleSubmitPostProject}
                    >
                        {t('CERTIFICATE_PAGE.BUTTON')}
                    </button>
                </div>
            </div>
            <p className="content-left" style={{ marginInlineStart: '60px' }}>
                <span className="has-text-danger">* </span>
                {t('CERTIFICATE_PAGE.FOOTER')}
            </p>
        </div>
    );
}

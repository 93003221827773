import { useQuery } from '@tanstack/react-query';
import api from '../axios';
import { useEffect, useState } from 'react';

export const useOverview = (subCategoryId: string, year: string, quarter: string) => {
    const [isPreFetching, setIsPreFetching] = useState(true);
    useEffect(() => {
        const timer = setTimeout(() => {
            setIsPreFetching(false);
        }, 2000);

        return () => clearTimeout(timer);
    }, []);
    const query = useQuery({
        queryKey: ['overview', subCategoryId, year, quarter],
        queryFn: async () => {
            const { data } = await api.get(`/overview?subCategoryId=${subCategoryId}&year=${year}&quarter=${quarter}`);
            return data;
        },
        staleTime: 1000,
        refetchIntervalInBackground: true,
        refetchOnWindowFocus: false,
        // refetchInterval: 1000,
    });
    return { ...query, isPreFetching };
};

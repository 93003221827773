import React, { useEffect } from 'react';
const payment_paid_url = process.env.REACT_APP_PAYMENT_PAID_URL;
const chill_pay_api_key = process.env.REACT_APP_CHILL_PAY_API_KEY;
const ChillPayWidget = ({
    totalPaid,
    eventId,
    paymentURL,
    merchantID,
    customerID,
    mobileNo,
}: {
    customerID: number;
    totalPaid: number;
    eventId: number;
    paymentURL: string;
    merchantID: string;
    mobileNo?: string | undefined;
}) => {
    useEffect(() => {
        const existingScript = document.querySelector(`script[src='${paymentURL}']`);
        if (existingScript) {
            return;
        } else {
            const script = document.querySelector(`script[src='${paymentURL}']`);
            if (script) {
                document.body.removeChild(script);
            }
        }
        const script = document.createElement('script');
        script.src = paymentURL;
        script.async = true;
        script.onerror = () => {};
        document.body.appendChild(script);
    }, [paymentURL]);

    const total = Math.ceil(totalPaid) * 100;
    const htmlSend = `
        <modernpay:widget id="modernpay-widget-container" 
        data-merchantid=${merchantID} 
        data-amount="${total}"
        data-orderno=${eventId}
        data-customerID="${customerID}" 
        data-mobileno="${mobileNo}" 
        data-clientip="182.232.181.139" data-routeno="1" data-currency="764" 
        data-description="ZeroCarbon Payment" data-apikey="${chill_pay_api_key}">
        </modernpay:widget>`;
    return (
        <>
            <form id="payment-form" action={payment_paid_url} method="post" className="form-horizontal">
                <div
                    dangerouslySetInnerHTML={{
                        __html: htmlSend,
                    }}
                />
                <input type="hidden" name="amount" value={total} />
            </form>
        </>
    );
};
export default ChillPayWidget;

import React, { useState } from 'react';
import { emptyRows, applyFilter, getComparator } from '@src/components/layout/admin/components/TableZeroCarbon/utils';
import Scrollbar from '@src/components/scrollbar';
import { Card, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TablePagination } from '@mui/material';
import TableHeadZero from '@src/components/layout/admin/components/TableZeroCarbon/TableHead';
import TableEmptyRows from '@src/components/layout/admin/components/TableZeroCarbon/TableEmptyRows';
import TableToolBar from '@src/components/layout/admin/components/TableZeroCarbon/TableToolBar';
import { useNavigate } from 'react-router';

import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import TableRowZeroCarbon from '../../../components/TableZeroCarbon/TableRow';
import { formatDate } from '@src/utils/format-date-th';
import { useDeleteEventQuery, usePreEventsQuery } from '@src/services/queries/useEventsQuery';
import TableNoData from '../../../components/TableZeroCarbon/TableNoData';
import TableLoading from '../../../components/TableZeroCarbon/TableLoading';
import { EventEmission } from '@src/types';
import { CompensateStatusPreEvent } from '../../../components/Element/CompensateStatusBadge';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import Swal from 'sweetalert2';
import { config } from '@src/config';

interface PreEventTableProp {
    filterSelect: {
        projectType: string;
        years: string;
        quarter: string;
    };
}
const PreEventTable = ({ filterSelect }: PreEventTableProp) => {
    const [page, setPage] = useState(0);
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('all');
    const [search, setSearch] = useState('');
    const [rowsPerPage, setRowsPerPage] = useState(20);
    const deleteEventMutation = useDeleteEventQuery();

    const { data, isLoading } = usePreEventsQuery(filterSelect.projectType, filterSelect.years, filterSelect.quarter, search, page + 1, rowsPerPage);

    const navigate = useNavigate();

    const handleSort = (event: any, id: any) => {
        const isAsc = orderBy === id && order === 'asc';
        if (id !== '') {
            setOrder(isAsc ? 'desc' : 'asc');
            setOrderBy(id);
        }
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: any) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleFilter = (query: string) => {
        setPage(0);
        setSearch(query);
    };

    const rowPreEvents = data?.data || [];
    const newRowPreEvents = rowPreEvents.map((event: any) => {
        event.subCategoryName = event?.subCategory?.name || '';
        event.summary = event?.emission?.summary || 0;
        return event;
    });
    const preEvents = newRowPreEvents || [];

    const dataFiltered = applyFilter({
        inputData: preEvents,
        comparator: getComparator(order, orderBy),
        filterName: search,
    });

    const notFound = !dataFiltered.length && !!search;

    const handleView = (id: number | undefined) => {
        navigate(`/admin/pre-event/${id}`);
    };
    const handlePreEvent = (id: number) => {
        Swal.fire({
            title: 'ต้องการลบ Event นี้?',
            text: 'คุณต้องการลบ Event นี้หรือไม่?',
            icon: 'error',
            showCancelButton: true,
            confirmButtonColor: '#1AA238',
            cancelButtonColor: '#d33',
            cancelButtonText: 'ยกเลิก',
            confirmButtonText: 'ยืนยัน',
            customClass: {
                popup: 'popup-backend',
            },
        }).then((result: any) => {
            if (result.isConfirmed) {
                deleteEventMutation.mutateAsync(id as number);
            }
        });
    };

    const TableRowBody = ({ index, rows }: { index: number; rows: EventEmission }) => {
        const { id, name, subCategoryName, location, startDate, compensateStatus, eventMember, endDate, paymentStatus, summary } = rows;
        return (
            <>
                {' '}
                <TableCell
                    sx={{
                        textAlign: 'center',
                    }}
                >
                    {index + 1}
                </TableCell>
                <TableCell scope="row" className="text-xs whitespace-nowrap text-left">
                    <div className="truncate max-w-72 flex flex-col" title={name}>
                        {name}
                        <span className="text-black/80 font-light">{location}</span>
                    </div>
                </TableCell>
                <TableCell className="text-xs">
                    <div>{subCategoryName}</div>
                </TableCell>
                <TableCell className="text-xs">
                    <div className="text-center"> {eventMember ? eventMember : 0} </div>
                </TableCell>
                <TableCell className="text-xs">
                    <div className="text-center">{summary ? summary : 0}</div>
                </TableCell>
                <TableCell className="text-xs">
                    {formatDate(startDate)}
                    {' - '}
                    {formatDate(endDate)}
                </TableCell>
                <TableCell align="center">
                    <div className="flex justify-center">
                        <CompensateStatusPreEvent paymentStatus={paymentStatus} compensateStatus={compensateStatus} />
                    </div>
                </TableCell>
                <TableCell align="right">
                    <IconButton aria-label="view" size="small">
                        <VisibilityOutlinedIcon onClick={() => handleView(id)} />
                    </IconButton>
                    <IconButton aria-label="delete" size="small">
                        <DeleteOutlinedIcon onClick={() => handlePreEvent(id as number)} />
                    </IconButton>
                </TableCell>
            </>
        );
    };
    return (
        <>
            <TableToolBar onSearch={handleFilter} />
            <Scrollbar>
                <Card>
                    <TableContainer component={Paper}>
                        <Table
                            sx={{
                                minWidth: {
                                    sm: 1700,
                                    md: 1600,
                                    lg: 1300,
                                },
                            }}
                            size={'small'}
                        >
                            <colgroup>
                                <col width="1%" />
                                <col width="13%" />
                                <col width="15%" />
                                <col width="15%" />
                                <col width="20%" />
                                <col width="15%" />
                                <col width="10%" />
                                <col width="10%" />
                            </colgroup>
                            <TableHeadZero
                                order={order}
                                orderBy={orderBy}
                                rowCount={dataFiltered.length}
                                onRequestSort={handleSort}
                                headLabel={[
                                    { id: '', label: 'ลำดับ', align: 'center' },
                                    { id: 'name', label: 'ชื่อกิจกรรม', align: 'left' },
                                    { id: 'subCategoryName', label: 'ประเภทกิจกรรม', align: 'left' },
                                    { id: 'eventMember', label: 'จำนวนผู้ร่วม (คน/วัน)', align: 'center' },
                                    {
                                        id: 'summary',
                                        label: ' ปริมาณคาร์บอนฟุตพริ้นท์รวม (ตัน)	',
                                        align: 'left',
                                    },
                                    { id: 'startDate', label: 'วันที่จัดกรรม', align: 'center' },
                                    { id: 'compensateStatus', label: 'สถานะ', align: 'center' },
                                    { id: '', label: 'เครื่องมือ' },
                                ]}
                            />
                            <TableBody sx={{ fontSize: '0.75rem' }}>
                                {isLoading && <TableLoading />}

                                {dataFiltered.map((row, index) => (
                                    <TableRowZeroCarbon key={index} rows={row} index={index} TableRowBody={<TableRowBody index={index} rows={row} />} />
                                ))}
                                {/* <TableEmptyRows height={notFound ? 2 : 5} emptyRows={emptyRows(page, rowsPerPage, dataFiltered.length)} /> */}
                                {notFound && <TableNoData query={search} />}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Card>
            </Scrollbar>
            <TablePagination
                count={data?.total || 0}
                onPageChange={handleChangePage}
                page={page}
                rowsPerPage={rowsPerPage}
                rowsPerPageOptions={config.rowsPerPageOptions}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </>
    );
};

export default PreEventTable;

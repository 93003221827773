import { Transport } from '@src/types';
import { formatNumber } from '@src/utils/format-number';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

const TransportEmission = ({ transport, ef }: { transport?: Transport[]; ef: number }) => {
    const { t } = useTranslation();

    const { totalDistance, totalWeight } = useMemo(() => {
        return (transport || []).reduce(
            (totals, tp) => {
                totals.totalDistance += tp.distance || 0;
                totals.totalWeight += tp.weight || 0;
                return totals;
            },
            { totalDistance: 0, totalWeight: 0 },
        );
    }, [transport]);

    const { totalCarbonFootprint } = useMemo(() => {
        return (transport || []).reduce(
            (totals, tp) => {
                totals.totalCarbonFootprint += tp.distance * tp.weight * ef || 0;
                return totals;
            },
            { totalCarbonFootprint: 0 },
        );
    }, [transport, ef]);

    const newTotalCarbonFootprint = (Math.floor(totalCarbonFootprint * 100) / 100).toFixed(2);
    return (
        <div className="mt-4">
            <div className="flex justify-between items-center font-normal">
                <div className=" text-black"> {t('DETAIL_PAGE.EXPRESS_DETAIL.AMOUNT')}</div>
                <div className=" text-green-600">
                    <span className="px-1">{formatNumber(totalWeight) || 0}</span>
                    {t('DETAIL_PAGE.EXPRESS_DETAIL.AMOUNT_UNIT')}
                </div>
            </div>
            <div className="flex justify-between items-center font-normal">
                <div className=" text-black"> {t('DETAIL_PAGE.EXPRESS_DETAIL.DISTANCT')}</div>
                <div className=" text-green-600">
                    <span className="px-1">{formatNumber(totalDistance) || 0}</span>
                    {t('DETAIL_PAGE.EXPRESS_DETAIL.DISTANCT_UNIT')}
                </div>
            </div>
            <div className="flex justify-between items-center font-semibold">
                <div className=" text-black"> {t('DETAIL_PAGE.CARBON_FOOTPRINT')}</div>
                <div className=" text-black"> {totalCarbonFootprint ? newTotalCarbonFootprint : 0} kgCO2eq</div>
            </div>
        </div>
    );
};

export default TransportEmission;

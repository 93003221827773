import type { IconButtonProps } from '@mui/material/IconButton';

import { useState, useCallback } from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import Popover from '@mui/material/Popover';
import Divider from '@mui/material/Divider';
import MenuList from '@mui/material/MenuList';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuItem, { menuItemClasses } from '@mui/material/MenuItem';
import { usePathname, useRouter } from '@src/hooks';
import { Profile } from '@src/utils/state-profile';
import { useTranslation } from 'react-i18next';
import Iconify from '../layout/admin/components/iconify';
import Swal from 'sweetalert2';
import { removeCookie } from '@src/utils/remove-cookie';
import { config } from '@src/config';
import { googleLogout } from '@react-oauth/google';

export type AccountPopoverProps = IconButtonProps & {
    data?: {
        label: string;
        href: string;
        icon?: React.ReactNode;
        info?: React.ReactNode;
    }[];
};

const myAccount = {
    photoURL: '',
    displayName: '',
    email: '',
    fullName: '',
};
export function AccountPopover({ data = [], sx, ...other }: AccountPopoverProps) {
    const { t } = useTranslation();

    const profile = Profile();
    myAccount.displayName = profile?.firstName;
    myAccount.email = profile?.email;
    myAccount.photoURL = profile?.image;
    myAccount.fullName = `${profile?.firstName} ${profile?.lastName}`;

    const router = useRouter();

    const pathname = usePathname();

    const [openPopover, setOpenPopover] = useState<HTMLButtonElement | null>(null);

    const handleOpenPopover = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
        setOpenPopover(event.currentTarget);
    }, []);

    const handleClosePopover = useCallback(() => {
        setOpenPopover(null);
    }, []);

    const handleClickItem = useCallback(
        (path: string) => {
            handleClosePopover();
            router.push(path);
        },
        [router, handleClosePopover],
    );

    function onLogout() {
        const profile = Profile();
        Swal.fire({
            html: `<b>` + t('SETTING_PAGE.ALERT_POPUP.LOGOUT_POPUP.TITLE') + `</b><p>` + t('SETTING_PAGE.ALERT_POPUP.LOGOUT_POPUP.TEXT') + `</p>`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: t('SETTING_PAGE.ALERT_POPUP.LOGOUT_POPUP.CANCEL_BTN'),
            confirmButtonText: t('SETTING_PAGE.ALERT_POPUP.LOGOUT_POPUP.CONFIRM_BTN'),
            width: '20em',
        }).then(result => {
            if (result.isConfirmed) {
                removeCookie(config.keyNameCookie);
                if (profile.authType === 'google') {
                    googleLogout();
                }
                window.location.href = '/login';
            } else {
                handleClosePopover();
            }
        });
    }
    return (
        <>
            <Box sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={(event: any) => handleOpenPopover(event)}>
                <IconButton
                    onClick={handleOpenPopover}
                    sx={{
                        p: '2px',
                        width: 40,
                        height: 40,
                        ...sx,
                    }}
                    {...other}
                >
                    <Avatar src={myAccount.photoURL} alt={myAccount.displayName} sx={{ width: 1, height: 1 }}>
                        {myAccount.displayName.charAt(0).toUpperCase()}
                    </Avatar>
                </IconButton>
                <Typography variant="subtitle1" sx={{ ml: 1, fontWeight: 400, fontFamily: 'kanit' }}>
                    {myAccount.displayName}
                </Typography>
            </Box>

            <Popover
                open={!!openPopover}
                anchorEl={openPopover}
                onClose={handleClosePopover}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                slotProps={{
                    paper: {
                        sx: { width: 200 },
                    },
                }}
            >
                <Box sx={{ p: 2, pb: 1.5 }}>
                    <Typography variant="subtitle2" noWrap sx={{ overflow: 'hidden', textOverflow: 'ellipsis', width: '11rem' }}>
                        {myAccount?.fullName}
                    </Typography>

                    <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
                        {myAccount?.email}
                    </Typography>
                </Box>

                {/* <Divider sx={{ borderStyle: 'dashed' }} /> */}

                <MenuList
                    disablePadding
                    sx={{
                        p: 0,
                        gap: 0.5,
                        display: 'flex',
                        flexDirection: 'column',
                        [`& .${menuItemClasses.root}`]: {
                            px: 1,
                            gap: 2,
                            borderRadius: 0.75,
                            color: 'text.secondary',
                            '&:hover': { color: 'text.primary' },
                            [`&.${menuItemClasses.selected}`]: {
                                color: 'text.primary',
                                bgcolor: 'action.selected',
                                fontWeight: 'fontWeightSemiBold',
                            },
                        },
                    }}
                >
                    {data.map(option => (
                        <MenuItem key={option.label} selected={option.href === pathname} onClick={() => handleClickItem(option.href)}>
                            {option.icon}
                            {option.label}
                        </MenuItem>
                    ))}
                </MenuList>

                <Box sx={{ p: 0.5 }}>
                    <Divider sx={{ borderStyle: 'dashed' }} />

                    <Button fullWidth onClick={onLogout} color="error" size="medium" variant="text" className="!font-Kanit !capitalize gap-1">
                        <Iconify icon="carbon:logout" /> {t('SETTING_PAGE.LOGOUT')}
                    </Button>
                </Box>
            </Popover>
        </>
    );
}

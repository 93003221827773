import React from 'react';
import { Navigate, Routes as ReactRoutes, Route } from 'react-router-dom';
import Dashboard from '../pages/Dashboard';
import Register from '../pages/Register';
import Login from '../pages/Login';
import Setting from '../pages/Setting';
import PayMentSuccess from '../pages/payment-success';
import ViewSummary from '@src/pages/ViewSummary';
import { SettingLocation } from '@src/pages/Setting-Location';
import GuestDashboard from '@src/pages/Guest/Dashboard';
import GuestLayOut from '@src/pages/Guest/layouts/guest-layout';
import GuestRegister from '@src/pages/Guest/Register';
import ForgetPassword from '@src/pages/ForgetPassword';

import AdminDashboardPage from '../pages/admin/Dashboard';
import PreEventPage from '../pages/admin/PreEvent';
import PostEventPage from '../pages/admin/PostEvent';
import ProjectPage from '../pages/admin/Project';
import UsersPage from '../pages/admin/Users';
import PreEventViewPage from '../pages/admin/PreEvent/view';
import PostEventViewPage from '@src/pages/admin/PostEvent/view';
import ProfilePage from '../pages/admin/Profile';
import ProfileResetPasswordPage from '../pages/admin/Profile/reset-password';

import AdminLayout from '../components/layout/AdminLayout';
import NotFoundPage from '../pages/NotFound';
import ProtectedRoute from './ProtectedRoute';
import { DashboardLayout } from '@src/layouts/dashboard';

import ManageLocationsPage from '../pages/admin/ManageLocations';
import UnauthorizedPage from '@src/pages/admin/UnauthorizedPage';

export function AuthRoutes() {
    return (
        <ReactRoutes>
            <Route path={'login'} element={<Login />} />
            <Route path={'forget-password/:token'} element={<ForgetPassword />} />
            <Route path="*" element={<Navigate to="/login" replace />} />
        </ReactRoutes>
    );
}

export function GuestRoutes() {
    return (
        <ReactRoutes>
            <Route path="/login" element={<Login />} />;
            <Route element={<GuestLayOut />}>
                <Route path="/guest-dashboard" element={<GuestDashboard />} />;
                <Route path="/guest-register/:event/:id" element={<GuestRegister />} />;
                <Route path="*" element={<Navigate to="/guest-dashboard" replace />} />
            </Route>
        </ReactRoutes>
    );
}

export function AppRoutes() {
    return (
        <ReactRoutes>
            <Route element={<DashboardLayout />}>
                <Route path="/" element={<Dashboard />} />;
                <Route path="/register/:event/:id" element={<Register />} />;
                <Route path="/view-summary/:id" element={<ViewSummary />} />;
                <Route path="/payment-success" element={<PayMentSuccess />} />;
                <Route path="/setting" element={<Setting />} />;
                <Route path="/setting-location" element={<SettingLocation />} />;
            </Route>
            <Route path="*" element={<Navigate to="/" replace />} />
        </ReactRoutes>
    );
}

export function AdminRoutes({ role }: { role: string }) {
    const routerAdmin = [
        { path: '/admin', component: AdminDashboardPage },
        { path: '/admin/pre-event', component: PreEventPage },
        { path: '/admin/pre-event/:id', component: PreEventViewPage },
        { path: '/admin/users', component: UsersPage },
        // { path: '/admin/post-event', component: PostEventPage },
        // { path: '/admin/post-event/:id', component: PostEventViewPage },
        // { path: '/admin/project', component: ProjectPage },
        // { path: '/admin/profile', component: ProfilePage },
        // { path: '/admin/profile/reset-password', component: ProfileResetPasswordPage },
    ];
    return (
        <ReactRoutes>
            <Route element={<AdminLayout />}>
                <Route
                    path={'/admin/manage-locations'}
                    element={<ProtectedRoute userRole={role} component={ManageLocationsPage} allowedRoles={['admin', 'event_manager']} />}
                />

                <Route
                    path={'/admin/post-event'}
                    element={<ProtectedRoute userRole={role} component={PostEventPage} allowedRoles={['admin', 'project_owner']} />}
                />
                <Route
                    path={'/admin/post-event/:id'}
                    element={<ProtectedRoute userRole={role} component={PostEventViewPage} allowedRoles={['admin', 'project_owner']} />}
                />
                <Route path={'/admin/project'} element={<ProtectedRoute userRole={role} component={ProjectPage} allowedRoles={['admin', 'project_owner']} />} />

                {routerAdmin.map(({ path, component }) => (
                    <Route key={path} path={path} element={<ProtectedRoute userRole={role} component={component} allowedRoles={['admin']} />} />
                ))}

                <Route path={'/admin/profile'} element={<ProtectedRoute userRole={role} component={ProfilePage} allowedRoles={['admin', 'event_manager']} />} />
                <Route
                    path={'/admin/profile/reset-password'}
                    element={<ProtectedRoute userRole={role} component={ProfileResetPasswordPage} allowedRoles={['admin', 'event_manager']} />}
                />
                <Route element={<ProtectedRoute userRole={role} component={NotFoundPage} allowedRoles={['admin']} />} />
            </Route>
            <Route path="/unauthorized" element={<UnauthorizedPage />} />;
            <Route
                path="*"
                element={
                    <Navigate to={role === 'event_manager' ? '/admin/manage-locations' : role === 'project_owner' ? '/admin/project' : '/admin'} replace />
                }
            />
        </ReactRoutes>
    );
}

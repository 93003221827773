import { Card, Stack } from '@mui/material';
import React from 'react';
import { ChartBar } from './chartBar';

const WidgetChartBar = ({ title, subTitle, sx, labels, data }: { title?: string; subTitle: string; sx?: object; labels?: string[]; data?: number[] }) => {
    const dataSets = {
        labels: labels,
        datasets: [
            {
                label: 'กิจกรรม',
                data: data,
                backgroundColor: ['rgba(10, 104, 71, 0.2)'],
                borderColor: ['rgb(10, 104, 71)'],
                borderWidth: 1,
            },
        ],
    };
    return (
        <Card
            component={Stack}
            spacing={3}
            direction="column"
            sx={{
                px: 2,
                py: 2,
                borderRadius: 1,
                boxShadow: 3,
            }}
            {...sx}
        >
            <div className="my-2 text-sm font-bold">
                {title}
                <span className="text-green-600">{subTitle}</span>
            </div>

            <ChartBar labels={labels} dataSets={dataSets} />
        </Card>
    );
};

export default WidgetChartBar;

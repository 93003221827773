import CryptoJS from 'crypto-js';
const secretKey = 'zero-carbon-secret-g-key';
const nameLocalStorage = 'zero_carbon_g';
export const keyStatePreEvent = 'guest-pre-event';

export const setGuest = (guest: string) => {
    const jsonData = JSON.stringify(guest);
    const encryptedData = CryptoJS.AES.encrypt(jsonData, secretKey).toString();
    localStorage.setItem(nameLocalStorage, encryptedData);
};

export const getGuest = () => {
    const encryptedData = localStorage.getItem(nameLocalStorage);
    if (!encryptedData) return '';
    const bytes = CryptoJS.AES.decrypt(encryptedData as string, secretKey);
    const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    return decryptedData;
};

export const getStatePreEventGuest = () => {
    const encryptedData = localStorage.getItem('zero_carbon_pre');
    if (!encryptedData) return '';
    const bytes = CryptoJS.AES.decrypt(encryptedData as string, secretKey);
    const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    return decryptedData;
};

export const storePreEventGuest = (key: string, preEvent: any) => {
    const jsonArray = JSON.stringify(preEvent);
    const encryptedData = CryptoJS.AES.encrypt(jsonArray, secretKey).toString();
    localStorage.setItem(key, encryptedData);
};

export const getPreEventGuest = () => {
    const encryptedData = localStorage.getItem(keyStatePreEvent);
    if (!encryptedData) return [];
    try {
        const decrypted = CryptoJS.AES.decrypt(encryptedData as string, secretKey);
        const jsonArray = decrypted.toString(CryptoJS.enc.Utf8);
        if (!jsonArray) {
            return [];
        }
        const decryptedData = JSON.parse(jsonArray);
        return decryptedData;
    } catch (error) {
        console.error('Decryption failed: ', error);
        // Return empty array if decryption fails
        return [];
    }
};

export const addPreEventGuest = (preEvent: any) => {
    const decryptedData = getPreEventGuest();
    decryptedData.push(preEvent);
    storePreEventGuest(keyStatePreEvent, decryptedData);
};

export const updatePreEventByIdGuest = (id: number, updatePreEvent: any) => {
    const decryptedData = getPreEventGuest();
    const existingData = decryptedData || [];
    const itemIndex = existingData.findIndex((item: any) => item.id === id);
    if (itemIndex !== -1) {
        existingData[itemIndex] = { ...existingData[itemIndex], ...updatePreEvent };
        storePreEventGuest(keyStatePreEvent, existingData);
    }
};

export const removePreEventByIdGuest = (id: number) => {
    const decryptedData = getPreEventGuest();
    let existingData = [];
    try {
        existingData = decryptedData;
    } catch (error) {
        console.error('Error parsing localStorage data:', error);
        existingData = [];
    }
    const updatedData = existingData.filter((item: any) => item.id !== id);
    if (updatedData.length !== existingData.length) {
        storePreEventGuest(keyStatePreEvent, updatedData);
    } else {
        console.error(`Item with id ${id} not found.`);
    }
};

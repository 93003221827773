import { useMutation, useQuery } from '@tanstack/react-query';
import api from '../axios';

export const useUpdateProfile = () => {
    return useMutation({
        /**
         * Update a user's profile information
         * @param {number} userId the ID of the user to update
         * @param {Object} user the user's profile information
         * @param {string} user.firstName the user's first name
         * @param {string} user.lastName the user's last name
         * @param {string} user.phoneNumber the user's phone number
         * @returns {Promise<Object>} the updated user object
         */
        mutationFn: async ({
            userId,
            user,
        }: {
            userId: number;
            user: {
                firstName: string;
                lastName: string;
                phoneNumber: string;
            };
        }) => {
            try {
                const { data } = await api.patch(`/user/${userId}/profile`, user);
                return data;
            } catch (error) {
                throw error;
            }
        },
    });
};

export const useProfile = () => {
    return useQuery({
        queryKey: ['profile'],
        queryFn: async () => {
            try {
                const { data } = await api.get(`/user/profile`);
                return data;
            } catch (error) {
                console.error(error);
                throw error;
            }
        },
    });
};

import { useMutation, useQuery } from '@tanstack/react-query';
import api from '../axios';

export const useEvidenceEventsById = (EventId: number) => {
    return useQuery({
        queryKey: ['EvidenceEventById', EventId],
        queryFn: async () => {
            try {
                const { data, status } = await api.get(`/events/${EventId}/evidences`);
                return { data, status };
            } catch (error) {
                console.error(error);
                throw error;
            }
        },
        enabled: !!EventId,
    });
};

export const useDeleteEvidenceById = () => {
    return useMutation({
        mutationFn: async (evidencesPath: { evidenceId: number; url: string; type: string }) => {
            try {
                const { data, status } = await api.delete(`/evidences/${evidencesPath.evidenceId}?url=${evidencesPath.url}&type=${evidencesPath.type}`);
                return {
                    data,
                    status,
                };
            } catch (error: any) {
                return {
                    data: error?.response?.data,
                    status: error?.response?.status,
                };
            }
        },
    });
};

import React, { useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useTranslation } from 'react-i18next';
import { useDeleteUser } from '@src/services/queries/useAuthQuery';
import { Profile } from '@src/utils/state-profile';
import { removeCookie } from '@src/utils/remove-cookie';
import { config } from '@src/config';
const DeleteUserDialog = () => {
    const { t, i18n } = useTranslation();
    const deleteUserQuery = useDeleteUser();
    const [open, setOpen] = React.useState(false);
    const [inputDelete, setInputDelete] = useState('');

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const profile = Profile();
    return (
        <div>
            <button
                className="button is-medium is-fullwidth content-left is-danger is-light"
                id="delAcct"
                onClick={handleClickOpen}
                style={{ borderRadius: '10px', borderWidth: '1px', borderColor: '#dbdbdb' }}
            >
                <span className="icon" style={{ color: '#C54A4A' }}>
                    <i className="fa-solid fa-trash" id="delAcctIcon"></i>
                </span>
                <span className="content-sub-header" id="delAcctText">
                    {t('SETTING_PAGE.DELETE_ACCOUNT')}
                </span>
            </button>
            <Dialog
                fullWidth={true}
                maxWidth={'sm'}
                open={open}
                onClose={handleClose}
                PaperProps={{
                    component: 'form',
                    onSubmit: async (event: React.FormEvent<HTMLFormElement>) => {
                        event.preventDefault();
                        const formData = new FormData(event.currentTarget);
                        const formJson = Object.fromEntries((formData as any).entries());
                        const deleteValue = formJson.delete;
                        if (deleteValue === 'Delete') {
                            const res = await deleteUserQuery.mutateAsync(profile.mapId);
                            if (res.status === 200) {
                                handleClose();
                                removeCookie(config.keyNameCookie);
                                setTimeout(() => {
                                    window.location.reload();
                                }, 1000);
                            }
                        }
                    },
                }}
            >
                <DialogTitle>{i18n.language === 'en' ? 'Delete Account' : i18n.language === 'th' ? 'ลบบัญชี' : '藝術本身'}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {i18n.language === 'en'
                            ? 'Once your account is removed from our database, it cannot be recovered.'
                            : i18n.language === 'th'
                              ? 'การลบบัญชีของคุณออกจากฐานข้อมูลของเรา เมื่อดำเนินการแล้วจะไม่สามารถกู้คืนได้'
                              : '从我们的数据库中删除您的帐户一旦完成，就无法恢复。'}
                    </DialogContentText>
                    <div className="">
                        {i18n.language === 'en' ? 'Confirm message' : i18n.language === 'th' ? 'ยืนยันข้อความ' : '确认消息'}
                        <span className="text-red-600 px-1">"Delete"</span>
                    </div>
                    <TextField
                        autoFocus
                        required
                        margin="dense"
                        id="delete"
                        name="delete"
                        onChange={e => setInputDelete(e.target.value)}
                        fullWidth
                        variant="outlined"
                    />
                </DialogContent>
                <DialogActions className="px-2">
                    <Button onClick={handleClose} variant="outlined">
                        {t('BUTTONS.CANCEL')}
                    </Button>
                    <Button type="submit" disabled={inputDelete !== 'Delete'} color="success" variant="contained">
                        {t('BUTTONS.COMFIRM')}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default DeleteUserDialog;

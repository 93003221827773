import React, { useState, RefCallback, MouseEvent, ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Swal from 'sweetalert2';
import { HStack, PinInput, PinInputField } from '@chakra-ui/react';
import Logo from '../../constants/img/pin_logo.png';
import { useForm, SubmitHandler } from 'react-hook-form';
import './resetPassword.css';
import { useForgotPassword, useValidateOtp } from '@src/services/queries/useUsersQuery';
import { CircularProgress, Stack } from '@mui/material';
import { useNavigate } from 'react-router';

interface propsChangePassword {
    open: boolean;
    handleClose: () => void;
    handleOpen: RefCallback<any>;
}

interface resetPasswordStorage {
    password?: string;
    otp?: string;
    email?: string;
    isSuccess: boolean;
}

export default function ResetPassword(props: propsChangePassword) {
    const { open, handleClose } = props;
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [stateResetPassword, setStateResetPassword] = useState<number>(1);
    const [emailInput, setEmailInput] = useState<string>('');
    const [otpInput, setOtpInput] = useState<string>('');
    const [isDisableBtn, setIsDisableBtn] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const forgotPasswordQuery = useForgotPassword();
    const validateOtpQuery = useValidateOtp();

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm<resetPasswordStorage>();

    const handleOtpChange = (value: string) => {
        //coding for OTP change here
        if (value.length !== 6) {
            setIsDisableBtn(true);
        } else {
            setOtpInput(value);
            setIsDisableBtn(false);
        }
    };

    const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
        setEmailInput(e.target.value);
    };

    async function handleResendOTP(e: MouseEvent<HTMLAnchorElement>) {
        //coding to resend otp here
        if (emailInput) {
            const jsonSend: { email: string | undefined } = {
                email: emailInput,
            };
            const response = await forgotPasswordQuery.mutateAsync(jsonSend);
            if (response && response.status === 201) {
                Swal.fire({
                    html: `<p>ส่งขอรหัสสําเร็จ</p>`,
                    icon: 'warning',
                    width: '20em',
                    showConfirmButton: false,
                }).then(() => {
                    setStateResetPassword(2);
                });
            }
        }
    }

    function handleCancel() {
        setStateResetPassword(1);
        setEmailInput('');
        reset();
        handleClose();
    }

    const handleClickNext: SubmitHandler<resetPasswordStorage> = async e => {
        try {
            setIsLoading(true);

            if (stateResetPassword === 1) {
                const jsonSend: { email: string | undefined } = {
                    email: e.email,
                };
                const response = await forgotPasswordQuery.mutateAsync(jsonSend);
                if (response.status === 201) {
                    setIsLoading(false);
                    setStateResetPassword(2);
                }
            }

            if (stateResetPassword === 2) {
                const sendOtp: {
                    otp: string;
                    email: string | undefined;
                } = {
                    otp: otpInput,
                    email: e.email,
                };
                const { status, data } = await validateOtpQuery.mutateAsync(sendOtp);
                if (status === 201 && data.status) {
                    const { token } = data;
                    // setStateResetPassword(3);
                    Swal.fire({
                        icon: 'success',
                        title: 'Verification successful',
                        timer: 2000,
                    }).then(result => {
                        // window.location.href = `/forget-password/${token}`;
                        navigate(`/forget-password/${token}`);
                        handleCancel();
                    });
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: t('VERIFICATION_CODE_OTP'),
                        confirmButtonText: t('BUTTONS.CLOSE'),
                        confirmButtonColor: '#d33',
                    });
                }
                setIsLoading(false);
            }
        } catch (error: any) {
            if (error && error?.response?.status === 404) {
                setIsLoading(false);
                const { message } = error?.response?.data;
                Swal.fire({
                    html: `<p>` + message + `</p>`,
                    icon: 'error',
                    confirmButtonText: t('BUTTONS.CLOSE'),
                    confirmButtonColor: '#d33',
                }).then(result => {
                    if (result.isConfirmed) {
                    }
                });
            }
        }
    };

    return (
        <Dialog
            open={open}
            onClose={handleCancel}
            fullWidth
            maxWidth="sm"
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
            PaperProps={{
                style: {
                    borderRadius: '15px', // Adjust the value as needed
                },
            }}
        >
            <form onSubmit={handleSubmit(handleClickNext)} action={'#' + stateResetPassword}>
                <DialogTitle id="scroll-dialog-title">
                    <div className="columns">
                        <div className="column is-6">
                            <h3 className="content-sub-header content-left font-bold">{t('FORGET_PASSWORD_PAGE.HEADER' + stateResetPassword)}</h3>
                        </div>
                    </div>
                </DialogTitle>
                <hr style={{ backgroundColor: '#828693', marginTop: '-5px' }} />
                <DialogContent className="" dividers={false}>
                    {stateResetPassword === 2 ? (
                        <div className="columns">
                            <div className="column">
                                <img src={Logo} alt="Logo" />
                            </div>
                        </div>
                    ) : (
                        ''
                    )}
                    {[1, 2].includes(stateResetPassword) ? (
                        <div className="columns">
                            <div className="column">
                                <h3 className="content-sub-header font-bold">{t('FORGET_PASSWORD_PAGE.TITLE' + stateResetPassword)}</h3>
                                <p className="content-left">{t('FORGET_PASSWORD_PAGE.CONTENT' + stateResetPassword)}</p>
                            </div>
                        </div>
                    ) : (
                        ''
                    )}
                    {stateResetPassword === 1 ? (
                        <div className="columns">
                            <div className="column is-12">
                                <div className="field">
                                    <p className="control is-expanded">
                                        <input
                                            className="input"
                                            type="text"
                                            id="setEmail"
                                            placeholder={t('FORGET_PASSWORD_PAGE.EMAIL')}
                                            value={emailInput}
                                            style={{
                                                borderRadius: '10px',
                                                borderColor: errors.email ? 'red' : '',
                                                background: errors.email ? '' : '',
                                            }}
                                            {...register('email', {
                                                required:
                                                    t('ERROR_MSG_FORM.ERROR_FORM_PREFIX_MSG') +
                                                    t('LOGIN_PAGE.USER_ID') +
                                                    t('ERROR_MSG_FORM.ERROR_FORM_SUFFIX_MSG'),
                                                onChange: e => {
                                                    handleEmailChange(e);
                                                },
                                                pattern: {
                                                    value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                                                    message: t('ERROR_MSG_FORM.ERROR_EMAIL_MSG'),
                                                },
                                            })}
                                        />
                                        {errors?.email ? <p className="content-error">{errors.email.message}</p> : <p className="content-error">&nbsp;</p>}
                                    </p>
                                </div>
                            </div>
                        </div>
                    ) : (
                        ''
                    )}
                    {stateResetPassword === 2 ? (
                        <div>
                            <div className="columns">
                                <div className="column">
                                    <Stack justifyContent={'center'} direction={'row'}>
                                        <HStack className="gap-3">
                                            <PinInput placeholder="" onChange={handleOtpChange} otp={true} mask={false}>
                                                <PinInputField className="pin-input-custom" />
                                                <PinInputField className="pin-input-custom" />
                                                <PinInputField className="pin-input-custom" />
                                                <PinInputField className="pin-input-custom" />
                                                <PinInputField className="pin-input-custom" />
                                                <PinInputField className="pin-input-custom" />
                                            </PinInput>
                                        </HStack>
                                    </Stack>
                                </div>
                            </div>
                            <div className="columns">
                                <div className="column">
                                    <p className="content-center">
                                        <span>{t('FORGET_PASSWORD_PAGE.OTP_FOOTER')}</span>
                                        <span>
                                            {/* eslint-disable jsx-a11y/anchor-is-valid  */}

                                            <a className="a-clickable" onClick={handleResendOTP}>
                                                {t('BUTTONS.RESEND')}
                                            </a>
                                        </span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    ) : (
                        ''
                    )}
                </DialogContent>
                <DialogActions>
                    <div className="column">
                        <div className="buttons content-center">
                            <button className="button is-rounded is-danger App" type="button" onClick={handleCancel}>
                                {t('BUTTONS.CANCEL')}
                            </button>
                            <button className="button is-rounded is-link App gap-2" type="submit" disabled={isDisableBtn || isLoading}>
                                {isLoading ? <CircularProgress sx={{ color: '#fff' }} size={15} /> : ''}
                                {t('BUTTONS.NEXT')}
                            </button>
                        </div>
                    </div>
                </DialogActions>
            </form>
        </Dialog>
    );
}

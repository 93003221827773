import React, { useEffect, useState, MouseEvent, ChangeEvent, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { useTranslation } from 'react-i18next';
import PolicyRefunds from '../Setting/policyRefunds';
import './payment.css';
import '../../App.css';
import ChillPay from './Chilpay';
import { EmissionsSource } from '@src/types';
import { useGlobalState } from '@src/context/GlobalStateContext';
import { formatNumber } from '@src/utils/format-number';
import { useProjectsQuery } from '@src/services/queries/useProjectQuery';
import { Profile } from '@src/utils/state-profile';
const merchant_id = process.env.REACT_APP_MERCHANT_ID;
const paymentUrl = process.env.REACT_APP_PAYMENT_URL;

export default function Payment({ eventId }: { eventId: number }) {
    const navigate = useNavigate();
    const projectsQuery = useProjectsQuery(true);

    const profile = Profile();
    const { t, i18n } = useTranslation();
    const { gasEmissions, projectSelect } = useGlobalState();

    const [openConfirmPaid, setOpenConfirmPaid] = useState<boolean>(false);
    const [openRefundPolicy, setOpenRefundPolicy] = useState<boolean>(false);
    const [isDisableConfirm, setIsDisableConfirm] = useState<boolean>(true);
    const [projectName, setProjectName] = useState<string>('');

    useEffect(() => {
        if (projectsQuery?.data && projectSelect) {
            const { data } = projectsQuery?.data;
            if (data.data.length > 0) {
                const resultFilter = data.data.find((d: any) => d.id === parseInt(projectSelect.id));
                setProjectName(i18n.language === 'th' ? resultFilter.nameTH : resultFilter.nameEN);
            }
        }
    }, [projectsQuery, projectSelect, i18n.language]);

    function handleClickPaid(e: MouseEvent<HTMLElement>) {}
    function handleClickUnPaid(e: MouseEvent<HTMLElement>) {
        navigate('/');
    }

    function handleCloseConfirmPaid() {
        setOpenConfirmPaid(false);
    }

    function handleCloseRefundPolicy() {
        setOpenRefundPolicy(false);
    }

    function handleOpenRefundPolicy() {
        setOpenRefundPolicy(true);
    }

    function handleClickAccpetConsent(e: ChangeEvent<HTMLInputElement>) {
        let checkedStatus = e.target.checked;
        setIsDisableConfirm(!checkedStatus);
    }

    const { totalCarbonFootprint } = useMemo(() => {
        return (gasEmissions || []).reduce(
            (totals: any, source: EmissionsSource) => {
                totals.totalCarbonFootprint += source.percentage;
                return totals;
            },
            { totalCarbonFootprint: 0 },
        );
    }, [gasEmissions]);

    const projectPrice = projectSelect?.price || 0;
    const totalPricePaid = Math.ceil(totalCarbonFootprint) * projectPrice;
    return (
        <div className="column is-8 is-offset-2 mt-28">
            <div className="box" style={{ marginBlockStart: '10px', borderRadius: '90px' }}>
                <div className="columns is-mobile">
                    <div className="column is-10 is-offset-1" style={{ marginBlockStart: '20px' }}>
                        <Box
                            display="block"
                            alignItems="center"
                            sx={{
                                border: '3px solid grey',
                                borderColor: '#C8F026',
                                borderRadius: '20px',
                            }}
                        >
                            <div className="column">
                                <h1 className="content-sub-header">{t('PAID_PAGE.TITLE')}</h1>
                                <h1 className="content-header" style={{ fontSize: '42px', fontWeight: '700', color: 'black', marginTop: '-3px' }}>
                                    {Math.ceil(totalCarbonFootprint)}
                                    {/* {formatNumber(totalCarbonFootprint, 2)} */}
                                </h1>
                                <h1 className="content-header-s" style={{ color: '#26B3F0' }}>
                                    {projectName}
                                </h1>
                            </div>
                        </Box>
                        <div className="overflow-hidden">
                            {merchant_id && paymentUrl && totalPricePaid > 0 && (
                                <ChillPay
                                    customerID={profile.mapId}
                                    totalPaid={totalPricePaid}
                                    paymentURL={paymentUrl}
                                    merchantID={merchant_id as string}
                                    eventId={eventId}
                                    mobileNo={profile.mobile ?? '0880000000'}
                                />
                            )}
                        </div>
                        <br />
                        <div className="columns">
                            <div className="column is-4">
                                <h1 className="content-sub-header content-left" style={{ fontSize: '20px' }}>
                                    {t('PAID_PAGE.AMOUNT_TOTAL')}
                                </h1>
                            </div>
                            <div className="column is-offset-5" style={{ marginTop: '-40px' }}>
                                <h1 className="content-header content-right" style={{ fontWeight: '700', color: 'black', marginLeft: '24px' }}>
                                    {'฿' + formatNumber(totalPricePaid)}
                                </h1>
                                <h1 className="content-sub-header content-right">{`฿ ${formatNumber(projectPrice)}/tCO2eq`}</h1>
                            </div>
                        </div>
                        <div className="columns">
                            <div className="column is-6 is-offset-6" style={{ alignItems: 'right' }}>
                                <button
                                    className="button is-large is-fullwidth is-light App"
                                    style={{ fontSize: '18px', height: '70px' }}
                                    onClick={handleClickUnPaid}
                                >
                                    {t('PAID_PAGE.BUTTONS.PENDING')}
                                </button>
                            </div>
                        </div>
                        <br />
                    </div>
                </div>
                <Dialog
                    open={openConfirmPaid}
                    onClose={handleCloseConfirmPaid}
                    fullWidth
                    maxWidth="xs"
                    aria-labelledby="scroll-dialog-title"
                    aria-describedby="scroll-dialog-description"
                    PaperProps={{
                        style: {
                            borderRadius: '15px', // Adjust the value as needed
                        },
                    }}
                >
                    <DialogContent dividers={false}>
                        <div className="columns">
                            <div className="column is-10 is-offset-1 ">
                                <h3 className="content-sub-header font-bold content-center">{t('PAID_PAGE.CONFIRM_DIAG.TITLE')}</h3>
                                <br />
                                <p className="content-left font-payment">{t('PAID_PAGE.CONFIRM_DIAG.CONTENT')}</p>
                            </div>
                        </div>
                        <div className="columns">
                            <div className="column is-9 is-offset-2">
                                <label className="checkbox">
                                    <input className="checkbox-consent" type="checkbox" onChange={handleClickAccpetConsent} />
                                    <p className="content-left font-payment-sm">
                                        {t('PAID_PAGE.CONFIRM_DIAG.CONSENT')}
                                        {/* eslint-disable jsx-a11y/anchor-is-valid  */}
                                        <a style={{ color: '#0485db' }} onClick={handleOpenRefundPolicy}>
                                            {t('PAID_PAGE.CONFIRM_DIAG.BUTTON')}
                                        </a>
                                    </p>
                                </label>
                            </div>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <div className="column">
                            <div className="buttons" style={{ justifyContent: 'center' }}>
                                <button className="button is-info is-fullwidth App" type="button" onClick={handleClickPaid} disabled={isDisableConfirm}>
                                    {t('BUTTONS.COMFIRM')}
                                </button>
                                <button className="button is-light is-fullwidth App" type="submit" onClick={handleCloseConfirmPaid}>
                                    {t('BUTTONS.CANCEL')}
                                </button>
                            </div>
                        </div>
                    </DialogActions>
                </Dialog>
                <PolicyRefunds open={openRefundPolicy} handleClose={handleCloseRefundPolicy} />
            </div>
        </div>
    );
}

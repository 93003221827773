export const formatNumber = (number: number, decimals: number = 0) => {
    if (number === undefined) return 0;
    if (isNaN(number)) return 0;
    const formatter = new Intl.NumberFormat('en-US', {
        minimumFractionDigits: decimals,
        maximumFractionDigits: decimals,
    });
    return formatter.format(number);
};

export function formatTwoDecimalsWithoutRounding(number: number) {
    // const result = Math.floor(number * 100) / 100;

    return number.toFixed(3);
}

import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { handleError } from '../queries';
import { UserFormInput } from '@src/validators/userFormSchema';
import api from '../axios';

export const useUsers = (authType: string, page: number, limit: number) => {
    return useQuery({
        queryKey: ['users', authType, page, limit],
        queryFn: async ({ queryKey }: { queryKey: [string, string, number, number] }) => {
            const [, authType, page, limit] = queryKey;
            try {
                const { data } = await api.get(`/user?authType=${authType}&page=${page}&limit=${limit}`);
                return data;
            } catch (error) {
                throw error;
            }
        },
    });
};

export const useCreateUser = () => {
    const queryClient = useQueryClient();
    const handleSuccess = async () => {
        await queryClient.invalidateQueries({ queryKey: ['users'] });
    };

    return useMutation({
        mutationFn: async (user: UserFormInput) => {
            try {
                const { data } = await api.post('/user', user);
                return data;
            } catch (error) {
                throw error;
            }
        },
        onSuccess: handleSuccess,
        onError: handleError,
    });
};

export const useUpdateUser = () => {
    const queryClient = useQueryClient();
    const handleSuccess = async (data: any) => {
        await queryClient.invalidateQueries({ queryKey: ['users'] });
        await queryClient.invalidateQueries({ queryKey: ['userId', data.id] });
    };

    return useMutation({
        mutationFn: async (user: UserFormInput) => {
            try {
                const { data } = await api.patch(`/user/${user.id}`, user);
                return data;
            } catch (error) {
                throw error;
            }
        },
        onSuccess: handleSuccess,
        onError: handleError,
    });
};

export const useAdminUpdateUser = () => {
    const queryClient = useQueryClient();
    const handleSuccess = async (data: any) => {
        await queryClient.invalidateQueries({ queryKey: ['users'] });
        await queryClient.invalidateQueries({ queryKey: ['userId', data.id] });
    };

    return useMutation({
        mutationFn: async ({ userId, user }: { userId: number | undefined; user: UserFormInput }) => {
            try {
                const { data } = await api.patch(`/admin/${userId}`, user);
                return data;
            } catch (error) {
                throw error;
            }
        },
        onSuccess: handleSuccess,
        onError: handleError,
    });
};

export const useDeleteUser = () => {
    const queryClient = useQueryClient();
    const handleSuccess = async () => {
        await queryClient.invalidateQueries({ queryKey: ['users'] });
    };
    return useMutation({
        mutationFn: async (id: number | undefined) => {
            try {
                const { data } = await api.delete(`/user/${id}`);
                return data;
            } catch (error) {
                throw error;
            }
        },
        onSuccess: handleSuccess,
        onError: handleError,
    });
};

export const useUserById = (id: string) => {
    return useQuery({
        queryKey: ['userId', id],
        queryFn: async ({ queryKey }: { queryKey: [string, string] }) => {
            const [, id] = queryKey;
            try {
                const { data } = await api.get(`/user/${id}`);
                return data;
            } catch (error) {
                throw error;
            }
        },
    });
};

export const useUserResetPassword = () => {
    return useMutation({
        mutationFn: async (user: { email: string; password: string; confirmPassword: string }) => {
            try {
                const { data } = await api.post(`/user/reset-password`, user);
                return data;
            } catch (error) {
                throw error;
            }
        },
        onError: handleError,
    });
};

export const useUpdateLocationUser = () => {
    return useMutation({
        mutationFn: async ({ userId, user }: { userId: number; user: any }) => {
            try {
                const { data, status } = await api.patch(`/user/${userId}`, user);
                return { data, status };
            } catch (error) {
                throw error;
            }
        },
    });
};

export const useAdminCreate = () => {
    const queryClient = useQueryClient();
    const handleSuccess = async (data: any) => {
        await queryClient.invalidateQueries({ queryKey: ['users'] });
        await queryClient.invalidateQueries({ queryKey: ['userId', data.id] });
    };

    return useMutation({
        mutationFn: async (user: UserFormInput) => {
            try {
                const { data } = await api.post(`/admin`, user);
                return data;
            } catch (error) {
                throw error;
            }
        },
        onSuccess: handleSuccess,
        onError: handleError,
    });
};

export const useForgotPassword = () => {
    return useMutation({
        mutationFn: async (dataForgot: { email: string | undefined }) => {
            try {
                const { data, status } = await api.post('/user/forgot-password', dataForgot);
                return { status, data };
            } catch (error) {
                return Promise.reject(error);
            }
        },
    });
};

export const useValidateOtp = () => {
    return useMutation({
        mutationFn: async (dataForgot: { otp: string; email: string | undefined }) => {
            try {
                const { data, status } = await api.post('/user/validate-otp', dataForgot);
                return { status, data };
            } catch (error) {
                return Promise.reject(error);
            }
        },
    });
};

export const useValidateToken = () => {
    return useMutation({
        mutationFn: async (dataToken: { token: string }) => {
            try {
                const { data, status } = await api.post('/user/validate-token', dataToken);
                return { status, data };
            } catch (error) {
                return Promise.reject(error);
            }
        },
    });
};

export const useResetForgotPassword = () => {
    return useMutation({
        mutationFn: async (dataReset: { token: string; password: string }) => {
            try {
                const { data, status } = await api.post(`/user/reset-forgot?token=${dataReset.token}`, dataReset);
                return { status, data };
            } catch (error) {
                return Promise.reject(error);
            }
        },
    });
};

export const useValidateResetPassword = () => {
    return useMutation({
        mutationFn: async (dataForgot: { token: string; email: string | undefined }) => {
            try {
                const { data, status } = await api.post('/user/validate-reset', dataForgot);
                return { status, data };
            } catch (error) {
                return Promise.reject(error);
            }
        },
    });
};

export const useRoles = () => {
    return useQuery({
        queryKey: ['roles'],
        queryFn: async () => {
            try {
                const { data } = await api.get(`/user/list-roles`);
                return data;
            } catch (error) {
                throw error;
            }
        },
    });
};

import { Button, Dialog, DialogActions, DialogTitle, Paper, Table, TableBody, TableContainer, TableHead, TableRow } from '@mui/material';
import { green, red, yellow } from '@mui/material/colors';
import Iconify from '@src/components/layout/admin/components/iconify';
import { StyledTableCell, StyledTableRow } from '@src/pages/Dashboard';
import { EventEmission } from '@src/types';
import { formatDateEn } from '@src/utils/format-date-th';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import CircleIcon from '@mui/icons-material/Circle';
import { useGlobalState } from '@src/context/GlobalStateContext';
import { MiceForm } from '@src/types/mice-form.model';
import Swal from 'sweetalert2';
import { getPreEventGuest, removePreEventByIdGuest } from '@src/utils/state-guest';

const TablePre = () => {
    const navigate = useNavigate();
    const { setFormMice, setGasEmissions } = useGlobalState();

    const { t } = useTranslation();
    const [eventId, setEventId] = useState<number>();

    const [events, setEvents] = useState<EventEmission[]>([]);

    const loadStateGuest = () => {
        const data = getPreEventGuest();
        if (data) {
            setEvents(data);
        }
    };
    useEffect(() => {
        loadStateGuest();
    }, []);

    const [open, setOpen] = useState(false);

    const handleClose = () => {
        setOpen(false);
    };

    const handleRemoveEvent = (id: number) => {
        Swal.fire({
            title: t('DELETE_ACTIVITY'),
            text: t('DELETE_ACTIVITY_TEXT'),
            iconHtml: `<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 16 16"><path fill="black" d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5M8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5m3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0"/></svg>`,
            customClass: {
                icon: 'no-border',
            },
            showCancelButton: true,
            confirmButtonColor: green[500],
            cancelButtonColor: red[500],
            confirmButtonText: t('BUTTONS.COMFIRM'),
            cancelButtonText: t('BUTTONS.CANCEL'),
        }).then(result => {
            if (result.isConfirmed) {
                onRemoveById(id);
            }
        });
    };
    const onRemoveById = (id: number) => {
        removePreEventByIdGuest(id);
        loadStateGuest();
    };
    const handleClickEditEvent = () => {
        const preData = getPreEventGuest();
        if (preData && preData.length) {
            const resultFindPre = preData.find((p: any) => p.id === eventId);
            if (resultFindPre) {
                setFormMice(new MiceForm(resultFindPre));
                setGasEmissions(resultFindPre.emission?.emissions_sources);
                sessionStorage.setItem('tmpEventId', JSON.stringify(resultFindPre?.id));
                sessionStorage.setItem(
                    'tmpCreateData',
                    JSON.stringify({
                        status: 'edit',
                        paging: 1,
                    }),
                );
                navigate(`/guest-register/edit/1`);
            }
        }
    };

    const handleClickEvent = (id: number) => {
        setEventId(id as number);
        setOpen(true);
    };
    return (
        <>
            <div className="columns is-mobile">
                <div className="column is-3 is-offset-1">
                    <h1 className="content-header content-left">{t('DASHBOARD_PAGE.PROJECT_TABLE.HEADER')}</h1>
                    <h3 className="content-sub-header content-left">{t('DASHBOARD_PAGE.PROJECT_TABLE.SUBHEADER')}</h3>
                </div>
            </div>
            <div className="columns is-mobile">
                <div className="column is-10 is-offset-1 box">
                    <div className="tabs ">
                        <ul>
                            <li className={'all' ? 'is-active ' : ''}>
                                {/* eslint-disable jsx-a11y/anchor-is-valid  */}
                                <a className="cursor-pointer">
                                    <span>{t('DASHBOARD_PAGE.TABS.TAB1')}</span>
                                </a>
                            </li>
                            {/* <li className={activeTabId === 'PRE_EVENT' ? 'is-active' : ''}>
                            <a className="cursor-pointer">
                                <span>{t('DASHBOARD_PAGE.TABS.TAB2')}</span>
                            </a>
                        </li>
                        <li className={activeTabId === 'POST_EVENT' ? 'is-active' : ''}>
                            <a className="cursor-pointer">
                                <span>{t('DASHBOARD_PAGE.TABS.TAB3')}</span>
                            </a>
                        </li>
                        <li className={activeTabId === 'COMPENSATED' ? 'is-active' : ''}>
                            <a className="cursor-pointer">
                                <span>{t('DASHBOARD_PAGE.TABS.TAB4')}</span>
                            </a>
                        </li> */}
                        </ul>
                    </div>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 700 }} aria-label="customized table">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell className="content-left" sx={{ width: '5%' }}>
                                        #
                                    </StyledTableCell>
                                    <StyledTableCell className="content-left" sx={{ width: '40%' }}>
                                        {t('DASHBOARD_PAGE.PROJECT_TABLE.PROJECT_NAME')}
                                    </StyledTableCell>
                                    <StyledTableCell className="content" sx={{ width: '20%' }}>
                                        {t('DASHBOARD_PAGE.PROJECT_TABLE.PROJECT_DATE')}
                                    </StyledTableCell>

                                    <StyledTableCell align="right" className="content-center pl-5" sx={{ width: '20%' }}>
                                        {t('DASHBOARD_PAGE.PROJECT_TABLE.PROJECT_STATUS')}
                                    </StyledTableCell>
                                    <StyledTableCell align="left" className="content-center" sx={{ width: '10%' }}>
                                        {t('DASHBOARD_PAGE.PROJECT_TABLE.PROJECT_ACTION')}
                                    </StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {events.map((row: EventEmission, index) => (
                                    <StyledTableRow key={row.id}>
                                        <StyledTableCell align="left">{index + 1}</StyledTableCell>
                                        <StyledTableCell component="th" scope="row" className="content-left">
                                            <div>
                                                <p className="content-left">
                                                    <b>{row.name}</b>
                                                </p>
                                                <p className="content-left">{row?.location}</p>
                                            </div>
                                        </StyledTableCell>
                                        <StyledTableCell align="left" className="content">
                                            {formatDateEn(row.createdAt)}
                                        </StyledTableCell>
                                        <StyledTableCell align="left" className="content">
                                            <div className="flex justify-center max-w-60 items-center">
                                                <Button
                                                    onClick={() => handleClickEvent(row.id as number)}
                                                    className="!font-Kanit"
                                                    sx={{
                                                        color: 'black',
                                                        border: '1px solid #ffb700',
                                                        backgroundColor: '#ffb700',
                                                        borderRadius: '16px',
                                                        ':hover': {
                                                            backgroundColor: '#e6a500',
                                                            border: '1px solid #e6a500',
                                                        },
                                                    }}
                                                    variant="outlined"
                                                >
                                                    {t('DASHBOARD_PAGE.PROJECT_ACTION.PRE')}
                                                </Button>
                                                <CircleIcon
                                                    className="blink"
                                                    sx={{
                                                        color: row.isEvent === 'PRE_EVENT' ? yellow[700] : green[400],
                                                        marginLeft: '10px',
                                                    }}
                                                />
                                            </div>
                                        </StyledTableCell>
                                        <StyledTableCell align="left">
                                            <div className="flex justify-center">
                                                <button className=" is-small" onClick={() => handleRemoveEvent(row.id as number)} type="button">
                                                    <Iconify icon="eva:trash-2-outline" sx={{ color: 'red' }} />
                                                </button>
                                            </div>
                                        </StyledTableCell>
                                    </StyledTableRow>
                                ))}

                                {events.length === 0 && (
                                    <StyledTableRow>
                                        <StyledTableCell colSpan={5}>
                                            <div className="font-Kanit">ไม่พบกิจกรรม</div>
                                        </StyledTableCell>
                                    </StyledTableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>

                <Dialog
                    open={open}
                    fullWidth
                    maxWidth="xs"
                    aria-labelledby="scroll-dialog-title"
                    aria-describedby="scroll-dialog-description"
                    PaperProps={{
                        style: {
                            overflowY: 'unset',
                            borderRadius: '15px', // Adjust the value as needed
                        },
                    }}
                >
                    <div onClick={handleClose} className="z-20 cursor-pointer  absolute  -top-2 -right-1 border-1 rounded-full p-[0.5px] bg-red-50 ">
                        <Iconify icon="carbon:close-filled" sx={{ color: 'red' }} width={30} />
                    </div>
                    <DialogTitle className="relative">
                        <div className="column is-12">
                            <h4 className="content-center">{t('DASHBOARD_PAGE.POST_EVENT_DIALOG.CONTENT')}</h4>
                        </div>
                    </DialogTitle>
                    <DialogActions>
                        <div className="column">
                            <div className="buttons" style={{ justifyContent: 'center' }}>
                                <button className="button App bg-[#FEC236]" type="button" onClick={handleClickEditEvent}>
                                    {t('DASHBOARD_PAGE.POST_EVENT_DIALOG.PRE_EVENT')}
                                </button>
                            </div>
                        </div>
                    </DialogActions>
                </Dialog>
            </div>
        </>
    );
};

export default TablePre;

import CloseIcon from '@mui/icons-material/Close';
import { Dialog, DialogTitle, DialogContent, TextField, DialogActions, Button, Box, Grid, IconButton } from '@mui/material';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';
import { zodResolver } from '@hookform/resolvers/zod';

import React, { FC, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import Swal from 'sweetalert2';
import { SubLocationFormSchema, SubLocationFormInput } from '@src/validators/subLocationFormSchema';
import { Save } from 'lucide-react';
import { useAddSubLocationQuery, useSubLocationQuery, useUpdateSubLocationQuery } from '@src/services/queries/useLocationQuery';
import { ILocation, ISubLocation } from '@src/types';
import { useQueryClient } from '@tanstack/react-query';
import { useGlobalState } from '@src/context/GlobalStateContext';

interface FormSubLocationDialogProps {
    open: boolean;
    setOpenForm: React.Dispatch<React.SetStateAction<boolean>>;
    handleClose?: () => void;
    defaultValue?: ISubLocation | undefined;
}

export const FormSubLocationDialog: FC<FormSubLocationDialogProps> = ({ open, setOpenForm, handleClose, defaultValue }) => {
    const { mutateAsync: AddSubLocationQuery } = useAddSubLocationQuery();
    const { mutateAsync: UpdateSubLocationQuery } = useUpdateSubLocationQuery();
    const { subLocation, setSubLocation } = useGlobalState();
    const queryClient = useQueryClient();

    const { mutateAsync: getSubLocation } = useSubLocationQuery();

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
        setValue,
    } = useForm<SubLocationFormInput>({
        resolver: zodResolver(SubLocationFormSchema),
    });

    const loadSubLocations = async () => {
        const response = await getSubLocation({ locationId: subLocation?.id as number });
        if (response) {
            let newSubLocation = { ...subLocation };
            newSubLocation.subLocation = response;
            setSubLocation(undefined);
            setSubLocation(newSubLocation as ILocation);
        }
    };

    const handleCreateLocation = async (location: SubLocationFormInput) => {
        try {
            await AddSubLocationQuery({
                locationId: subLocation?.id as number,
                name: location.name,
                area: parseInt(location.area as string),
                electricConsumption: parseInt(location.electricConsumption as string),
            });
            setOpenForm(false);
            Swal.fire({
                title: 'สร้างสําเร็จ',
                text: 'เพิ่มข้อมูลสําเร็จ',
                icon: 'success',
                timer: 1000,
                timerProgressBar: true,
                showConfirmButton: false,
                customClass: {
                    popup: 'popup-backend',
                },
            }).then(({ isDismissed }) => {
                if (isDismissed) {
                    // reset();
                    loadSubLocations();
                    queryClient.invalidateQueries({ queryKey: ['locations'] });
                }
            });
        } catch (error) {
            console.error(error);
        }
    };

    const handleUpdateLocation = async (location: SubLocationFormInput) => {
        try {
            await UpdateSubLocationQuery({
                subLocationId: defaultValue?.id as number,
                name: location.name,
                area: parseInt(location.area as string),
                electricConsumption: parseInt(location.electricConsumption as string),
            });
            Swal.fire({
                title: 'แก้ไขข้อมูลสําเร็จ',
                icon: 'success',
                timer: 2000,
                timerProgressBar: true,
                showConfirmButton: false,
                customClass: {
                    popup: 'popup-backend',
                },
            }).then(({ isDismissed }) => {
                if (isDismissed) {
                    reset();
                    setOpenForm(false);
                    loadSubLocations();
                    queryClient.invalidateQueries({ queryKey: ['locations'] });
                }
            });
        } catch (error) {
            console.error(error);
        }
    };
    const onSubmit = (data: SubLocationFormInput) => {
        if (defaultValue) {
            handleUpdateLocation(data);
        } else {
            handleCreateLocation(data);
        }
    };

    useEffect(() => {
        if (defaultValue) {
            /* eslint-disable @typescript-eslint/no-unused-vars */
            setValue('name', defaultValue?.name ?? '');
            setValue('area', defaultValue?.area.toString() ?? '0');
            setValue('electricConsumption', defaultValue?.electricConsumption.toString() ?? '0');
        } else {
            reset();
        }
    }, [defaultValue, setValue, reset]);

    useEffect(() => {
        if (!open) {
            reset();
        }
    }, [open, reset]);

    return (
        <Dialog fullWidth maxWidth={'md'} open={open}>
            <DialogTitle sx={{ m: 0, p: 2 }}>{defaultValue ? `แก้ไขข้อมูลสถานที่ย่อย` : 'สร้างข้อมูลสถานที่ย่อย'}</DialogTitle>
            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                }}
            >
                <CloseIcon />
            </IconButton>
            <form onSubmit={handleSubmit(onSubmit)}>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12}>
                            <label>
                                ชื่อ <span className="text-red-600">*</span>
                            </label>
                            <TextField
                                {...register('name')}
                                error={!!errors.name}
                                helperText={errors.name?.message}
                                fullWidth
                                size="small"
                                InputProps={{
                                    sx: { borderRadius: 1.6, backgroundColor: 'white' },
                                }}
                            />
                        </Grid>

                        <Grid item xs={6} sm={6}>
                            <label>
                                ขนาดพื้นที่ <span className="text-red-600">*</span>
                            </label>
                            <TextField
                                {...register('area')}
                                error={!!errors.area}
                                helperText={errors.area?.message}
                                fullWidth
                                size="small"
                                InputProps={{
                                    sx: { borderRadius: 1.6, backgroundColor: 'white' },
                                }}
                            />
                        </Grid>

                        <Grid item xs={6} sm={6}>
                            <label>การใช้ไฟฟ้า (กิโลวัตต์ชั่วโมง)</label>
                            <TextField
                                {...register('electricConsumption')}
                                error={!!errors.electricConsumption}
                                helperText={errors.electricConsumption?.message}
                                fullWidth
                                size="small"
                                InputProps={{
                                    sx: { borderRadius: 1.6, backgroundColor: 'white' },
                                }}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions sx={{ p: 2 }}>
                    <Button onClick={handleClose} color="inherit" sx={{ borderRadius: '8px', alignItems: 'center', gap: 0.5 }} variant="contained">
                        <CloseTwoToneIcon />
                        <Box pt={0.4}> ปิดหน้าต่าง</Box>
                    </Button>
                    <Button
                        type="submit"
                        variant="contained"
                        color={defaultValue ? 'secondary' : 'primary'}
                        sx={{ borderRadius: '8px', alignItems: 'center', gap: 0.5 }}
                    >
                        {defaultValue ? (
                            <>
                                <Save />
                                <Box pt={0.4}> ยันยันแก้ไข</Box>
                            </>
                        ) : (
                            <>
                                <Save size={20} strokeWidth={1.5} absoluteStrokeWidth />

                                <Box pt={0.4}> ยันยัน</Box>
                            </>
                        )}
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};

import React, { useState, useEffect } from 'react';
import { emptyRows, applyFilter, getComparator } from '@src/components/layout/admin/components/TableZeroCarbon/utils';
import Scrollbar from '@src/components/scrollbar';
import { Card, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TablePagination } from '@mui/material';
import TableHeadZero from '@src/components/layout/admin/components/TableZeroCarbon/TableHead';
import TableEmptyRows from '@src/components/layout/admin/components/TableZeroCarbon/TableEmptyRows';
import TableToolBar from '@src/components/layout/admin/components/TableZeroCarbon/TableToolBar';

import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';

import TableRowZeroCarbon from '@src/components/layout/admin/components/TableZeroCarbon/TableRow';
import { formatDate } from '@src/utils/format-date-th';
import { useProjects, useDeleteProject } from '@src/services/queries';
import TableNoData from '@src/components/layout/admin/components/TableZeroCarbon/TableNoData';
import TableLoading from '@src/components/layout/admin/components/TableZeroCarbon/TableLoading';
import { formatNumber } from '@src/utils/format-number';
import { ProjectCreate } from '@src/types';
import Swal from 'sweetalert2';
import { useQueryClient } from '@tanstack/react-query';
import { FormProjects } from '../form-projects';
import { ButtonAdd } from '@src/components/layout/admin/components/Element/ButtonAdd';
import { Profile } from '@src/utils/state-profile';
import { config } from '@src/config';

const ProjectTable = () => {
    const queryClient = useQueryClient();

    const [openForm, setOpenForm] = useState(false);
    const [projectForUpdate, setProjectForUpdate] = useState<ProjectCreate | undefined>(undefined);
    const [page, setPage] = useState(0);

    const [order, setOrder] = useState<'asc' | 'desc'>('asc');
    const [orderBy, setOrderBy] = useState<string>('createdAt');
    const [filterName, setFilterName] = useState('');
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const deleteProjectMutation = useDeleteProject();
    const profile = Profile();
    console.log(profile);
    const { data, isLoading } = useProjects(page + 1, rowsPerPage, profile.role === 'PROJECT_OWNER' ? false : true);
    const handleSort = (event: any, id: any) => {
        const isAsc = orderBy === id && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(id);
    };

    const columns = [
        { id: '', label: 'NO.', align: 'center' },
        { id: 'nameTH', label: 'ชื่อ(TH)' },
        { id: 'nameEN', label: 'ชื่อ(EN)' },
        { id: 'quantity', label: 'จำนวนปริมาณคาร์บอน' },
        { id: 'price', label: 'ราคา' },
        { id: 'createdAt', label: 'วันที่สร้าง' },
        { id: '', label: 'เครื่องมือ' },
    ];

    const handleChangePage = (event: unknown, newPage: number) => setPage(newPage);

    const handleChangeRowsPerPage = (event: any) => {
        setPage(0);
        setRowsPerPage(parseInt(event.target.value, 10));
    };

    const handleFilter = (event: any) => {
        setPage(0);
        setFilterName(event);
    };

    const projects = data?.data || [];

    useEffect(() => {
        if (data?.data.length === 0 && data?.page > 1) {
            setPage(0);
            queryClient.invalidateQueries({ queryKey: ['projects'] });
        }
    }, [data, setPage, queryClient]);

    const filteredProjects = applyFilter({
        inputData: projects,
        comparator: getComparator(order, orderBy),
        filterName,
        keyField: ['price', 'nameTH', 'nameEN'],
    });

    const notFound = !filteredProjects.length && !!filterName;

    const onClickAdd = () => {
        setProjectForUpdate(undefined);
        setOpenForm(true);
    };

    const onEditProject = (projectData: ProjectCreate) => {
        setProjectForUpdate(projectData);
        setOpenForm(true);
    };

    const TableRowBody = ({ index, rows }: any) => {
        const { id, nameTH, nameEN, price, createdAt, quantity } = rows;
        const onDeleteProject = (id: number) => {
            Swal.fire({
                title: 'ต้องการลบโครงการนี้?',
                text: 'คุณต้องการลบโครงการนี้หรือไม่?',
                icon: 'error',
                showCancelButton: true,
                confirmButtonColor: '#1AA238',
                cancelButtonColor: '#d33',
                cancelButtonText: 'ยกเลิก',
                confirmButtonText: 'ยืนยัน',
                customClass: {
                    popup: 'popup-backend',
                },
            }).then((result: any) => {
                if (result.isConfirmed) {
                    deleteProjectMutation.mutateAsync(id);
                }
            });
        };
        return (
            <>
                <TableCell
                    sx={{
                        textAlign: 'center',
                    }}
                >
                    {index + 1}
                </TableCell>
                <TableCell scope="row" className="text-xs whitespace-nowrap text-left">
                    <div className="truncate max-w-72 flex flex-col" title={nameTH}>
                        {nameTH}
                    </div>
                </TableCell>
                <TableCell sx={{ textAlign: 'center' }} className="text-xs">
                    {nameEN}
                </TableCell>
                <TableCell className="text-xs">{formatNumber(quantity)}</TableCell>
                <TableCell className="text-xs">{formatNumber(price)}</TableCell>
                <TableCell className="text-xs">{formatDate(createdAt)}</TableCell>
                <TableCell align="right">
                    <IconButton aria-label="edit" size="small" onClick={() => onEditProject(rows)}>
                        <EditTwoToneIcon />
                    </IconButton>

                    <IconButton aria-label="delete" size="small" onClick={() => onDeleteProject(id)}>
                        <DeleteTwoToneIcon />
                    </IconButton>
                </TableCell>
            </>
        );
    };

    return (
        <>
            <FormProjects open={openForm} setOpenForm={setOpenForm} defaultValue={projectForUpdate} handleClose={() => setOpenForm(false)} />
            <TableToolBar onSearch={handleFilter} ButtonAdd={<ButtonAdd onClick={onClickAdd} text={'เพิ่มโครงการ'} />} />
            <Scrollbar>
                <Card>
                    <TableContainer component={Paper}>
                        <Table
                            sx={{
                                minWidth: {
                                    sm: 1700,
                                    md: 1600,
                                    lg: 1300,
                                },
                            }}
                            size={'small'}
                        >
                            <colgroup>
                                <col width="1%" />
                                <col width="25%" />
                                <col width="25%" />
                                <col width="10%" />
                                <col width="10%" />
                                <col width="7%" />
                                <col width="7%" />
                            </colgroup>
                            <TableHeadZero order={order} orderBy={orderBy} onRequestSort={handleSort} headLabel={columns} />

                            <TableBody sx={{ fontSize: '0.75rem' }}>
                                {isLoading && <TableLoading />}

                                {filteredProjects.map((row, index) => (
                                    <TableRowZeroCarbon key={index} rows={row} index={index} TableRowBody={<TableRowBody index={index} rows={row} />} />
                                ))}

                                <TableEmptyRows height={notFound ? 2 : 5} emptyRows={emptyRows(page, rowsPerPage, filteredProjects.length)} />

                                {notFound && <TableNoData query={filterName} />}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Card>
            </Scrollbar>

            <TablePagination
                count={data?.total || 0}
                onPageChange={handleChangePage}
                page={page}
                rowsPerPage={rowsPerPage}
                rowsPerPageOptions={config.rowsPerPageOptions}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </>
    );
};

export default ProjectTable;

import { useMemo } from 'react';
import { decodeJwt } from '@src/utils/decode-jwt';
//@ts-ignore
import Cookies from 'js-cookie';
import { config } from '@src/config';

/**
 * Custom hook to check if a JWT token stored in cookies is expired
 * @param {Object} config - Configuration object
 * @param {string} config.keyNameCookie - Cookie key name for the JWT token
 * @returns {boolean} - Returns true if token is expired or invalid, false otherwise
 */
const useTokenExpiration = () => {
    const isTokenExpired = useMemo(() => {
        const token = Cookies.get(config.keyNameCookie);

        if (!token) {
            return true;
        }

        try {
            const decodedToken = decodeJwt(token);
            const expirationTime = decodedToken.exp * 1000; // Convert to milliseconds

            if (!expirationTime) {
                return true;
            }

            return Date.now() > expirationTime;
        } catch (error) {
            console.error('Error decoding token:', error);
            return true;
        }
    }, []);

    return isTokenExpired;
};

export default useTokenExpiration;

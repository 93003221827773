import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import translationEN from './constants/locales/en/translation.json';
import translationTH from './constants/locales/th/translation.json';
import translationCN from './constants/locales/cn/translation.json';
import detector from 'i18next-browser-languagedetector';

// the translations
const resources = {
    en: {
        translation: translationEN,
    },
    th: {
        translation: translationTH,
    },
    cn: {
        translation: translationCN,
    },
};

const lang = localStorage.getItem('i18nextLng');

i18n.use(detector)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        lng: lang || 'th',
        fallbackLng: 'th', // use th if detected lng is not available

        keySeparator: '.', // we do not use keys in form messages.welcome

        interpolation: {
            escapeValue: false, // react already safes from xss
        },
    });

export default i18n;

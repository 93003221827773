import { IconButton } from '@mui/material';
import Iconify from '@src/components/layout/admin/components/iconify';
import React from 'react';

const IconBack = ({ onClick }: { onClick?: () => void }) => (
    <div className="text-left">
        <IconButton aria-label="fingerprint" title="back" onClick={onClick}>
            <Iconify icon="material-symbols:arrow-back-rounded" width={30} />
        </IconButton>
    </div>
);

export default IconBack;

import { Divider, Stack, Tooltip, IconButton, Table, TableHead, TableRow, TableCell, TableBody, Button } from '@mui/material';
import { ILocation, ISubLocation } from '@src/types';
import { CircleX, Diff, MapPinCheck, PenLine, Trash } from 'lucide-react';
import React from 'react';
import { FormSubLocationDialog } from './form-sub-location';

const SubLocations = ({
    dataSubLocation,
    closeSubLocation,
    onDelete,
}: {
    dataSubLocation: ILocation;
    closeSubLocation: () => void;
    onDelete: (arg: number) => void;
}) => {
    const [openForm, setOpenForm] = React.useState(false);
    const [updateSubLocation, setUpdateSubLocation] = React.useState<ISubLocation>();

    const handelAddSubLocation = () => {
        setOpenForm(true);
        setUpdateSubLocation(undefined);
    };

    const handelUpdateSubLocation = (valueSubLocation: ISubLocation) => {
        setOpenForm(true);
        setUpdateSubLocation(valueSubLocation);
    };

    return (
        <div className=" bg-white shadow-md rounded-md p-4 my-4 relative">
            <FormSubLocationDialog open={openForm} setOpenForm={setOpenForm} handleClose={() => setOpenForm(false)} defaultValue={updateSubLocation} />
            <div className="absolute -top-4 -right-3">
                <IconButton onClick={closeSubLocation}>
                    <CircleX size={30} strokeWidth={1.5} absoluteStrokeWidth />
                </IconButton>
            </div>
            <div className="flex flex-col">
                <div className="flex items-center gap-2 ">
                    <MapPinCheck size={40} strokeWidth={1.5} />

                    <div className="flex flex-col gap-1 w-96">
                        <div className="text-xl font-bold ">จัดการข้อมูลสถานที่ย่อย/ห้องประชุม</div>
                        <div className=" font-medium truncate ">สถานที่: {dataSubLocation?.name} </div>
                    </div>
                </div>

                <div className="flex justify-end py-2">
                    <Button
                        variant="contained"
                        sx={{
                            color: 'black',
                            backgroundColor: '#f1c40f',
                            '&:hover': {
                                backgroundColor: '#f39c12',
                            },
                        }}
                        onClick={handelAddSubLocation}
                    >
                        <Diff size={20} strokeWidth={1.5} />
                        เพิ่มสถานที่ย่อย/ห้องประชุม
                    </Button>
                </div>
            </div>
            <Stack divider={<Divider sx={{ borderStyle: 'dashed' }} />} sx={{ minWidth: 560 }}>
                <Table sx={{ minWidth: 650 }} aria-label="caption table" size="small">
                    <colgroup>
                        <col width="1%" />
                        <col width="40%" />
                        <col width="20%" />
                        <col width="15%" />
                    </colgroup>
                    <TableHead>
                        <TableRow>
                            <TableCell>#</TableCell>
                            <TableCell align="right">สถานที่ย่อย/ห้องประชุม</TableCell>
                            <TableCell align="right">ขนาดพื้นที่</TableCell>
                            <TableCell align="right">การใช้ไฟฟ้า</TableCell>
                            <TableCell align="right">จัดการ</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {dataSubLocation?.subLocation.map((row, index) => (
                            <TableRow key={index}>
                                <TableCell component="th" scope="row">
                                    {index + 1}
                                </TableCell>
                                <TableCell align="right">{row.name}</TableCell>
                                <TableCell align="right">{row.area}</TableCell>
                                <TableCell align="right">{row.electricConsumption}</TableCell>
                                <TableCell align="right">
                                    <Tooltip title="แก้ไขข้อมูลสถานที่" placement="top" color="green">
                                        <IconButton sx={{ alignSelf: 'flex-start' }} onClick={() => handelUpdateSubLocation(row)}>
                                            <PenLine size={18} strokeWidth={1.5} color="green" />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="ลบ" placement="top" color="green">
                                        <IconButton sx={{ alignSelf: 'flex-start' }} onClick={() => onDelete(row.id)}>
                                            <Trash size={18} strokeWidth={1.5} color="red" absoluteStrokeWidth />
                                        </IconButton>
                                    </Tooltip>
                                </TableCell>
                            </TableRow>
                        ))}

                        {dataSubLocation?.subLocation.length === 0 && (
                            <TableRow>
                                <TableCell colSpan={4} align="center">
                                    {'ไม่พบข้อมูล '}
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </Stack>
        </div>
    );
};

export default SubLocations;

import CloseIcon from '@mui/icons-material/Close';
import { Dialog, DialogTitle, DialogContent, TextField, DialogActions, Button, Box, Grid, IconButton } from '@mui/material';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';
import { zodResolver } from '@hookform/resolvers/zod';

import React, { FC, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import Swal from 'sweetalert2';
import { LocationFormSchema, LocationFormInput } from '@src/validators/locationFormSchema';
import { MapPinPlus } from 'lucide-react';
import { useAddLocationQuery, useUpdateLocationQuery } from '@src/services/queries/useLocationQuery';
import { ILocation } from '@src/types';
import { useQueryClient } from '@tanstack/react-query';

interface FormLocationDialogProps {
    open: boolean;
    setOpenForm: React.Dispatch<React.SetStateAction<boolean>>;
    handleClose?: () => void;
    defaultValue?: ILocation | undefined;
}

export const FormLocationDialog: FC<FormLocationDialogProps> = ({ open, setOpenForm, handleClose, defaultValue }) => {
    const { mutateAsync: AddLocationQuery } = useAddLocationQuery();
    const { mutateAsync: UpdateLocationQuery } = useUpdateLocationQuery();
    const queryClient = useQueryClient();

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
        setValue,
    } = useForm<LocationFormInput>({
        resolver: zodResolver(LocationFormSchema),
    });

    const handleCreateLocation = async (location: LocationFormInput) => {
        try {
            await AddLocationQuery(location);
            setOpenForm(false);
            Swal.fire({
                title: 'สร้างสําเร็จ',
                text: 'เพิ่มข้อมูลสําเร็จ',
                icon: 'success',
                timer: 1000,
                timerProgressBar: true,
                showConfirmButton: false,
                customClass: {
                    popup: 'popup-backend',
                },
            }).then(({ isDismissed }) => {
                if (isDismissed) {
                    reset();
                    queryClient.invalidateQueries({ queryKey: ['locations'] });
                }
            });
        } catch (error) {
            console.error(error);
        }
    };

    const handleUpdateLocation = async (location: LocationFormInput) => {
        try {
            await UpdateLocationQuery({
                locationId: defaultValue?.id as number,
                name: location.name,
            });
            Swal.fire({
                title: 'แก้ไขข้อมูลสําเร็จ',
                icon: 'success',
                timer: 2000,
                timerProgressBar: true,
                showConfirmButton: false,
                customClass: {
                    popup: 'popup-backend',
                },
            }).then(({ isDismissed }) => {
                if (isDismissed) {
                    queryClient.invalidateQueries({ queryKey: ['locations'] });
                    reset();
                    setOpenForm(false);
                }
            });
        } catch (error) {
            console.error(error);
        }
    };
    const onSubmit = (data: LocationFormInput) => {
        if (defaultValue) {
            handleUpdateLocation(data);
        } else {
            handleCreateLocation(data);
        }
    };

    useEffect(() => {
        if (defaultValue) {
            /* eslint-disable @typescript-eslint/no-unused-vars */
            setValue('name', defaultValue?.name ?? '');
        } else {
            reset();
        }
    }, [defaultValue, setValue, reset]);

    useEffect(() => {
        if (!open) {
            reset();
        }
    }, [open, reset]);

    return (
        <Dialog fullWidth maxWidth={'sm'} open={open}>
            <DialogTitle sx={{ m: 0, p: 2 }}>{defaultValue ? `แก้ไขข้อมูลสถานที่` : 'สร้างข้อมูลสถานที่'}</DialogTitle>
            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                }}
            >
                <CloseIcon />
            </IconButton>
            <form onSubmit={handleSubmit(onSubmit)}>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12}>
                            <label>
                                ชื่อ <span className="text-red-600">*</span>
                            </label>
                            <TextField
                                {...register('name')}
                                error={!!errors.name}
                                helperText={errors.name?.message}
                                fullWidth
                                size="small"
                                InputProps={{
                                    sx: { borderRadius: 1.6, backgroundColor: 'white' },
                                }}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions sx={{ p: 2 }}>
                    <Button onClick={handleClose} color="inherit" sx={{ borderRadius: '8px', alignItems: 'center', gap: 0.5 }} variant="contained">
                        <CloseTwoToneIcon />
                        <Box pt={0.4}> ปิดหน้าต่าง</Box>
                    </Button>
                    <Button
                        type="submit"
                        variant="contained"
                        color={defaultValue ? 'secondary' : 'primary'}
                        sx={{ borderRadius: '8px', alignItems: 'center', gap: 0.5 }}
                    >
                        {defaultValue ? (
                            <>
                                <EditTwoToneIcon />
                                <Box pt={0.4}> ยันยันแก้ไข</Box>
                            </>
                        ) : (
                            <>
                                <MapPinPlus size={20} strokeWidth={1.5} absoluteStrokeWidth />
                                <Box pt={0.4}> ยันยัน</Box>
                            </>
                        )}
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};

export const CO2Viewer = [
    { id: 'week', title: 'Week' },
    { id: 'month', title: 'Month' },
    { id: 'project', title: 'Project' },
];

export const LANGUAGES = [
    { label: 'Thai', code: 'th', flag: 'TH' },
    { label: 'English', code: 'en', flag: 'US' },
    { label: 'Chinese', code: 'cn', flag: 'CN' },
];

export const PlaceEvent = [
    { value: 'ศูนย์การประชุมแห่งชาติสิริกิติ์', label: 'FORM_PAGE.LIST_PLACE.PLACE1' },
    { value: 'อื่น ๆ', label: 'FORM_PAGE.LIST_PLACE.PLACE2' },
];

import React from 'react';
import { Button, Switch } from '@mui/material';
import Iconify from '@src/components/layout/admin/components/iconify';
import { useTranslation } from 'react-i18next';
import { getNameByLanguage } from '../UtilEmission';

interface EmissionItemProps {
    item: any;
    index: number;
    language: string;
    handleChangeSwitch: (index: number) => void;
    handleSpecify: (item: any) => void;
}
const EmissionItem: React.FC<EmissionItemProps> = ({ item, index, handleChangeSwitch, handleSpecify, language }) => {
    const { t } = useTranslation();

    return (
        <div>
            <div className="flex justify-between items-center py-1">
                <h4 className="content-left text-black">{item[getNameByLanguage(language)]}</h4>
                <Switch className="content-left" id={index + '_switch'} onChange={() => handleChangeSwitch(index)} color="info" checked={item.default} />
            </div>

            <div className="default"></div>
            {!item.default && (
                <Button
                    variant="contained"
                    fullWidth
                    type="button"
                    color="primary"
                    sx={{
                        fontFamily: 'Kanit, sans-serif',
                        backgroundColor: '#106DBE',
                        borderRadius: 2,
                        justifyContent: 'left',
                    }}
                    className="gap-2"
                    onClick={() => handleSpecify(item)}
                >
                    <Iconify icon="octicon:sliders-16" />
                    {t('DETAIL_PAGE.BUTTONS.CUSTOM_EVENT')} {item[getNameByLanguage(language)]}
                </Button>
            )}
        </div>
    );
};

export default EmissionItem;

import React from 'react';
import { InputAdornment, OutlinedInput, Toolbar } from '@mui/material';
import { Search } from 'lucide-react';
import { ButtonAdd } from '../../components/Element/ButtonAdd';
const LocationToolbar = ({
    search,
    onSearch,
    handleAddLocation,
    textButtonAdd = 'เพิ่มสถานที่',
}: {
    search: string;
    textButtonAdd?: string;
    onSearch: (e: React.ChangeEvent<HTMLInputElement>) => void;
    handleAddLocation: () => void;
}) => {
    return (
        <Toolbar sx={{ gap: 1, justifyItems: 'center', justifyContent: 'end' }}>
            <OutlinedInput
                fullWidth
                size="small"
                value={search}
                type="search"
                onChange={onSearch}
                placeholder="ค้นหาสถานที่... "
                startAdornment={
                    <InputAdornment position="start">
                        <Search size={20} strokeWidth={1.5} absoluteStrokeWidth />
                    </InputAdornment>
                }
                sx={{
                    borderRadius: 2,
                    '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#6CB2A1',
                    },
                    maxWidth: 250,
                }}
            />
            <ButtonAdd text={textButtonAdd} onClick={handleAddLocation} />
        </Toolbar>
    );
};

export default LocationToolbar;

import { Typography } from '@mui/material';
import React, { ReactNode } from 'react';

type headerZeroProps = {
    title: string;
    filterElement?: ReactNode;
};
export const HeaderZero = ({ title, filterElement }: headerZeroProps) => {
    return (
        <div className="flex justify-between sm:flex-row flex-col">
            <Typography
                variant="h5"
                sx={{
                    my: 3,
                    px: {
                        xs: 3,
                        sm: 3,
                        md: 3,
                        lg: 0,
                    },
                    fontWeight: '600',
                }}
            >
                {title}
            </Typography>
            <div className="md:flex items-center justify-between hidden ">{filterElement}</div>
        </div>
    );
};

import { MapPinned } from 'lucide-react';
import ListLocations from './list-location';
import { Grid, Skeleton } from '@mui/material';
import { Suspense } from 'react';
import { useGlobalState } from '@src/context/GlobalStateContext';
import SubLocations from './sub-location';
import Swal from 'sweetalert2';
import { useQueryClient } from '@tanstack/react-query';
import { useDeleteSubLocationQuery, useSubLocationQuery } from '@src/services/queries/useLocationQuery';
import { ILocation } from '@src/types';
export const ManageLocationsSections = ({ isPrivate = false }: { isPrivate: boolean }) => {
    const { subLocation, setSubLocation } = useGlobalState();
    const { mutateAsync: deleteSubLocation } = useDeleteSubLocationQuery();
    const { mutateAsync: getSubLocation } = useSubLocationQuery();

    const queryClient = useQueryClient();

    const loadSubLocations = async () => {
        const response = await getSubLocation({ locationId: subLocation?.id as number });
        if (response) {
            let newSubLocation = { ...subLocation };
            newSubLocation.subLocation = response;
            setSubLocation(undefined);
            setSubLocation(newSubLocation as ILocation);
        }
    };
    const handelDeleteSubLocation = (subLocationId: number) => {
        Swal.fire({
            title: 'คุณแน่ใจหรือไม่ ?',
            text: 'คุณต้องการลบสถานที่ย่อยนี้',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'ยืนยัน',
            cancelButtonText: 'ยกเลิก',
        }).then(async result => {
            if (result.isConfirmed) {
                const result = await deleteSubLocation({ subLocationId: subLocationId });
                // delete subLocation
                if (result) {
                    Swal.fire({
                        icon: 'success',
                        title: 'ลบสถานที่ย่อยสําเร็จ',
                        timer: 1000,
                        showConfirmButton: false,
                    });

                    loadSubLocations();
                }
                queryClient.invalidateQueries({ queryKey: ['locations'] });
            }
        });
    };

    return (
        <div className="w-full  px-2 py-3">
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <div className=" bg-white shadow-md rounded-md p-4 my-4">
                        <div className="flex items-center gap-4">
                            <MapPinned size={48} strokeWidth={1.5} />
                            <div className="grid gap-1">
                                <h1 className="text-2xl font-bold">จัดการข้อมูลสถานที่</h1>
                                <div className="flex items-center gap-2 text-sm text-muted-foreground">
                                    <span>{'จัดการข้อมูลสถานที่ สถานที่ย่อย และขนาดพื้นที่'}</span>
                                </div>
                            </div>
                        </div>
                        <Suspense fallback={<Skeleton variant="rectangular" width={'100%'} height={80} />}>
                            <ListLocations isPrivate={isPrivate} />
                        </Suspense>
                    </div>
                </Grid>

                {subLocation !== undefined && (
                    <Grid item xs={6}>
                        <SubLocations onDelete={handelDeleteSubLocation} dataSubLocation={subLocation} closeSubLocation={() => setSubLocation(undefined)} />
                    </Grid>
                )}
            </Grid>
        </div>
    );
};

import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import './i18n';
import 'bulma/css/bulma.css';
import './index.css';
import { AuthProvider } from './context/AuthContext';
import { GlobalStateProvider } from './context/GlobalStateContext';
import { config } from './config';
import { ManageAccessRouter } from './routes/ManageAccessRouter';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
const queryClient = new QueryClient();
const clientId = process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID ?? config.keyGOOGLE_OAUTH_CLIENT_ID;
root.render(
    <AuthProvider>
        <GlobalStateProvider>
            <QueryClientProvider client={queryClient}>
                <GoogleOAuthProvider clientId={clientId}>
                    <ManageAccessRouter />
                </GoogleOAuthProvider>
            </QueryClientProvider>
        </GlobalStateProvider>
    </AuthProvider>,
);

reportWebVitals();

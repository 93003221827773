import React from 'react';
import { Navigate } from 'react-router';
interface ProtectedRouteProps {
    component: React.ComponentType;
    allowedRoles: string[];
    userRole: string;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ component: Component, allowedRoles, userRole }) => {
    if (!allowedRoles.includes(userRole)) {
        return <Navigate to="/unauthorized" replace />;
    }
    return <Component />;
};

export default ProtectedRoute;

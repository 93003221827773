import { Button } from '@mui/material';
import AddTwoToneIcon from '@mui/icons-material/AddTwoTone';

export const ButtonAdd = ({ onClick, text }: { text?: string; onClick?: () => void }) => {
    return (
        <Button
            color="success"
            variant="contained"
            onClick={onClick}
            sx={{
                color: 'black',
                backgroundColor: '#B4E380',
                '&:hover': {
                    backgroundColor: '#88D66C',
                },
            }}
        >
            <AddTwoToneIcon /> {text}
        </Button>
    );
};

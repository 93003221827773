import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Swal from 'sweetalert2';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import ReactPasswordChecklist from 'react-password-checklist';
import { useRegister } from '@src/services/queries/useAuthQuery';
import { IRegister } from '@src/types';
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material';

interface propsSignUp {
    open: boolean;
    handleClose: () => void;
}

export default function SignUp(props: propsSignUp) {
    const useRegisterQuery = useRegister();
    const { open, handleClose } = props;
    const { t } = useTranslation();
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [passwordConfirmVisible, setPasswordConfirmVisible] = useState(false);

    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };
    const togglePasswordConfirmVisibility = () => {
        setPasswordConfirmVisible(!passwordConfirmVisible);
    };

    const {
        register,
        handleSubmit,
        reset,
        watch,
        control,
        formState: { errors },
    } = useForm<IRegister>({
        defaultValues: {
            firstName: '',
            lastName: '',
            email: '',
            tel: '',
            password: '',
            passwordConfirm: '',
            role: 'MEMBER',
        },
    });

    const passwordInput = watch('password');
    const passwordConfirmInput = watch('passwordConfirm');

    function handleCancel() {
        reset();
        handleClose();
    }

    const handleRegistration: SubmitHandler<IRegister> = async e => {
        console.log(e);
        try {
            const response = await useRegisterQuery.mutateAsync(e);
            if (response.status === 201) {
                Swal.fire({
                    title: `${t('REGISTER_SUCCESS')}`,
                    icon: 'success',
                    showConfirmButton: false,
                    timer: 1500,
                }).then(() => {
                    // reset form
                    handleCancel();
                });
            }
            // Handle success
        } catch (error: any) {
            const { data } = error.response;
            if (data?.statusCode !== 201) {
                Swal.fire({
                    title: data?.message,
                    icon: 'error',
                    showConfirmButton: true,
                    confirmButtonText: `${t('TEXT_CLOSE')}`,
                });
            }
        }
    };

    return (
        <Dialog
            open={open}
            onClose={handleCancel}
            fullWidth
            maxWidth="sm"
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
            PaperProps={{
                style: {
                    borderRadius: '15px', // Adjust the value as needed
                },
            }}
        >
            <form onSubmit={handleSubmit(handleRegistration)} action="#">
                <DialogTitle id="scroll-dialog-title">
                    <div className="columns">
                        <div className="column is-6">
                            <h3 className="content-sub-header content-left font-bold">{t('SIGN_UP_PAGE.HEADER')}</h3>
                        </div>
                    </div>
                </DialogTitle>
                <hr style={{ backgroundColor: '#828693', marginTop: '-5px', marginBottom: '15px' }} />
                <DialogContent dividers={false}>
                    <div className="columns" style={{ marginTop: '-35px' }}>
                        <div className="column is-12">
                            <div className="field">
                                <p className="control is-expanded">
                                    <p className="content-left">
                                        {t('SIGN_UP_PAGE.FIRST_NAME')}
                                        <span className="has-text-danger">*</span>
                                    </p>
                                    <input
                                        className="input"
                                        type="text"
                                        placeholder={t('SIGN_UP_PAGE.FIRST_NAME')}
                                        style={{
                                            borderRadius: '10px',
                                            borderColor: errors.firstName ? 'red' : '',
                                            background: errors.firstName ? '' : '',
                                        }}
                                        {...register('firstName', {
                                            required: t('ERROR_MSG_FORM.ERROR_FORM_PREFIX_MSG') + t('SIGN_UP_PAGE.FIRST_NAME'),
                                        })}
                                    />
                                    {errors?.firstName ? <p className="content-error">{errors.firstName.message}</p> : <p className="content-error">&nbsp;</p>}
                                </p>
                            </div>

                            <div className="field">
                                <p className="control is-expanded">
                                    <p className="content-left">
                                        {t('SIGN_UP_PAGE.LAST_NAME')}
                                        <span className="has-text-danger">*</span>
                                    </p>
                                    <input
                                        className="input"
                                        type="text"
                                        placeholder={t('SIGN_UP_PAGE.LAST_NAME')}
                                        style={{
                                            borderRadius: '10px',
                                            borderColor: errors.lastName ? 'red' : '',
                                            background: errors.lastName ? '' : '',
                                        }}
                                        {...register('lastName', {
                                            required: t('ERROR_MSG_FORM.ERROR_FORM_PREFIX_MSG') + t('SIGN_UP_PAGE.LAST_NAME'),
                                        })}
                                    />
                                    {errors?.lastName ? <p className="content-error">{errors.lastName.message}</p> : <p className="content-error">&nbsp;</p>}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="columns" style={{ marginTop: '-30px' }}>
                        <div className="column is-12">
                            <div className="field">
                                <p className="control is-expanded">
                                    <p className="content-left">
                                        {t('SIGN_UP_PAGE.EMAIL')}
                                        <span className="has-text-danger">*</span>
                                    </p>
                                    <input
                                        className="input"
                                        type="email"
                                        id="setEmail"
                                        placeholder={t('SIGN_UP_PAGE.EMAIL')}
                                        style={{
                                            borderRadius: '10px',
                                            borderColor: errors.email ? 'red' : '',
                                            background: errors.email ? '' : '',
                                        }}
                                        {...register('email', {
                                            required:
                                                t('ERROR_MSG_FORM.ERROR_FORM_PREFIX_MSG') + t('SIGN_UP_PAGE.EMAIL') + t('ERROR_MSG_FORM.ERROR_FORM_SUFFIX_MSG'),
                                            pattern: {
                                                value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                                                message: t('ERROR_MSG_FORM.ERROR_EMAIL_MSG'),
                                            },
                                        })}
                                    />
                                    {errors?.email ? <p className="content-error">{errors.email.message}</p> : <p className="content-error">&nbsp;</p>}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="columns" style={{ marginTop: '-30px', marginBottom: '-18px' }}>
                        <div className="column is-12">
                            <div className="field">
                                <p className="control is-expanded">
                                    <p className="content-left">
                                        {t('SIGN_UP_PAGE.PHONE')}
                                        <span className="has-text-danger">*</span>
                                    </p>
                                    <input
                                        className="input"
                                        type="tel"
                                        placeholder={t('SIGN_UP_PAGE.PHONE')}
                                        style={{
                                            borderRadius: '10px',
                                            borderColor: errors.tel ? 'red' : '',
                                            background: errors.tel ? '' : '',
                                        }}
                                        {...register('tel', {
                                            required:
                                                t('ERROR_MSG_FORM.ERROR_FORM_PREFIX_MSG') + t('SIGN_UP_PAGE.PHONE') + t('ERROR_MSG_FORM.ERROR_FORM_SUFFIX_MSG'),
                                            pattern: {
                                                value: /^[0-9]{10}$/,
                                                message: t('ERROR_MSG_FORM.ERROR_PHONE_MSG'),
                                            },
                                        })}
                                    />
                                    {errors?.tel ? <p className="content-error">{errors.tel.message}</p> : <p className="content-error">&nbsp;</p>}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="columns">
                        <div className="column">
                            <div className="field">
                                <p className="control is-expanded">
                                    <p className="content-left">
                                        {t('SETTING_PAGE.CHANGE_PASSWORD_DIAG.PASSWORD')}
                                        <span className="has-text-danger">*</span>
                                    </p>
                                    <input
                                        className="input"
                                        type={passwordVisible ? 'text' : 'password'}
                                        placeholder={t('SETTING_PAGE.CHANGE_PASSWORD_DIAG.PASSWORD')}
                                        style={{
                                            borderRadius: '10px',
                                            borderColor: errors.password ? 'red' : '',
                                            background: errors.password ? '' : '',
                                        }}
                                        {...register('password', {
                                            required:
                                                t('ERROR_MSG_FORM.ERROR_FORM_PREFIX_MSG') +
                                                t('LOGIN_PAGE.PASSWORD') +
                                                t('ERROR_MSG_FORM.ERROR_FORM_SUFFIX_MSG'),
                                        })}
                                    />
                                    <i
                                        className={passwordVisible ? 'fa-solid fa-eye' : 'fa-solid fa-eye-slash'}
                                        onClick={togglePasswordVisibility}
                                        style={{
                                            cursor: 'pointer',
                                            marginInlineStart: '-30px',
                                            marginTop: '13px',
                                            position: 'absolute',
                                        }}
                                    ></i>
                                    {errors?.password ? <p className="content-error">{errors.password.message}</p> : <p className="content-error">&nbsp;</p>}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="columns" style={{ marginTop: '-30px' }}>
                        <div className="column">
                            <div className="field">
                                <p className="control is-expanded">
                                    <p className="content-left">
                                        {t('SETTING_PAGE.CHANGE_PASSWORD_DIAG.CONFIRM_PASSWORD')}
                                        <span className="has-text-danger">*</span>
                                    </p>
                                    <input
                                        className="input"
                                        type={passwordConfirmVisible ? 'text' : 'password'}
                                        placeholder={t('SETTING_PAGE.CHANGE_PASSWORD_DIAG.CONFIRM_PASSWORD')}
                                        style={{
                                            borderRadius: '10px',
                                            borderColor: errors.passwordConfirm ? 'red' : '',
                                            background: errors.passwordConfirm ? '' : '',
                                        }}
                                        {...register('passwordConfirm', {
                                            required:
                                                t('ERROR_MSG_FORM.ERROR_FORM_PREFIX_MSG') +
                                                t('LOGIN_PAGE.PASSWORD') +
                                                t('ERROR_MSG_FORM.ERROR_FORM_SUFFIX_MSG'),
                                            validate: value => value === passwordInput || 'Passwords do not match',
                                        })}
                                    />
                                    <i
                                        className={passwordConfirmVisible ? 'fa-solid fa-eye' : 'fa-solid fa-eye-slash'}
                                        onClick={togglePasswordConfirmVisibility}
                                        style={{
                                            cursor: 'pointer',
                                            marginInlineStart: '-30px',
                                            marginTop: '13px',
                                            position: 'absolute',
                                        }}
                                    ></i>
                                    {errors?.passwordConfirm ? (
                                        <p className="content-error">{errors.passwordConfirm.message}</p>
                                    ) : (
                                        <p className="content-error">&nbsp;</p>
                                    )}
                                </p>
                            </div>
                        </div>
                    </div>

                    <FormControl component="fieldset" sx={{ mb: 2, px: 2 }}>
                        <FormLabel component="legend">{t('REGISTER.LABEL_ROLE')}</FormLabel>
                        <Controller
                            rules={{ required: t('REGISTER.ROLE_MESSAGE') }}
                            control={control}
                            name="role"
                            render={({ field }) => (
                                <RadioGroup {...field} defaultValue="MEMBER">
                                    <FormControlLabel value="MEMBER" control={<Radio />} label={t('REGISTER.MEMBER')} />
                                    <FormControlLabel value="PROJECT_OWNER" control={<Radio />} label={t('REGISTER.PROJECT_OWNER')} />
                                    <FormControlLabel value="EVENT_MANAGER" control={<Radio />} label={t('REGISTER.EVENT_MANAGER')} />
                                </RadioGroup>
                            )}
                        />
                        {errors?.role ? <p className="content-error">{errors.role.message}</p> : <p className="content-error">&nbsp;</p>}
                    </FormControl>

                    <div className="columns" style={{ marginTop: '-35px' }}>
                        <div className="column is-12">
                            <div className="box">
                                <h3 className="content-sub-header content-left">{t('SETTING_PAGE.CHANGE_PASSWORD_DIAG.HEADER')}</h3>
                                <ReactPasswordChecklist
                                    rules={['minLength', 'number', 'specialChar', 'lowercase', 'capital', 'match']}
                                    className="content-left"
                                    minLength={8}
                                    value={passwordInput}
                                    valueAgain={passwordConfirmInput}
                                    // onChange={isValid => {
                                    //     handleValidPassword(isValid);
                                    // }}
                                    messages={{
                                        minLength: t('SETTING_PAGE.CHANGE_PASSWORD_DIAG.MIN_LENGTH'),
                                        number: t('SETTING_PAGE.CHANGE_PASSWORD_DIAG.NUMBER'),
                                        specialChar: t('SETTING_PAGE.CHANGE_PASSWORD_DIAG.SPECIAL_CHAR'),
                                        lowercase: t('SETTING_PAGE.CHANGE_PASSWORD_DIAG.LOWERCASE'),
                                        capital: t('SETTING_PAGE.CHANGE_PASSWORD_DIAG.CAPITAL'),
                                        match: t('SETTING_PAGE.CHANGE_PASSWORD_DIAG.MATCH'),
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <div className="column">
                        <div className="buttons content-center">
                            <button className="button is-rounded is-danger App" type="button" onClick={handleCancel}>
                                {t('BUTTONS.CANCEL')}
                            </button>
                            <button className="button is-rounded is-link App" type="submit">
                                {t('BUTTONS.REGISTRATION')}
                            </button>
                        </div>
                    </div>
                </DialogActions>
            </form>
        </Dialog>
    );
}

import HiLightDashboard from '@src/components/Hi-LIght-Dashboard';
import React from 'react';
import { useNavigate } from 'react-router';
import TablePre from './table-pre';
import { useGlobalState } from '@src/context/GlobalStateContext';

const GuestDashboard = () => {
    const navigate = useNavigate();
    const { setFormMice, setGasEmissions } = useGlobalState();

    const handleCreate = () => {
        sessionStorage.setItem('tmpCreateData', '');
        localStorage.removeItem('formMice');
        localStorage.removeItem('gasEmissions');
        sessionStorage.removeItem('tmpEventId');
        setFormMice(undefined as any);
        setGasEmissions(undefined as any);
        navigate('/guest-register/new/1');
    };
    return (
        <div className="container mt-6">
            <HiLightDashboard handleCreateProject={handleCreate} />
            <TablePre />
        </div>
    );
};

export default GuestDashboard;

import React from 'react';
import { useTranslation } from 'react-i18next';
import CustomNumberInput from './CustomNumberInput';

export const EmissionRunTimeTypeInput = ({ runtime_type, register, errors }: { runtime_type: string; register: any; errors?: any }) => {
    const { t } = useTranslation();

    return (
        <>
            {runtime_type === 'TravelEmission' && (
                <>
                    <CustomNumberInput
                        name="passengers_total"
                        label={`
                             ${t('DETAIL_PAGE.TRANSPORTER_DETAIL.AMOUNT')}
                             (${t('DETAIL_PAGE.TRANSPORTER_DETAIL.AMOUNT_UNIT')})
                             `}
                        error={errors.passengers_total}
                        register={register}
                        requiredMessage={
                            t('ERROR_MSG_FORM.ERROR_FORM_PREFIX_MSG') + t('DETAIL_PAGE.TRANSPORTER_DETAIL.AMOUNT') + t('ERROR_MSG_FORM.ERROR_FORM_SUFFIX_MSG')
                        }
                        minValue={1}
                        minValueMessage={t('DETAIL_PAGE.TRANSPORTER_DETAIL.AMOUNT') + t('ERROR_MSG_FORM.ERROR_AMT_GT_MSG') + 1}
                        maxLength={8}
                    />
                    <CustomNumberInput
                        name="distance_outbound_total"
                        label={`
                             ${t('DETAIL_PAGE.TRANSPORTER_DETAIL.DISTANCT')}
                             (${t('DETAIL_PAGE.TRANSPORTER_DETAIL.DISTANCT_UNIT')})
                             `}
                        error={errors.distance_outbound_total}
                        register={register}
                        requiredMessage={
                            t('ERROR_MSG_FORM.ERROR_FORM_PREFIX_MSG') + t('DETAIL_PAGE.TRANSPORTER_DETAIL.DISTANCT') + t('ERROR_MSG_FORM.ERROR_FORM_SUFFIX_MSG')
                        }
                        minValue={1}
                        minValueMessage={t('DETAIL_PAGE.TRANSPORTER_DETAIL.DISTANCT') + t('ERROR_MSG_FORM.ERROR_AMT_GT_MSG') + 1}
                        maxLength={8}
                    />
                </>
            )}

            {runtime_type === 'AccommodationEmission' && (
                <>
                    <CustomNumberInput
                        name="guests"
                        label={`
                             ${t('DETAIL_PAGE.REST_PLACE_DETAIL.AMOUNT_PERSON')}
                             (${t('DETAIL_PAGE.REST_PLACE_DETAIL.AMOUNT_PERSON_UNIT')})
                             `}
                        error={errors.guests}
                        register={register}
                        requiredMessage={
                            t('ERROR_MSG_FORM.ERROR_FORM_PREFIX_MSG') +
                            t('DETAIL_PAGE.REST_PLACE_DETAIL.AMOUNT_PERSON') +
                            t('ERROR_MSG_FORM.ERROR_FORM_SUFFIX_MSG')
                        }
                        minValue={1}
                        minValueMessage={t('DETAIL_PAGE.REST_PLACE_DETAIL.AMOUNT_PERSON') + t('ERROR_MSG_FORM.ERROR_AMT_GT_MSG') + 1}
                        maxLength={8}
                    />
                    <CustomNumberInput
                        name="night"
                        label={`
                             ${t('DETAIL_PAGE.REST_PLACE_DETAIL.AMOUNT')}
                             (${t('DETAIL_PAGE.REST_PLACE_DETAIL.AMOUNT_UNIT')})
                             `}
                        error={errors.night}
                        register={register}
                        requiredMessage={
                            t('ERROR_MSG_FORM.ERROR_FORM_PREFIX_MSG') + t('DETAIL_PAGE.REST_PLACE_DETAIL.AMOUNT') + t('ERROR_MSG_FORM.ERROR_FORM_SUFFIX_MSG')
                        }
                        minValue={1}
                        minValueMessage={t('DETAIL_PAGE.REST_PLACE_DETAIL.AMOUNT') + t('ERROR_MSG_FORM.ERROR_AMT_GT_MSG') + 1}
                        maxLength={8}
                    />
                </>
            )}

            {(runtime_type === 'WasteEmission' || runtime_type === 'GiftEmission') && (
                <CustomNumberInput
                    name="weight"
                    label={`
                             ${t('DETAIL_PAGE.WASTE_DETAIL.AMOUNT')}
                             (${t('DETAIL_PAGE.WASTE_DETAIL.AMOUNT_UNIT')})
                             `}
                    error={errors.weight}
                    register={register}
                    requiredMessage={
                        t('ERROR_MSG_FORM.ERROR_FORM_PREFIX_MSG') + t('DETAIL_PAGE.WASTE_DETAIL.AMOUNT') + t('ERROR_MSG_FORM.ERROR_FORM_SUFFIX_MSG')
                    }
                    minValue={1}
                    minValueMessage={t('DETAIL_PAGE.WASTE_DETAIL.AMOUNT') + t('ERROR_MSG_FORM.ERROR_AMT_GT_MSG') + 1}
                    maxLength={8}
                />
            )}

            {(runtime_type === 'ElectricEmission' || runtime_type === 'ElectricOnLineEmission') && (
                <CustomNumberInput
                    name="kw_hour"
                    allowDecimal
                    label={`
                             ${t('DETAIL_PAGE.ELECTRIC_SITE_DETAIL.AMOUNT')}
                             (${t('DETAIL_PAGE.ELECTRIC_SITE_DETAIL.AMOUNT_UNIT')})
                             `}
                    error={errors.kw_hour}
                    register={register}
                    requiredMessage={
                        t('ERROR_MSG_FORM.ERROR_FORM_PREFIX_MSG') + t('DETAIL_PAGE.ELECTRIC_SITE_DETAIL.AMOUNT') + t('ERROR_MSG_FORM.ERROR_FORM_SUFFIX_MSG')
                    }
                    minValue={1}
                    minValueMessage={t('DETAIL_PAGE.ELECTRIC_SITE_DETAIL.AMOUNT') + t('ERROR_MSG_FORM.ERROR_AMT_GT_MSG') + 1}
                    maxLength={8}
                />
            )}

            {runtime_type === 'FoodEmission' && (
                <CustomNumberInput
                    name="set"
                    label={`
                             ${t('DETAIL_PAGE.FOOD_DRINK_DETAIL.AMOUNT')}
                             (${t('DETAIL_PAGE.FOOD_DRINK_DETAIL.AMOUNT_UNIT')})
                             `}
                    error={errors.set}
                    register={register}
                    requiredMessage={
                        t('ERROR_MSG_FORM.ERROR_FORM_PREFIX_MSG') + t('DETAIL_PAGE.FOOD_DRINK_DETAIL.AMOUNT') + t('ERROR_MSG_FORM.ERROR_FORM_SUFFIX_MSG')
                    }
                    minValue={1}
                    minValueMessage={t('DETAIL_PAGE.FOOD_DRINK_DETAIL.AMOUNT') + t('ERROR_MSG_FORM.ERROR_AMT_GT_MSG') + 1}
                    maxLength={8}
                />
            )}
        </>
    );
};

import React from 'react';
import { ToastContainer } from 'react-toastify';
import { Outlet } from 'react-router';
import DashboardNav from './nav';
export const DashboardLayout = () => {
    return (
        <div className="App">
            <ToastContainer style={{ width: '400px' }} pauseOnFocusLoss={false} newestOnTop />
            <DashboardNav />
            <Outlet />
        </div>
    );
};

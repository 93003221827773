// import { Container, Typography } from '@mui/material';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { HeaderZero } from '../../../components/header-zero';
import CardDetails from '../../../components/activity-details/card-details';
import TotalCarbon from '../../../components/activity-details/total-carbon';
import SummaryTable from '../../../components/activity-details/summary-table';
import SummaryBarChart from '../../../components/activity-details/summary-bar-chart';
import LoadingSkeletonScreen from '../../../components/Event/LoadingSkeletonScreen';
import { useEventsByIdQuery } from '@src/services/queries/useEventsQuery';
import PageError from '../../../components/Error/page-error';
import { formatDate } from '@src/utils/format-date-th';
import { EmissionsSourcesProps } from '@src/types';
import { useParams } from 'react-router';

const PreEventView = ({ title }: { title?: string }) => {
    let { id } = useParams();
    const { isLoading, data, isError } = useEventsByIdQuery(parseInt(id as string));
    const formatPhoneNumber = (phoneNumber: string) => {
        const phoneNumberArray = phoneNumber.split('');
        phoneNumberArray.splice(3, 0, '-');
        return phoneNumberArray.join('');
    };

    const templateGeneral = [
        {
            name: 'ชื่องาน',
            value: data?.name ?? '-',
        },
        {
            name: 'ประเภทงาน',
            value: data?.subCategory?.name ?? '-',
        },
        {
            name: 'สถานที่จัดงาน',
            value: data?.location ?? '-',
        },
        {
            name: 'วันเเรกของงาน',
            value: formatDate(data?.startDate, true) ?? '-',
        },
        {
            name: 'วันสุดท้ายของงาน',
            value: formatDate(data?.endDate, true) ?? '-',
        },

        {
            name: 'ระยะเวลาจัดงาน',
            value: `${data?.eventPeriod} ชม.`,
        },
        {
            name: 'ขนาดพื้นที่จัดงาน',
            value: `${data?.eventSpace ?? 0} ตร.ม.`,
        },
        {
            name: 'สัดส่วนพื้นที่กลางแจ้ง/พื้นที่การจัดงานทั้งหมด',
            value: `${data?.outDoorPercent ?? 0}  %`,
        },
        {
            name: 'จำนวนผู้จัดงานและผู้ร่วมงาน',
            value: `${data?.eventMember ?? 0} คน/วัน`,
        },
        {
            name: 'จำนวนผู้จัดงานและผู้ร่วมงาน Online',
            value: `${data?.eventMemberOnline ?? 0}  คน/วัน`,
        },
        {
            name: 'สัดส่วนผู้จัดงานและผู้ร่วมงานที่เดินทางมาจากต่างประเทศ/จำนวนผู้จัดงานและผู้ร่วมงานทั้งหมด',
            value: `${data?.eventForeignScale ?? 0} %`,
        },
        {
            name: 'ชื่อผู้จัดงาน',
            value: data?.eventOwner ?? '-',
        },
    ];

    const userName = data?.user?.firstName + ' ' + data?.user?.lastName;
    const templateContact = [
        {
            name: 'ชื่อ:',
            value: userName,
        },
        {
            name: 'อีเมล:',
            value: data?.user?.email ?? '',
        },
        {
            name: 'เบอร์โทรศัพท์:',
            value: data?.user?.tel ? formatPhoneNumber(data?.user?.tel) : '',
        },
    ];
    const emissionsSources: EmissionsSourcesProps[] = data?.emission?.emissions_sources ?? [];

    if (isLoading) return <LoadingSkeletonScreen />;

    if (isError) return <PageError />;
    return (
        <>
            <Helmet>
                <title> {title ?? ''} | Zero Carbon </title>
            </Helmet>
            <HeaderZero title={title ?? ''} />

            <div className="flex-col items-center gap-y-2">
                <CardDetails title="ข้อมูลทั่วไป">
                    <div className="grid md:grid-cols-2 text-sm mt-2 text-black">
                        {templateGeneral.map((template, index) => (
                            <div className="grid grid-cols-2 mb-0" key={index}>
                                <div className="px-4 py-2 font-semibold">{template.name}</div>
                                <div className="px-4 py-2">{template.value}</div>
                            </div>
                        ))}
                    </div>
                </CardDetails>

                <CardDetails className="mt-4" title="รายละเอียดการติดต่อ">
                    <div className="grid md:grid-cols-3 text-sm mt-2 text-black">
                        {templateContact.map((template, index) => (
                            <div className="flex gap-2" key={index}>
                                <div className="ml-4 py-2 font-semibold">{template.name}</div>
                                <div className="py-2">{template.value}</div>
                            </div>
                        ))}
                    </div>
                </CardDetails>
                <CardDetails className="mt-4" title="สรุปผล">
                    <div className="grid grid-cols-6 gap-4 text-sm mt-2">
                        <div className="col-span-4">
                            <SummaryTable dataTable={emissionsSources} />
                        </div>
                        <div className="col-span-2">
                            <TotalCarbon title="ปริมาณคาร์บอนฟุตพริ้นท์รวม" value={data?.emission?.summary} type="ตัน" />
                        </div>
                        <div className="col-span-4">
                            <SummaryBarChart dataChart={emissionsSources} />
                        </div>
                        <div className="col-span-2">
                            <TotalCarbon
                                title="ค่าเฉลี่ยต่อจำนวนผู้ร่วมงาน"
                                color="bg-red-800"
                                value={Math.ceil(data?.emission?.summary_person)}
                                type="ตัน/คน"
                            />
                        </div>
                    </div>
                </CardDetails>
            </div>
        </>
    );
};

export default PreEventView;

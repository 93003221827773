import { Button, IconButton } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Iconify from '@src/components/layout/admin/components/iconify';
import { useNavigate } from 'react-router';
import { ManageLocationsSections } from '@src/components/layout/admin/sections/manage-locations';

export const SettingLocation = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const handleBack = () => {
        try {
            navigate(-1);
        } catch (error) {
            navigate('/');
        }
    };

    return (
        <div className="container text-left">
            <div className=" mt-6">
                <div className="my-4">
                    <Button variant="outlined" className="!font-Kanit !rounded-lg" color="inherit" size="small" onClick={handleBack}>
                        <IconButton>
                            <Iconify icon="bitcoin-icons:arrow-left-filled" sx={{ fontSize: 18, color: '#000' }} width={20} />
                        </IconButton>
                        {t('BUTTONS.BACKWARD')}
                    </Button>
                </div>
                {/* <h2 className="text-4xl">{t('MANAGE_PLACES')}</h2> */}
            </div>
            <ManageLocationsSections isPrivate={true} />
        </div>
    );
};

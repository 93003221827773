import { useQuery } from '@tanstack/react-query';
import api from '../axios';

export const useProjectsQuery = (min: boolean) => {
    return useQuery({
        queryKey: ['projects', min],
        queryFn: async ({ queryKey }: { queryKey: [string, boolean] }) => {
            const [, min] = queryKey;
            try {
                const { data, status } = await api.get(`/projects?mine=${min}`);
                return {
                    data,
                    status,
                };
            } catch (error: any) {
                return {
                    data: error?.response?.data,
                    status: error?.response?.status,
                };
            }
        },
    });
};

import React from 'react';
import Box from '@mui/material/Box';
import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';
import TableCell from '@mui/material/TableCell';
import TableSortLabel from '@mui/material/TableSortLabel';
import { visuallyHidden } from './utils';

type TableHeaderProps = {
    order: any;
    orderBy: string;
    rowCount?: number;
    headLabel: object[];
    numSelected?: number;
    defaultChecked?: boolean;
    onRequestSort: (data: any, property: any) => void;
};
const TableHeadZero = ({ order, orderBy, rowCount, headLabel, numSelected, onRequestSort, defaultChecked = false }: TableHeaderProps) => {
    const onSort = (property: any) => (event: any) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead
            sx={{
                '& th': {
                    color: 'black',
                    backgroundColor: '#e9e9ea',
                },
            }}
        >
            <TableRow>
                {defaultChecked && (
                    <TableCell padding="checkbox" sx={{ padding: 0 }}>
                        เลือก
                    </TableCell>
                )}

                {headLabel.map((headCell: any, index: number) => (
                    <TableCell
                        key={index}
                        align={headCell?.align || 'left'}
                        sortDirection={orderBy === headCell.id ? order : false}
                        sx={{
                            width: headCell.width,
                            minWidth: headCell.minWidth,
                            textAlign: `${headCell?.align || 'left'} !important`,
                        }}
                    >
                        <TableSortLabel
                            hideSortIcon
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={onSort(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box sx={{ ...visuallyHidden }}>{order === 'desc' ? 'sorted descending' : 'sorted ascending'}</Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
};

export default TableHeadZero;

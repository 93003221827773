import React, { useEffect, useState, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { blockInvalidChar, dateFormatMUI } from '@src/constants/screen/util';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { useGlobalState } from '@src/context/GlobalStateContext';
import { ILocation, IMiceForm } from '@src/types';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Tooltip } from '@mui/material';
import { Select } from 'antd';
import { useLocationQuery } from '@src/services/queries/useLocationQuery';
const { Option } = Select;

const locationOther = 'อื่น ๆ';
const MyForm: React.FC = () => {
    const { data: locationData } = useLocationQuery(false);

    const navigate = useNavigate();
    const { event } = useParams();
    const { t, i18n } = useTranslation();
    const [selectedPlace, setSelectedPlace] = useState<string>('');
    const { formMice, setFormMice } = useGlobalState();
    const [locations, setLocations] = useState<ILocation[]>([]);

    const {
        register,
        control,
        handleSubmit,
        setValue,
        trigger,
        watch,
        resetField,
        formState: { errors },
    } = useForm<IMiceForm>();
    const startDate = watch('startDate');
    const endDate = watch('endDate');

    const findSubLocations = useMemo(() => {
        if (selectedPlace !== locationOther) {
            const resultLocations = locations?.find(item => item.name === selectedPlace);
            return resultLocations?.subLocation;
        } else {
            return [];
        }
    }, [locations, selectedPlace]);

    function handleSelectPlace(e: string) {
        // const { value } = e.target;
        setSelectedPlace(e);
        setValue('selectPlace', e);
        setValue('subLocation', '');
        // const locationFilter = location.filter(item => value === item.name);
        // if (locationFilter.length > 0) {
        //     setValue('eventArea', locationFilter[0].area as number);
        // } else {
        //     setValue('eventArea', 0 as number);
        // }
    }

    function handleSelectSubLocation(valSubLocation: string) {
        setValue('subLocation', valSubLocation);
        if (findSubLocations && findSubLocations.length > 0) {
            const findAreaFromSubLocation = findSubLocations.find(item => valSubLocation === item.name);
            setValue('eventArea', findAreaFromSubLocation?.area as number);
        }
    }

    const handleSubmitForm: SubmitHandler<IMiceForm> = e => {
        let objData = {
            name: e.name,
            startDate: e.startDate,
            endDate: e.endDate,
            eventPeriod: e.eventPeriod,
            projectPlace: e.selectPlace,
            location: selectedPlace === locationOther ? e.placeOther : e.selectPlace,
            outDoorPercent: e.outDoorPercent ? e.outDoorPercent : 0,
            renewPercent: e.renewPercent ? e.renewPercent : 0,
            placeOther: e?.placeOther,
            eventArea: e.eventArea,
            eventSpace: e.eventSpace,
            eventMember: e.eventMember,
            eventMemberOnline: e.eventMemberOnline ? e.eventMemberOnline : 0,
            eventForeignScale: e.eventForeignScale ? e.eventForeignScale : 0,
            eventOwner: e.eventOwner,
            subCategoryId: formMice?.subCategoryId,
            subLocation: e.subLocation ?? '',
        };
        setFormMice({ ...objData } as IMiceForm);
        //code old
        let tmpCreateDataStr = sessionStorage.getItem('tmpCreateData');
        let tmpCreateData = null;
        if (tmpCreateDataStr) {
            tmpCreateData = JSON.parse(tmpCreateDataStr!);
            tmpCreateData.status = event;
            tmpCreateData.paging = 3;
        } else {
            tmpCreateData = {
                status: event,
                paging: 3,
            };
        }
        sessionStorage.setItem('tmpCreateData', JSON.stringify(tmpCreateData));

        navigate('/register/' + event + '/3');
    };

    useEffect(() => {
        if (locationData && locationData.data) {
            setLocations(locationData.data?.data);
        }
    }, [locationData]);

    useEffect(() => {
        if (locations && locations.length > 0) {
            const checkPlace = locations.filter((item: ILocation) => item.name === formMice?.location);
            if (checkPlace && checkPlace.length === 0) {
                setValue('selectPlace', locationOther);
                setSelectedPlace(locationOther);
                setValue('placeOther', formMice?.location ?? '');
            } else {
                setValue('selectPlace', formMice?.location ?? '');
                setSelectedPlace(formMice?.location ?? '');
            }
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [locations, formMice?.location, setValue]);

    useEffect(() => {
        setValue('name', formMice?.name ?? '');
        setValue('startDate', formMice?.startDate ? dayjs(formMice.startDate)?.format('YYYY-MM-DD') : '');
        setValue('endDate', formMice?.endDate ? dayjs(formMice.endDate)?.format('YYYY-MM-DD') : '');
        setValue('eventPeriod', formMice?.eventPeriod ?? 0);
        // setValue('selectPlace', formMice?.projectPlace ?? '');
        // setValue('placeOther', formMice?.placeOther ?? '');
        setValue('outDoorPercent', formMice?.outDoorPercent ?? 0);
        setValue('renewPercent', formMice?.renewPercent ?? 0);
        setValue('eventArea', formMice?.eventArea ?? 0);
        setValue('eventSpace', formMice?.eventSpace ?? 0);
        setValue('eventMember', formMice?.eventMember ?? 0);
        setValue('eventMemberOnline', formMice?.eventMemberOnline ?? '');
        setValue('eventForeignScale', formMice?.eventForeignScale ?? '');
        setValue('eventOwner', formMice?.eventOwner ?? '');
        setValue('subLocation', formMice?.subLocation ?? '');
    }, [
        setValue,
        formMice?.eventOwner,
        formMice?.name,
        formMice?.startDate,
        formMice?.endDate,
        formMice?.eventPeriod,
        formMice?.placeOther,
        formMice?.outDoorPercent,
        formMice?.renewPercent,
        formMice?.eventArea,
        formMice?.eventSpace,
        formMice?.eventMember,
        formMice?.eventMemberOnline,
        formMice?.eventForeignScale,
        formMice?.projectPlace,
        formMice?.location,
        formMice?.subLocation,
        formMice,
    ]);

    useEffect(() => {
        const handleLanguageChange = () => {
            setTimeout(() => {
                trigger(); // Re-validate the form to update error messages
            }, 50);
        };

        i18n.on('languageChanged', handleLanguageChange);

        return () => {
            i18n.off('languageChanged', handleLanguageChange);
        };
    }, [i18n, trigger]);

    const saveState = () => {
        let stateForm = {
            name: watch('name'),
            startDate: watch('startDate'),
            endDate: watch('endDate'),
            eventPeriod: watch('eventPeriod'),
            projectPlace: watch('selectPlace'),
            outDoorPercent: watch('outDoorPercent') ? watch('outDoorPercent') : 0,
            renewPercent: watch('renewPercent') ? watch('renewPercent') : 0,
            placeOther: watch('placeOther'),
            eventArea: watch('eventArea'),
            eventSpace: watch('eventSpace'),
            eventMember: watch('eventMember'),
            eventMemberOnline: watch('eventMemberOnline') ? watch('eventMemberOnline') : '',
            eventForeignScale: watch('eventForeignScale') ? watch('eventForeignScale') : '',
            eventOwner: watch('placeOther'),
            subCategoryId: formMice?.subCategoryId,
            location: '',
        };
        if (selectedPlace === 'อื่น ๆ') {
            stateForm.location = watch('placeOther');
        } else {
            stateForm.location = watch('selectPlace') ?? '';
        }
        setFormMice({ ...formMice, ...stateForm });
    };
    const onAddLocation = () => {
        saveState();

        navigate('/setting-location');
    };

    return (
        <form onSubmit={handleSubmit(handleSubmitForm)} action="#">
            <div className="columns is-mobile">
                <div className="column">
                    <br />
                    <br />
                    <h1 className="content-header content-left">{t('FORM_PAGE.HEADER')}</h1>
                </div>
            </div>
            <div className="columns is-mobile">
                <div className="column is-4">
                    <div className="field">
                        <div className="control is-expanded">
                            <p className="content-left">
                                {t('FORM_PAGE.NAME')}
                                <span className="has-text-danger">*</span>
                            </p>
                            <input
                                className="input"
                                id={'setProjectName'}
                                type="text"
                                style={{
                                    borderRadius: '10px',
                                    borderColor: errors.name ? 'red' : '',
                                    background: errors.name ? '' : '',
                                }}
                                {...register('name', {
                                    required: t('ERROR_MSG_FORM.ERROR_FORM_PREFIX_MSG') + t('FORM_PAGE.NAME') + t('ERROR_MSG_FORM.ERROR_FORM_SUFFIX_MSG'),
                                })}
                            />
                            {errors?.name ? <p className="content-error">{errors.name.message}</p> : <p className="content-error">&nbsp;</p>}
                        </div>
                    </div>
                </div>
                <div className="column is-3">
                    <div className="field">
                        <div className="control is-expanded">
                            <p className="content-left">
                                {t('FORM_PAGE.START_DATE')}
                                <span className="has-text-danger">*</span>
                            </p>
                            <Controller
                                name="startDate"
                                control={control}
                                rules={{
                                    required: t('ERROR_MSG_FORM.ERROR_FORM_SELECT_MSG') + t('FORM_PAGE.START_DATE') + t('ERROR_MSG_FORM.ERROR_FORM_SUFFIX_MSG'),
                                }}
                                render={({ field }) => (
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                            value={field.value ? dayjs(field.value) : null}
                                            onChange={newValue => {
                                                field.onChange(newValue);
                                                if (dayjs(newValue).diff(dayjs(endDate)) > 0) {
                                                    resetField('endDate');
                                                }
                                            }}
                                            format={dateFormatMUI}
                                            sx={{
                                                '& .MuiInputBase-root': {
                                                    marginLeft: '-72px',
                                                    height: '41px',
                                                },
                                                '& .MuiOutlinedInput-notchedOutline': {
                                                    borderColor: errors.startDate ? 'red' : '',
                                                    borderRadius: '10px',
                                                },
                                                '&:hover .MuiOutlinedInput-notchedOutline': {
                                                    borderColor: 'darkblue',
                                                },
                                                '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                    borderColor: 'darkblue',
                                                },
                                            }}
                                        />
                                    </LocalizationProvider>
                                )}
                            />
                            {errors?.startDate ? <p className="content-error">{errors.startDate.message}</p> : <p className="content-error">&nbsp;</p>}
                        </div>
                    </div>
                </div>
                <div className="column is-3">
                    <div className="field">
                        <div className="control is-expanded">
                            <p className="content-left">
                                {t('FORM_PAGE.END_DATE')}
                                <span className="has-text-danger">*</span>
                            </p>
                            <Controller
                                name="endDate"
                                control={control}
                                rules={{
                                    required: t('ERROR_MSG_FORM.ERROR_FORM_SELECT_MSG') + t('FORM_PAGE.END_DATE') + t('ERROR_MSG_FORM.ERROR_FORM_SUFFIX_MSG'),
                                }}
                                render={({ field }) => (
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                            value={field.value ? dayjs(field.value) : null}
                                            onChange={field.onChange}
                                            minDate={dayjs(startDate)}
                                            format={dateFormatMUI}
                                            sx={{
                                                '& .MuiInputBase-root': {
                                                    marginLeft: '-72px',
                                                    height: '41px',
                                                },
                                                '& .MuiOutlinedInput-notchedOutline': {
                                                    borderColor: errors.endDate ? 'red' : '',
                                                    borderRadius: '10px',
                                                },
                                                '&:hover .MuiOutlinedInput-notchedOutline': {
                                                    borderColor: 'darkblue',
                                                },
                                                '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                    borderColor: 'darkblue',
                                                },
                                            }}
                                        />
                                    </LocalizationProvider>
                                )}
                            />
                            {errors?.endDate ? <p className="content-error">{errors.endDate.message}</p> : <p className="content-error">&nbsp;</p>}
                        </div>
                    </div>
                </div>
                <div className="column is-2">
                    <div className="field">
                        <div className="control is-expanded">
                            <p className="content-left">
                                {t('FORM_PAGE.DURATION')}
                                <span className="has-text-danger">*</span>
                            </p>
                            <input
                                className="input"
                                type="number"
                                id="setDuration"
                                step={0.1}
                                min={0.1}
                                style={{
                                    borderRadius: '10px',
                                    borderColor: errors.eventPeriod ? 'red' : '',
                                    background: errors.eventPeriod ? '' : '',
                                }}
                                onKeyDown={blockInvalidChar}
                                {...register('eventPeriod', {
                                    required: t('ERROR_MSG_FORM.ERROR_FORM_PREFIX_MSG') + t('FORM_PAGE.DURATION') + t('ERROR_MSG_FORM.ERROR_FORM_SUFFIX_MSG'),
                                    valueAsNumber: true,
                                    min: {
                                        value: 0.1,
                                        message: t('FORM_PAGE.DURATION') + t('ERROR_MSG_FORM.ERROR_AMT_GT_MSG') + '0.1',
                                    },
                                })}
                            />
                            {errors?.eventPeriod ? <p className="content-error">{errors.eventPeriod.message}</p> : <p className="content-error">&nbsp;</p>}
                        </div>
                    </div>
                </div>
            </div>
            <div className="columns is-mobile" style={{ marginTop: '-30px' }}>
                <div className={selectedPlace !== 'อื่น ๆ' ? 'column is-3' : 'column is-2'}>
                    <div className="field">
                        <div className="control is-expanded">
                            <p className="content-left">
                                {t('FORM_PAGE.SELECT_PLACE')}
                                <span className="has-text-danger">*</span>

                                <Tooltip title="เพิ่มสถานที่" className="cursor-pointer mx-2" placement="top-end" onClick={() => onAddLocation()}>
                                    <AddCircleIcon />
                                </Tooltip>
                            </p>
                            <Controller
                                name="selectPlace"
                                rules={{
                                    required:
                                        t('ERROR_MSG_FORM.ERROR_FORM_SELECT_MSG') + t('FORM_PAGE.SELECT_PLACE') + t('ERROR_MSG_FORM.ERROR_FORM_SUFFIX_MSG'),
                                }}
                                control={control}
                                render={({ field }) => (
                                    <Select
                                        {...field}
                                        className="w-full h-10 text-left text-xl !rounded-xl"
                                        // onChange={value => field.onChange(value)} // Update the form state on change
                                        showSearch // Enable search functionality
                                        onChange={handleSelectPlace}
                                        filterOption={(input, option: any) => option?.children.toLowerCase().includes(input.toLowerCase())}
                                    >
                                        <Option value="อื่น ๆ">อื่น ๆ</Option>
                                        {locations.map((item, index) => (
                                            <Option key={index} value={item.name}>
                                                {item.name}
                                            </Option>
                                        ))}
                                    </Select>
                                )}
                            />
                            {errors?.selectPlace ? <div className="content-error">{errors.selectPlace.message}</div> : ''}
                            {/* <Select
                                {...register('selectPlace', {
                                    required:
                                        t('ERROR_MSG_FORM.ERROR_FORM_SELECT_MSG') + t('FORM_PAGE.SELECT_PLACE') + t('ERROR_MSG_FORM.ERROR_FORM_SUFFIX_MSG'),
                                })}
                                className="w-full h-10 text-left text-xl !rounded-xl"
                                showSearch
                                size={'large'}
                                onChange={handleSelectPlace}
                                placeholder="เลือกสถานที่"
                                filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                options={[
                                    { value: 'อื่น ๆ', label: 'อื่น ๆ' },
                                    { value: '1', label: 'Jack' },
                                    { value: '2', label: 'Lucy' },
                                    { value: '3', label: 'Tom' },
                                ]}
                            /> */}

                            {/* <div className="select is-fullwidth"> */}
                            {/* <select
                                    id="setSelectPlace"
                                    className="form-control"
                                    style={{
                                        borderRadius: '10px',
                                        borderColor: errors.selectPlace ? 'red' : '',
                                        background: errors.selectPlace ? '' : '',
                                    }}
                                    {...register('selectPlace', {
                                        required:
                                            t('ERROR_MSG_FORM.ERROR_FORM_SELECT_MSG') + t('FORM_PAGE.SELECT_PLACE') + t('ERROR_MSG_FORM.ERROR_FORM_SUFFIX_MSG'),
                                        onChange: e => handleSelectPlace(e),
                                    })}
                                >
                                    {location.map(({ name }, index) => (
                                        <option key={index} value={name}>
                                            {name}
                                        </option>
                                    ))}
                                    <option value={'อื่น ๆ'}>{'อื่น ๆ'}</option>
                                </select> */}

                            {/* {errors?.selectPlace ? <p className="content-error">{errors.selectPlace.message}</p> : <p className="content-error">&nbsp;</p>} */}
                            {/* </div> */}
                        </div>
                    </div>
                </div>
                {selectedPlace === 'อื่น ๆ' ? (
                    <div className="column is-3">
                        <div className="field">
                            <div className="control is-expanded">
                                <p className="content-left">
                                    {t('FORM_PAGE.PLACE_OTHER')}
                                    <span className="has-text-danger">*</span>
                                </p>
                                <input
                                    className="input"
                                    type="text"
                                    id="setPlaceOther"
                                    style={{
                                        borderRadius: '10px',
                                        borderColor: errors.placeOther ? 'red' : '',
                                        background: errors.placeOther ? '' : '',
                                    }}
                                    {...register('placeOther', {
                                        required:
                                            t('ERROR_MSG_FORM.ERROR_FORM_PREFIX_MSG') + t('FORM_PAGE.PLACE_OTHER') + t('ERROR_MSG_FORM.ERROR_FORM_SUFFIX_MSG'),
                                    })}
                                />
                                {errors?.placeOther ? <p className="content-error">{errors.placeOther.message}</p> : <p className="content-error">&nbsp;</p>}
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="column is-3">
                        <p className="content-left">
                            {t('FORM_PAGE.SELECT_PLACE_SMALL')}
                            <span className="has-text-danger">*</span>
                        </p>

                        <Controller
                            name="subLocation"
                            rules={{
                                required:
                                    t('ERROR_MSG_FORM.ERROR_FORM_SELECT_MSG') + t('FORM_PAGE.SELECT_PLACE_SMALL') + t('ERROR_MSG_FORM.ERROR_FORM_SUFFIX_MSG'),
                            }}
                            control={control}
                            render={({ field }) => (
                                <Select
                                    {...field}
                                    className="w-full h-10 text-left text-xl !rounded-xl"
                                    // onChange={value => field.onChange(value)}
                                    onChange={handleSelectSubLocation}
                                    showSearch
                                    filterOption={(input, option: any) => option?.children.toLowerCase().includes(input.toLowerCase())}
                                >
                                    {findSubLocations &&
                                        findSubLocations.map((item, index) => (
                                            <Option key={index} value={item.name}>
                                                {item.name}
                                            </Option>
                                        ))}
                                </Select>
                            )}
                        />
                        {errors?.subLocation ? <div className="content-error text-left">{errors.subLocation.message}</div> : ''}
                    </div>
                )}
                <div className="column is-3">
                    <div className="field">
                        <div className="control is-expanded">
                            <p className="content-left">{t('FORM_PAGE.OUTDOOR_PART')}</p>
                            <input
                                className="input"
                                type="number"
                                id="setOutDoorPart"
                                step={0.01}
                                defaultValue={0}
                                min={0}
                                style={{ borderRadius: '10px' }}
                                onKeyDown={blockInvalidChar}
                                {...register('outDoorPercent', {
                                    valueAsNumber: true,
                                    min: {
                                        value: 0,
                                        message: t('FORM_PAGE.OUTDOOR_PART') + t('ERROR_MSG_FORM.ERROR_AMT_GT_MSG') + '0',
                                    },
                                })}
                            />
                        </div>
                    </div>
                </div>
                <div className="column is-3">
                    <div className="field">
                        <div className="control is-expanded">
                            <p className="content-left">{t('FORM_PAGE.ENERGY_PART')}</p>
                            <input
                                className="input"
                                type="number"
                                id="setEnergyPart"
                                step={0.01}
                                defaultValue={0}
                                min={0}
                                style={{ borderRadius: '10px' }}
                                onKeyDown={blockInvalidChar}
                                {...register('renewPercent', {
                                    valueAsNumber: true,
                                    min: {
                                        value: 0,
                                        message: t('FORM_PAGE.ENERGY_PART') + t('ERROR_MSG_FORM.ERROR_AMT_GT_MSG') + '0',
                                    },
                                })}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className="columns is-mobile">
                <div className="column is-2">
                    <div className="field">
                        <div className="control is-expanded">
                            <p className="content-left">
                                {t('FORM_PAGE.SQM')}
                                <span className="has-text-danger">*</span>
                            </p>
                            <input
                                className="input"
                                type="number"
                                id="setSQm"
                                step={0.01}
                                min={0.01}
                                style={{
                                    borderRadius: '10px',
                                    borderColor: errors.eventArea ? 'red' : '',
                                    background: errors.eventArea ? '' : '',
                                }}
                                onKeyDown={blockInvalidChar}
                                {...register('eventArea', {
                                    required: t('ERROR_MSG_FORM.ERROR_FORM_PREFIX_MSG') + t('FORM_PAGE.SQM') + t('ERROR_MSG_FORM.ERROR_FORM_SUFFIX_MSG'),
                                    valueAsNumber: true,
                                    min: {
                                        value: 0.01,
                                        message: t('FORM_PAGE.SQM') + t('ERROR_MSG_FORM.ERROR_AMT_GT_MSG') + '0.01',
                                    },
                                })}
                            />
                            {errors?.eventArea ? <p className="content-error">{errors.eventArea.message}</p> : <p className="content-error">&nbsp;</p>}
                        </div>
                    </div>
                </div>
                <div className="column is-2">
                    <div className="field">
                        <div className="control is-expanded">
                            <p className="content-left">
                                {t('FORM_PAGE.USAGE_SQM')}
                                <span className="has-text-danger">*</span>
                            </p>
                            <input
                                className="input"
                                type="number"
                                id="eventSpace"
                                step={0.01}
                                min={0.01}
                                style={{
                                    borderRadius: '10px',
                                    borderColor: errors.eventSpace ? 'red' : '',
                                    background: errors.eventSpace ? '' : '',
                                }}
                                onKeyDown={blockInvalidChar}
                                {...register('eventSpace', {
                                    required: t('ERROR_MSG_FORM.ERROR_FORM_PREFIX_MSG') + t('FORM_PAGE.USAGE_SQM') + t('ERROR_MSG_FORM.ERROR_FORM_SUFFIX_MSG'),
                                    valueAsNumber: true,
                                    min: {
                                        value: 0.01,
                                        message: t('FORM_PAGE.USAGE_SQM') + t('ERROR_MSG_FORM.ERROR_AMT_GT_MSG') + '0.01',
                                    },
                                })}
                            />
                            {errors?.eventSpace ? <p className="content-error">{errors.eventSpace.message}</p> : <p className="content-error">&nbsp;</p>}
                        </div>
                    </div>
                </div>
                <div className="column is-4">
                    <div className="field">
                        <div className="control is-expanded">
                            <p className="content-left">
                                {t('FORM_PAGE.ATTENDEES')}
                                <span className="has-text-danger">*</span>
                            </p>
                            <input
                                className="input"
                                type="number"
                                id="setAttendees"
                                min={1}
                                style={{
                                    borderRadius: '10px',
                                    borderColor: errors.eventMember ? 'red' : '',
                                    background: errors.eventMember ? '' : '',
                                }}
                                onKeyDown={blockInvalidChar}
                                {...register('eventMember', {
                                    required: t('ERROR_MSG_FORM.ERROR_FORM_PREFIX_MSG') + t('FORM_PAGE.ATTENDEES') + t('ERROR_MSG_FORM.ERROR_FORM_SUFFIX_MSG'),
                                    valueAsNumber: true,
                                    min: {
                                        value: 1,
                                        message: t('FORM_PAGE.ATTENDEES') + t('ERROR_MSG_FORM.ERROR_AMT_GT_MSG') + '1',
                                    },
                                })}
                            />
                            {errors?.eventMember ? <p className="content-error">{errors.eventMember.message}</p> : <p className="content-error">&nbsp;</p>}
                        </div>
                    </div>
                </div>
                <div className="column is-4">
                    <div className="field">
                        <div className="control is-expanded">
                            <p className="content-left">{t('FORM_PAGE.ATTENDEES_ONLINE')}</p>
                            <input
                                className="input"
                                type="number"
                                id="eventMemberOnline"
                                defaultValue={0}
                                min={0}
                                style={{ borderRadius: '10px' }}
                                onKeyDown={blockInvalidChar}
                                {...register('eventMemberOnline')}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="columns">
                <div className="column">
                    <div className="field">
                        <div className="control is-expanded">
                            <p className="content-left">{t('FORM_PAGE.FORIGENER')}</p>
                            <input
                                className="input"
                                type="number"
                                id="setForigener"
                                step={0.01}
                                defaultValue={0}
                                min={0}
                                style={{ borderRadius: '10px' }}
                                onKeyDown={blockInvalidChar}
                                {...register('eventForeignScale')}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="columns">
                <div className="column">
                    <div className="field">
                        <div className="control is-expanded">
                            <p className="content-left">
                                {t('FORM_PAGE.OWNER')}
                                <span className="has-text-danger">*</span>
                            </p>
                            <textarea
                                className="textarea"
                                id="setOwner"
                                style={{
                                    borderRadius: '10px',
                                    borderColor: errors.eventOwner ? 'red' : '',
                                    background: errors.eventOwner ? '' : '',
                                }}
                                {...register('eventOwner', {
                                    required: t('ERROR_MSG_FORM.ERROR_FORM_PREFIX_MSG') + t('FORM_PAGE.OWNER') + t('ERROR_MSG_FORM.ERROR_FORM_SUFFIX_MSG'),
                                })}
                            />
                            {errors?.eventOwner ? <p className="content-error">{errors.eventOwner.message}</p> : <p className="content-error">&nbsp;</p>}
                        </div>
                    </div>
                </div>
            </div>
            <div className="columns">
                <div className="column is-4 is-offset-4" style={{ alignItems: 'right' }}>
                    <br />
                    <button className="button is-rounded is-large is-fullwidth is-success App" type="submit">
                        {t('BUTTONS.NEXT')}
                    </button>
                </div>
            </div>
        </form>
    );
};
export default MyForm;

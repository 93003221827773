import React from 'react';
import { useTranslation } from 'react-i18next';

const ElectricEmission = ({ kw_hour, ef }: { kw_hour?: number; ef?: number }) => {
    const { t } = useTranslation();

    const totalCarbonFootprint = (kw_hour || 0) * (ef || 0);
    return (
        <div className="mt-4">
            <div className="flex justify-between items-center font-normal">
                <div className=" text-black"> {t('DETAIL_PAGE.ELECTRIC_SITE_DETAIL.AMOUNT')}</div>
                <div className=" text-green-600">
                    <span className="px-1">{kw_hour?.toFixed(2)}</span>
                    {t('DETAIL_PAGE.ELECTRIC_SITE_DETAIL.AMOUNT_UNIT')}
                </div>
            </div>

            <div className="flex justify-between items-center font-semibold">
                <div className=" text-black"> {t('DETAIL_PAGE.CARBON_FOOTPRINT')}</div>
                <div className=" text-black"> {totalCarbonFootprint ? totalCarbonFootprint?.toFixed(2) : 0} kgCO2eq</div>
            </div>
        </div>
    );
};

export default ElectricEmission;
